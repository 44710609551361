import { useSchoolStaffClient } from '@sparx/query/school-staff-service';
import { TrainingContextProvider } from '@sparx/teacher-training';
import { trainingProgressClient } from 'api';
import { queryClient } from 'api/client';
import { useSession } from 'api/sessions';
import { useClientEvent } from 'components/ClientEventProvider';
import { PropsWithChildren } from 'react';

import { ALL_MODULES } from './ModuleSpecs';

export const TrainingContext = ({ children }: PropsWithChildren) => {
  const { data: user } = useSession({ suspense: true });
  const { sendEvent } = useClientEvent();
  const schoolStaffClient = useSchoolStaffClient();
  const staticURL = window.settings?.STATIC_URL || 'https://static.sparxhomework.uk';

  if (!schoolStaffClient) {
    // Should not happen due to the suspense
    return null;
  }
  return (
    <TrainingContextProvider
      value={{
        schoolID: user?.schoolId || '',
        videoAssetDirURL: `${staticURL}/assets/training-science/`,
        moduleSpecs: ALL_MODULES,
        queryClient: queryClient,
        trainingProgressClient: trainingProgressClient,
        staffClient: schoolStaffClient,
        onTrainingCompleted: () => {
          if (window.hj) {
            // trigger a hotjar event so we can show a survey
            window.hj('trigger', 'training_completed_trigger');
          }
        },
        sendClientEvent: sendEvent,
      }}
    >
      {children}
    </TrainingContextProvider>
  );
};
