import { KeyContact } from '@sparx/api/apis/sparx/school/keycontacts/v1/keycontacts';
import {
  Deal,
  SubmitAgreementInPrincipleRequest,
} from '@sparx/api/apis/sparx/school/subscription/v1/subscriptionactions';
import { useCommonParams } from '@sparx/query';
import { mutations } from '@sparx/query/subscription-actions-service';
import { Button } from '@sparx/sparx-design/components/button/Button';
import { FormControl } from '@sparx/sparx-design/components/form/FormControl';
import { FormErrorMessage } from '@sparx/sparx-design/components/form/FormErrorMessage';
import { FormLabel } from '@sparx/sparx-design/components/form/FormLabel';
import { Input } from '@sparx/sparx-design/components/input/Input';
import { Modal } from '@sparx/sparx-design/components/modal/Modal';
import { Stack } from '@sparx/sparx-design/components/stack/Stack';
import { useForm } from 'react-hook-form';

import { CivilDate } from '../types/civilDate';
import { formatDate } from '../utils/dates';
import { emailValidationRegex } from '../utils/validation';
import { colourProp } from './colour-prop';
import styles from './FinanceModal.module.css';

interface FinanceFields {
  givenName: string;
  familyName: string;
  emailAddress: string;
}

export interface FinanceModalProps {
  deal: Deal;
  startDate: CivilDate;
  existingContact?: FinanceFields;
  isOpen: boolean;
  onClose: () => void;
}

export const FinanceModal = ({
  deal,
  startDate,
  existingContact = { givenName: '', familyName: '', emailAddress: '' },
  isOpen,
  onClose,
}: FinanceModalProps) => {
  const { product } = useCommonParams();
  const { mutateAsync: submitAIP, isLoading } = mutations.useSubmitAIP();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FinanceFields>({ defaultValues: existingContact, mode: 'all' });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Content>
        <Modal.Title>Agree to subscribe</Modal.Title>
        <Modal.CloseButton />
        <Modal.Body className={styles.FinanceModal}>
          <Stack direction="column" spacing={4}>
            <div>
              Your <strong>{deal.durationMonths} month</strong> subscription for{' '}
              <strong>£{deal.priceGbp}</strong>+VAT* will start on{' '}
              <strong>{formatDate(startDate)}</strong>.
              <br />
              <sub>*if applicable</sub>
            </div>
            <hr />
            <h4>Finance Contact</h4>
            <p>
              Please check the details for your finance contact. If they are incorrect, please
              update them now.
            </p>

            <FormControl isInvalid={!!errors.givenName}>
              <FormLabel>First name:</FormLabel>
              <Input
                type="text"
                {...register('givenName', {
                  required: 'A first name is required',
                  maxLength: {
                    value: 50,
                    message: 'Must be between 1 and 50 characters',
                  },
                })}
              />
              <FormErrorMessage>{errors.givenName?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.familyName}>
              <FormLabel>Last name:</FormLabel>
              <Input
                type="text"
                {...register('familyName', {
                  required: 'A last name is required',
                  maxLength: {
                    value: 50,
                    message: 'Must be between 1 and 50 characters',
                  },
                })}
              />
              <FormErrorMessage>{errors.familyName?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.emailAddress}>
              <FormLabel>Email address:</FormLabel>
              <Input
                type="email"
                {...register('emailAddress', {
                  required: 'An email address is required',
                  pattern: {
                    value: emailValidationRegex,
                    message: 'Must be a valid email address',
                  },
                })}
              />
              <FormErrorMessage>{errors.emailAddress?.message}</FormErrorMessage>
            </FormControl>
          </Stack>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outlined" colour={colourProp(product)} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            colour={colourProp(product)}
            isLoading={isLoading}
            isDisabled={!isValid}
            onClick={handleSubmit(data => {
              submitAIP(
                SubmitAgreementInPrincipleRequest.create({
                  startDate,
                  priceGbp: deal.priceGbp,
                  durationMonths: deal.durationMonths,
                  optionName: deal.optionName,
                  financeContact: KeyContact.create({
                    givenName: data.givenName,
                    familyName: data.familyName,
                    email: data.emailAddress,
                  }),
                  financeContactUpdateMask: {
                    paths: ['finance.email', 'finance.given_name', 'finance.family_name'],
                  },
                }),
                {
                  onSuccess: () => {
                    onClose();
                  },
                },
              );
            })}
          >
            Agree
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
