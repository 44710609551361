import {
  Box,
  Button,
  chakra,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Task, TaskItem, TaskItem_State } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { XpAward, XpAward_Reason } from '@sparx/api/apis/sparx/science/xp/v1/xp';
import { isComplete } from '@sparx/packageactivity';
import { ProgressBar } from '@sparx/sparx-design/components';
import { useUpdateUserXpState } from 'api/xp';
import { useMediaQuery } from 'components/chakraExports';
import { FlashCardCounts, useFlashCardCounts } from 'components/flashcards/hooks';
import { SmallFlashcard, SmallFlashcardContainer } from 'components/flashcards/SmallFlashcard';
import { LevelBadge } from 'components/xp/Badges/Badge';
import lightningIcon from 'components/xp/images/lightning_no_outline.svg';
import { getLevelProgress } from 'components/xp/utils';
import { useXpContext } from 'components/xp/XpManager/context';
import { AnimatePresence, motion } from 'motion/react';
import { useEffect, useMemo } from 'react';
import { isTaskFlashcards } from 'utils/flashcards';
import { useKeyPress } from 'utils/hooks/keypress';
import taskCompleteTick from 'views/task/task_complete_tick.svg';

interface ResultsProps {
  task: Task | undefined;
  onContinue?: () => void;
  onFinish?: () => void;
}

export const Results = ({ task, onContinue, onFinish }: ResultsProps) => {
  const complete = isComplete(task?.state?.completion);
  const completion = task?.state?.completion;
  const completeItems = (completion?.progress?.['C'] || 0) + (completion?.progress?.['SK'] || 0);
  const cftItems = completion?.progress?.['CFT'] || 0;
  const totalItems = completion?.size || 0;

  // get any xp rewards from the task
  const { getAwardsToShow, markAwardsAsShown, showLevelUp } = useXpContext();
  const { awards } = useMemo(() => {
    const awards = getAwardsToShow(XpAward_Reason.TASK_COMPLETE);
    const ilTargetCompleted = awards.some(a =>
      a.additionalData.some(
        d =>
          d.data.oneofKind === 'ilTargetStateUpdate' &&
          !!d.data.ilTargetStateUpdate.completedTimestamp,
      ),
    );

    return { awards, ilTargetCompleted };
  }, [getAwardsToShow]);

  const showAwards = awards.length > 0;

  // update the userXpState query to include the most recent xpState from the awards
  const updateUserXpState = useUpdateUserXpState();
  useEffect(() => {
    updateUserXpState(awards);
  }, [awards, updateUserXpState]);

  useKeyPress({ Enter: () => (!complete ? onContinue?.() : onFinish?.()) });

  const isFlashcards = isTaskFlashcards(task);
  const [largeHeight] = useMediaQuery('(min-height: 800px)');

  let content = null;
  let button = null;
  if (!complete) {
    content = (
      <>
        <Heading size="lg">Keep going!</Heading>
        <Text color="gray.500" fontWeight="bold">
          {completeItems}/{totalItems} complete
        </Text>
      </>
    );
    button = (
      <Button
        size={['sm', 'md']}
        colorScheme="buttonTeal"
        rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
        onClick={onContinue}
      >
        Keep going
      </Button>
    );
  } else {
    content = (
      <>
        <VStack justifyContent={'center'} gap={0}>
          <Heading color="green.400" size="xl" mb={2}>
            Task Complete
          </Heading>
          <Heading size="md">Well done!</Heading>
        </VStack>
        {largeHeight && <Image src={taskCompleteTick} alt="" height={'60px'} my={[1, 2]} />}
        {!isFlashcards ? (
          <>
            <Text color="gray.500" fontWeight="bold">
              You got {cftItems}/{totalItems} correct first time.
            </Text>
            {showAwards && <XpCard animate={!showLevelUp} xpAwards={awards} />}
          </>
        ) : (
          <>
            {showAwards && <XPValue xpAwards={awards} />}
            <FlashcardsResults task={task} taskItem={task?.contents?.taskItems[0]} />
          </>
        )}
      </>
    );
    button = (
      <Button
        size={['sm', 'md']}
        variant={isFlashcards ? 'flashcard' : undefined}
        colorScheme={!isFlashcards ? 'buttonTeal' : undefined}
        rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
        onClick={() => {
          onFinish?.();
          markAwardsAsShown(XpAward_Reason.TASK_COMPLETE);
        }}
      >
        Finish
      </Button>
    );
  }

  return (
    <VStack h="100%" spacing={0}>
      <VStack spacing={2} overflow="auto" flex="1 1 auto" py={largeHeight ? 12 : 4} w="100%">
        {content}
      </VStack>
      <Divider />
      <HStack flex="0 0 auto" w="100%" p={3} justifyContent={isFlashcards ? 'center' : 'flex-end'}>
        {button}
      </HStack>
    </VStack>
  );
};

const XPValue = ({ xpAwards }: { xpAwards: XpAward[] }) => {
  const xpEarned = xpAwards.reduce((acc, award) => acc + award.xpAwarded, 0);
  return (
    <Heading color="green.500" fontWeight="bold" fontSize="4xl" textAlign="center">
      +{xpEarned}
      <chakra.span fontSize="2xl">XP</chakra.span>
    </Heading>
  );
};

const XpCard = ({ xpAwards, animate }: { xpAwards: XpAward[]; animate: boolean }) => {
  const xpEarned = xpAwards.reduce((acc, award) => acc + award.xpAwarded, 0);
  const ilTargetCompleted = xpAwards.some(a =>
    a.additionalData.some(
      d =>
        d.data.oneofKind === 'ilTargetStateUpdate' &&
        !!d.data.ilTargetStateUpdate.completedTimestamp,
    ),
  );
  // last award is the most recent
  const xpState = xpAwards[xpAwards.length - 1];
  const { level, levelCompletion, xpToNext } = getLevelProgress(
    xpState.xpStateAfterAward?.currentXp || 0,
  );
  // if the level completion is 0, we are at a level exactly, so show it as full rather than empty
  const lvlComp = levelCompletion || 1;
  return (
    <Box maxW={325} my={5} w="100%">
      <Flex backgroundColor="gray.100" p={5} borderRadius={'lg'} flexDir="column">
        <Heading color="green.500" fontWeight="bold" size="lg" textAlign="center">
          You earned +{xpEarned} XP!
        </Heading>
        <HStack pt={5}>
          <ProgressBar animateFill={animate} showHighlight percentComplete={lvlComp * 100} />
          <LevelBadge
            level={lvlComp < 1 ? level + 1 : level}
            width={'60px'}
            filter={lvlComp < 1 ? 'grayscale(1)' : ''}
            opacity={lvlComp < 1 ? 0.3 : 1}
          />
        </HStack>
        {xpToNext <= 100 && (
          <Text pt={5} color={'blue.800'} textAlign="center" fontWeight={'bold'} fontSize={'lg'}>
            {xpToNext} XP more to reach Level {level + 1}
          </Text>
        )}
      </Flex>
      {ilTargetCompleted && (
        <AnimatePresence initial={animate}>
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.4,
              delay: 0.3,
              ease: [0, 0.71, 0.2, 1.5],
            }}
          >
            <Flex
              backgroundColor="gray.100"
              p={5}
              borderRadius={'lg'}
              mt={3}
              justifyContent="space-between"
              bgColor={'#FBEAB2'}
            >
              <Text>
                <Text as="span" color="yellow.600" fontWeight="bold">
                  Well done!
                </Text>{' '}
                You completed your Independent Learning Challenge for this week
              </Text>
              <Image src={lightningIcon} ml={2} w={30} />
            </Flex>
          </motion.div>
        </AnimatePresence>
      )}
    </Box>
  );
};

const FlashcardsResults = ({ task, taskItem }: { task?: Task; taskItem?: TaskItem }) => {
  const { packageCounts, taskCounts } = useFlashCardCounts(task);

  if (!taskItem || !taskItem.state || !packageCounts || !taskCounts) {
    return null;
  }

  return (
    <FlashcardsResultsInner
      packageCounts={packageCounts}
      taskCounts={taskCounts}
      taskItemState={taskItem.state}
    />
  );
};

const FlashcardsResultsInner = ({
  packageCounts,
  taskCounts,
  taskItemState,
}: {
  packageCounts: FlashCardCounts;
  taskCounts: FlashCardCounts;
  taskItemState: TaskItem_State;
}) => {
  const [largeHeight] = useMediaQuery('(min-height: 800px)');
  let accuracy =
    (taskItemState.correctActivities * 100) /
    (taskItemState.incorrectActivities + taskItemState.correctActivities);

  // Round correct up or down to nearest integer if it's close to 0 or 100
  if (accuracy > 0 && accuracy < 1) accuracy = Math.ceil(accuracy);
  else if (accuracy > 99 && accuracy < 100) accuracy = Math.floor(accuracy);
  else accuracy = Math.round(accuracy);

  return (
    <>
      <Text fontSize={'lg'}>You were {accuracy}% accurate!</Text>
      <SmallFlashcardContainer mt={largeHeight ? 6 : 4}>
        <SmallFlashcard
          category="learning"
          value={packageCounts.learning}
          start={packageCounts.learning - taskCounts.learning}
          countUp
        />
        <SmallFlashcard
          category="confident"
          value={packageCounts.confident}
          start={packageCounts.confident - taskCounts.confident}
          countUp
        />
        <SmallFlashcard category="mastered" value={0} countUp />
      </SmallFlashcardContainer>
    </>
  );
};
