import classNames from 'classnames';
import { useMemo } from 'react';

import { HintBannerWrapper, hintIsForGroup, HintPrompt, ResetButton } from '../components/Hints';
import { Stack } from '../components/Stack';
import styles from '../question/SparxQuestion.module.css';
import { LayoutElementProps, useSparxQuestionContext } from '../question/SparxQuestionContext';
import { IGroupElement } from '../question/types';
import { getLabelId } from '../utils/labelId';
import { AnswerPart } from './AnswerPart';
import { AnswerScreen } from './AnswerScreen';
import { CardsElement } from './CardsElement';
import { ChoiceGroupElement } from './ChoiceGroupElement';
import { LayoutElements } from './LayoutElement';
import { LeftImageWrapper } from './LeftImageWrapper';
import { LinkBoxesElement } from './LinkBoxesElement';
import { Slidey } from './Slidey';
import { TextElement } from './TextElement';

export const GroupElement = ({
  element: contentElement,
  answerPartIndex,
}: LayoutElementProps<IGroupElement>) => {
  const context = useSparxQuestionContext();
  const types = useMemo(() => new Set(contentElement.type), [contentElement.type]);
  const hintRef = useMemo(
    () =>
      hintIsForGroup(context.hintInfo, contentElement)
        ? context.hintInfo?.hint.inputRefs[0]
        : undefined,
    [context.hintInfo, contentElement],
  );

  if (
    context.mode === 'question' &&
    (contentElement.type.includes('answer') || contentElement.type.includes('answer-part'))
  )
    return null;
  if (
    context.mode === 'answer' &&
    (contentElement.type.includes('question') || contentElement.type.includes('question-part'))
  )
    return null;

  if (types.has('choices')) {
    return <ChoiceGroupElement element={contentElement} />;
  } else if (types.has('cards')) {
    return <CardsElement element={contentElement} />;
  } else if (types.has('link-boxes')) {
    return <LinkBoxesElement element={contentElement} />;
  }

  // If the group is a fraction, we need to add a image between each element.
  // TODO: reconsider this section
  let content = [...contentElement.content];
  if (contentElement.style === 'fraction' && content.length > 0) {
    const newArray = [content[0]];
    for (let i = 1; i < content.length; i++) {
      newArray.push({ element: 'image', src: 'fraction_slot_link' }, content[i]);
    }
    content = newArray;
  }
  // End section to reconsider

  // Handle left-image
  if (types.has('interleaved-question-answer') && types.has('left-image') && content.length > 0) {
    return <LeftImageWrapper content={content} />;
  }

  // if we're rendering the answers, wrap some elements depending on their type
  let jsxElement = <LayoutElements element={{ content }} />;

  if (hintRef && contentElement) {
    jsxElement = (
      <>
        {jsxElement}
        <ResetButton
          hintInfo={context.hintInfo}
          partHasInput={!!context.hintInfo?.partHasInputMap[hintRef]}
        />
      </>
    );
  }

  if (context.mode !== 'question') {
    if (types.has('answer-screen')) {
      jsxElement = <AnswerScreen>{jsxElement}</AnswerScreen>;
    }
    if (types.has('answer-part')) {
      jsxElement = (
        <AnswerPart groupElement={contentElement} answerPartIndex={answerPartIndex}>
          {jsxElement}
        </AnswerPart>
      );
    }
    if (types.has('slidey')) {
      jsxElement = <Slidey>{jsxElement}</Slidey>;
    }
    if (types.has('slots')) {
      jsxElement = (
        <Stack
          dir={types.has('fraction') ? 'vertical' : 'horizontal'}
          className={classNames(types.has('fraction') && styles.FractionSlotGroup)}
          dataTag={'slots'}
        >
          {jsxElement}
        </Stack>
      );
    }
    if (types.has('answer')) {
      // if the answer has a number field or slot, we wrap it in an answer part so the keypad and
      // options display
      if (['number-field', 'slot'].includes(contentElement.content?.[0].element)) {
        jsxElement = (
          <AnswerPart groupElement={contentElement} answerPartIndex={answerPartIndex}>
            {jsxElement}
          </AnswerPart>
        );
      }
    }
  }

  // Render the label if there is one, expect if it's an answer-part as the label will already be
  // have been rendered by the <AnswerPart> component
  if (!types.has('answer-part') && contentElement.label) {
    jsxElement = (
      <Stack>
        <TextElement
          element={{
            element: 'text',
            text: contentElement.label,
            labelId: getLabelId(contentElement),
          }}
        />
        {jsxElement}
      </Stack>
    );
  }

  if (hintRef) {
    if (contentElement) {
      jsxElement = (
        <HintBannerWrapper>
          <HintPrompt partHasInput={!!context.hintInfo?.partHasInputMap[hintRef]} />
          {jsxElement}
        </HintBannerWrapper>
      );
    } else {
      jsxElement = <HintBannerWrapper>{jsxElement}</HintBannerWrapper>;
    }
  }
  return jsxElement;
};
