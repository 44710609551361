import { Box, Heading } from '@chakra-ui/react';
import { faIdCard, faUserShield, faUserTag } from '@fortawesome/free-solid-svg-icons';
import { StaffRole } from '@sparx/api/apis/sparx/school/staff/v2/staff';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { listSchoolStaffMembers } from '@sparx/query/school-staff-service';
import { AnnotationKeys } from '@sparx/schoolutils';
import { KeyContactsTable } from '@sparx/staff-manager';
import { selectKeyContacts } from '@sparx/staff-manager/src/queries';
import { productKeyContactRoles } from '@sparx/staff-manager/src/utils';
import { articleIdAddingStaff, articleIdKeyContacts } from 'utils/knowledgeBaseArticles';
import { plural } from 'utils/plural';

import { IsRolloverProps, PageTab, TabDetails } from '..';
import { ActionBox } from '../components/ActionBox';
import { useIsManualRolloverStepComplete } from '../hooks';

const title = 'Add Staff & Key Contacts';
const rolloverTitle = 'Manage Staff & Key Contacts';

const numTeachersRequired = 2;

export const teachingRoles = [
  StaffRole.HEAD_OF_DEPARTMENT,
  StaffRole.DEPUTY_HEAD_OF_DEPARTMENT,
  StaffRole.DEPARTMENT_COORDINATOR,
  StaffRole.KEY_STAGE_LEAD,
  StaffRole.LEAD_PRACTITIONER,
  StaffRole.SPARX_LEADER,
  StaffRole.TEACHER,
];

const Page = ({ isRollover }: IsRolloverProps) => {
  const { data } = listSchoolStaffMembers.useSuspenseQuery({
    select: selectKeyContacts(Product.SPARX_SCIENCE),
  });

  const rolloverStaffReviewed = useIsManualRolloverStepComplete(
    AnnotationKeys.RolloverStepReviewedStaff,
  );
  const rolloverKeyContactsReviewed = useIsManualRolloverStepComplete(
    AnnotationKeys.RolloverStepReviewedKeyContacts,
  );

  // Calculate the number of key contacts that are missing, we don't use the
  // missing count on the status as that doesn't count every role
  const numMissing = [...productKeyContactRoles].filter(
    r => data?.keyContacts[r] === undefined,
  ).length;
  const numStaff =
    data?.allStaff.filter(s =>
      s.roles.some(r => r.product === Product.SPARX_SCIENCE && teachingRoles.includes(r.role)),
    ).length || 0;

  return (
    <>
      <Box borderRadius="md" borderWidth={1} my={4} overflow="hidden">
        <ActionBox
          heading={
            isRollover
              ? 'Review your Staff Manager page to remove any that have left, or add any new joiners.'
              : 'Add staff members so that they can access Sparx Science'
          }
          faIcon={faUserShield}
          button={{ text: 'Staff Manager', path: '/teacher/staff' }}
          borderWidth={0}
          borderRadius="unset"
          borderBottomWidth={1}
          knowledgeBaseArticleId={articleIdAddingStaff}
          checkbox={
            isRollover ? { annotation: AnnotationKeys.RolloverStepReviewedStaff } : undefined
          }
          complete={isRollover ? rolloverStaffReviewed : numStaff >= numTeachersRequired}
        >
          {!isRollover && (
            <>
              {numStaff || 'No'} science {plural(numStaff, 'teacher')} added
            </>
          )}
        </ActionBox>
        <ActionBox
          heading={
            (isRollover ? 'Review' : 'Assign') +
            ' your Key Contacts so we are able to send important information to the right person'
          }
          complete={numMissing === 0 && (isRollover ? rolloverKeyContactsReviewed : true)}
          faIcon={faUserTag}
          button={{ text: 'Key Contacts', path: '/teacher/staff/keycontacts' }}
          borderWidth={0}
          borderRadius="unset"
          knowledgeBaseArticleId={articleIdKeyContacts}
          checkbox={
            isRollover
              ? {
                  annotation: AnnotationKeys.RolloverStepReviewedKeyContacts,
                  disabled: numMissing > 0,
                }
              : undefined
          }
        >
          {(!isRollover || numMissing > 0) && (
            <>
              {numMissing > 0 ? numMissing : 'All'} Key {plural(numMissing, 'Contact')}{' '}
              {numMissing > 0 && 'not'} assigned
            </>
          )}
        </ActionBox>
      </Box>
      <Heading as="h2" size="sm" my={4}>
        Key Contacts
      </Heading>
      <Box data-sentry-mask>
        <KeyContactsTable
          contacts={data?.keyContacts || {}}
          readOnly={true}
          productKeyContactsOnly={true}
        />
      </Box>
    </>
  );
};

const useTabDetails = ({ isRollover }: IsRolloverProps): TabDetails => {
  const { data, isSuccess } = listSchoolStaffMembers.useQuery({
    select: selectKeyContacts(Product.SPARX_SCIENCE),
  });
  const rolloverStaffReviewed = useIsManualRolloverStepComplete(
    AnnotationKeys.RolloverStepReviewedStaff,
  );
  const rolloverKeyContactsReviewed = useIsManualRolloverStepComplete(
    AnnotationKeys.RolloverStepReviewedKeyContacts,
  );
  const numStaff =
    data?.allStaff.filter(s =>
      s.roles.some(r => r.product === Product.SPARX_SCIENCE && teachingRoles.includes(r.role)),
    ).length || 0;

  const keyContactsComplete =
    productKeyContactRoles.every(r => data?.keyContacts[r] !== undefined) &&
    (isRollover ? rolloverKeyContactsReviewed : true);

  const staffComplete = isRollover ? rolloverStaffReviewed : numStaff >= numTeachersRequired;

  const complete = staffComplete && keyContactsComplete;

  return {
    title: isRollover ? rolloverTitle : title,
    subtitle: complete ? 'Completed' : 'Action required',
    icon: faIdCard,
    status: !isSuccess ? 'loading' : complete ? 'complete' : undefined,
  };
};

const ManageStaffTab: PageTab = {
  path: 'staff',
  detailsHook: useTabDetails,
  page: Page,
};
export default ManageStaffTab;
