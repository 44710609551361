import { Task, TaskItem } from '@sparx/api/apis/sparx/science/packages/v1/package';

export const isTaskFlashcards = (task?: Task) =>
  task?.contents?.taskItems.length === 1 && isTaskItemFlashcards(task.contents.taskItems[0]);

export const isTaskItemFlashcards = (taskItem?: TaskItem) =>
  taskItem?.contents?.contents.oneofKind === 'flashcards';

export const isTaskUnstartedFlashcards = (task?: Task) => {
  const state = task?.contents?.taskItems?.[0]?.state;
  return (
    isTaskFlashcards(task) &&
    (state?.correctActivities || 0) + (state?.incorrectActivities || 0) == 0
  );
};
