import { HStack, Link as ChakraLink, Select, Text, TextProps } from '@chakra-ui/react';
import { ResourceStatus, SchemeOfLearningTemplate } from '@sparx/api/apis/sparx/planning/v1/sol';
import { SchoolStaffMember } from '@sparx/api/apis/sparx/school/staff/schoolstaff/v2/schoolstaff';
import { useSchoolGroups } from 'api/school';
import { useUserType } from 'api/sessions';
import { SCHOOL_GROUPS_ALL, useTemplateSols } from 'api/sol';
import { useCurrentStaffUser, useStaffLookup } from 'api/staff';
import { teacherFeedbackFormURL } from 'app/teacherFeedbackFormURL';
import { useCallback, useMemo, useState } from 'react';

import { AnnotatedSchemeOfLearningTemplate, SolTable } from './components/SolTable';

export const SolTemplates = () => {
  const canEdit = useCanEditTemplate();
  const { data: schoolGroups } = useSchoolGroups({ suspense: true });

  const templateSolByGroup = useTemplateSols(
    [SCHOOL_GROUPS_ALL, ...(schoolGroups?.map(g => g.name) || [])],
    {
      suspense: true,
    },
  );

  const sols = templateSolByGroup.flatMap(tsol => tsol.data || []);

  const rowHasPublishedVersion = useCallback(
    (sow: SchemeOfLearningTemplate) =>
      sow.metadata?.status === ResourceStatus.DRAFT &&
      sols.find(s => s.name === sow.name && s.metadata?.status === ResourceStatus.PUBLISHED),
    [sols],
  );

  const rowHasDraftVersion = useCallback(
    (sow: SchemeOfLearningTemplate) =>
      sow.metadata?.status === ResourceStatus.PUBLISHED &&
      sols.find(s => s.name === sow.name && s.metadata?.status === ResourceStatus.DRAFT),
    [sols],
  );

  const usableSols: AnnotatedSchemeOfLearningTemplate[] = useMemo(() => {
    return sols
      .map<AnnotatedSchemeOfLearningTemplate>(sol => {
        return {
          ...sol,
          isTemplate: true,
          status: sol.metadata?.status || ResourceStatus.UNKNOWN_STATUS,
          hasDraft: Boolean(rowHasDraftVersion(sol)),
          hasPublished: Boolean(rowHasPublishedVersion(sol)),
          schoolGroup:
            schoolGroups?.find(g => sol.name.startsWith(g.name))?.displayName ||
            (sol.name.startsWith(SCHOOL_GROUPS_ALL) && sol.syllabus) ||
            'Unknown',
          allowEdit: canEdit(sol),
        };
      })
      .filter(
        s =>
          s.metadata?.tags.includes('subject:science') && // Only show science sols
          (s.status === ResourceStatus.PUBLISHED || s.allowEdit), // Only pubilshed or ones we can edit
      )
      .sort(
        (a, b) =>
          a.syllabus.localeCompare(b.syllabus) ||
          a.displayName.localeCompare(b.displayName, undefined, { numeric: true }) ||
          a.name.localeCompare(b.name) ||
          (b.metadata?.status || 0) - (a.metadata?.status || 0),
      );
  }, [sols, schoolGroups, rowHasDraftVersion, rowHasPublishedVersion, canEdit]);

  const [schemeFilter, setSchemeFilter] = useState<string>('all');

  const filteredSols = useMemo(() => {
    return usableSols.filter(sol => {
      if (schemeFilter === 'all') {
        return true;
      }
      return sol.schoolGroup === schemeFilter;
    });
  }, [usableSols, schemeFilter]);

  const options = usableSols
    .map(sol => sol.schoolGroup)
    .filter((val, idx, arr) => arr.indexOf(val) === idx);

  return (
    <>
      <HStack pt={4}>
        <Text>Schemes:</Text>
        <Select
          value={schemeFilter}
          onChange={e => setSchemeFilter(e.target.value)}
          bgColor="white"
          maxWidth={80}
        >
          <option value="all">All</option>
          {options.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Select>
      </HStack>
      <SolTable templateSols={true} filteredSols={filteredSols} />
      <MissingScheme mt={4} />
    </>
  );
};

const MissingScheme = (props: TextProps) => {
  const { data: staff } = useCurrentStaffUser();

  const name = staff ? staff?.givenName + ' ' + staff?.familyName : '';
  const email = staff?.emailAddress || '';

  return (
    <Text {...props}>
      Use a common scheme but it&apos;s not here?{' '}
      <ChakraLink href={teacherFeedbackFormURL(name, email)} isExternal textDecoration="underline">
        Let us know by sharing your feedback.
      </ChakraLink>
    </Text>
  );
};

export const useCanEditTemplate = () => {
  const { isSparxStaff, isActuallySparxStaff } = useUserType();
  const { data: staffMap } = useStaffLookup({ suspense: true });

  return (sol: SchemeOfLearningTemplate) => {
    const { isSparx, isOtherSchool } = getLastUpdatedBy(sol, staffMap || {});
    return (
      // Teachers can edit templates created by teachers in the same school
      (!isOtherSchool && !isSparx) ||
      // Sparx staff can edit templates by other Sparx staff or staff in the current school
      (isActuallySparxStaff && (isSparx || !isOtherSchool)) ||
      // Sparx staff with features enabled can edit any template, including those from other schools
      isSparxStaff
    );
  };
};

const getLastUpdatedBy = (
  sol: SchemeOfLearningTemplate,
  staffMap: Record<string, SchoolStaffMember | undefined>,
) => {
  const updatedBy = sol.metadata?.updatedBy;
  if (!updatedBy) {
    return { isSparx: true, isOtherSchool: false };
  }

  if (staffMap[updatedBy]) {
    return { isSparx: false, isOtherSchool: false };
  }
  return { isSparx: false, isOtherSchool: true };
};
