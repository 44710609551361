import { IntercomProvider } from '@sparx/intercom';
import { getSchool } from '@sparx/query/schools-service';
import { readableStaffNames } from '@sparx/staff-manager';
import { useSession, useUserType } from 'api/sessions';
import { useCurrentStaffUser } from 'api/staff';
import { useClientEvent } from 'components/ClientEventProvider';
import { PropsWithChildren } from 'react';
import { isAnonymousMode } from 'utils/anonymous';
import { supportEmailAddress, urlKnowledgeBaseArticlePrefix } from 'utils/knowledgeBaseArticles';

const intercomAppId = 'os8d5arr';

export const IntercomContext = ({ children }: PropsWithChildren) => {
  const bootProps = useIntercomBootProps();
  const { sendEvent } = useClientEvent();
  return (
    <IntercomProvider
      appId={intercomAppId}
      bootProps={bootProps}
      sendEvent={sendEvent}
      handleSupportLinks={urlKnowledgeBaseArticlePrefix}
      handleSupportEmail={supportEmailAddress}
    >
      {children}
    </IntercomProvider>
  );
};

const useIntercomBootProps = () => {
  const { data: user } = useSession();
  const { isTeacher, isActuallySparxStaff } = useUserType();
  const { data: school } = getSchool.useQuery({ enabled: isTeacher });
  const { data: staffUser } = useCurrentStaffUser();

  // Don't return the properties until everything is loaded
  if (!user || !isTeacher || !school) {
    return undefined;
  }

  let company = {
    company_id: school.name,
    name: school.displayName,
    created_at: school.createTime?.seconds,
    website: `https://app.sparxscience.com?school=${school.name.split('/')[1]}`,
  };

  let name = `${user.givenName} ${user.familyName}`;

  const { realName } = readableStaffNames(staffUser);
  if (staffUser && realName) {
    // Prefer the name from the staff user if we have it
    name = realName;
  }

  const userID = user.sparxUserId || user.userId;

  let email = staffUser?.emailAddress;

  if (isActuallySparxStaff || isAnonymousMode()) {
    company = {
      company_id: 'sparx',
      name: 'Sparx',
      created_at: 0,
      website: 'https://sparx-learning.com/',
    };

    // We don't have email for sparx staff, try to make one from their name instead as a best effort instead
    email = `${user.givenName}.${user.familyName}@sparx.co.uk`.toLowerCase();
  }

  return {
    user_id: userID,
    school_id: user.schoolId,
    name,
    email,
    user_hash: user.intercomHash,

    // company attributes
    company,

    action_color: '#319795',
    background_color: '#319795',
  };
};
