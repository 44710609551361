import { ISchoolStaffServiceClient } from '@sparx/api/apis/sparx/school/staff/schoolstaff/v2/schoolstaff.client';
import { TrainingProgressServiceClient } from '@sparx/api/apis/sparx/training/progress/v1/trainingprogress.client';
import { QueryClient } from '@tanstack/react-query';
import { createContext, useContext } from 'react';

import { ModuleSpec } from './types';

interface Context {
  schoolID: string;
  queryClient: QueryClient;
  trainingProgressClient: TrainingProgressServiceClient;
  // the school status client is used to get training progress off other staff
  // in the school
  // This could be made non-optional once reader has training
  staffClient?: ISchoolStaffServiceClient;

  videoAssetDirURL: string;
  moduleSpecs: ModuleSpec[];
  onTrainingCompleted?: () => void;

  // Should probably have client events in a shared package, so we have a standard type
  sendClientEvent: (
    event: { action: string; category: string },
    labels?: {
      [key: string]: string;
    },
  ) => void;
}

const context = createContext<Context>(
  // This is to make the typing happy but will error at runtime if we're using the default values.
  {} as Context,
);

export const useTrainingContext = () => useContext(context);

export const TrainingContextProvider = context.Provider;
