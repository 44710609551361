import {
  Box,
  Button,
  Checkbox,
  HStack,
  IconButton,
  Input,
  ModalBody,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import {
  ActivityPreparationProps,
  createBasicLessonActivityBlueprint,
  TaskBlueprint,
} from 'views/lessons/createactivity/activitytypes';

import { textToSkillBlueprintArray } from './utils';

interface CustomTaskInput {
  title: string;
  text: string;
  secondChance: boolean;
  flashcards: boolean;
}

const newCustomTaskInput = (secondChance: boolean): CustomTaskInput => ({
  text: '',
  title: '',
  secondChance,
  flashcards: false,
});

export const CreateCustomSkillsActivity = ({ doSubmit, getFooter }: ActivityPreparationProps) => {
  const secondChanceDefaultOn = true; // Always default to on
  const [tasks, setTasks] = useState<CustomTaskInput[]>([
    newCustomTaskInput(secondChanceDefaultOn),
  ]);
  const addTask = () => setTasks([...tasks, newCustomTaskInput(secondChanceDefaultOn)]);
  const removeTask = (index: number) => setTasks(tasks.filter((_, i) => i !== index));
  const updateTask = (index: number, task: Partial<CustomTaskInput>) => {
    setTasks(tasks.map((t, i) => (i === index ? { ...t, ...task } : t)));
  };

  const onSubmit = async () => {
    const bpTasks: TaskBlueprint[] = [];
    for (let i = 0; i < tasks.length; i++) {
      const task = tasks[i];
      const skills = textToSkillBlueprintArray(task.text, task.secondChance);
      const output: TaskBlueprint = {
        name: task.title || `Task ${i + 1}`,
        skills,
        flashcards: task.flashcards,
      };
      if (output.skills.length === 0) {
        alert(`No skills in task ${i + 1}`);
        return;
      }
      bpTasks.push(output);
    }

    if (bpTasks.length === 0) {
      alert('No tasks');
      return;
    }
    doSubmit(createBasicLessonActivityBlueprint(...bpTasks));
  };

  return (
    <>
      <ModalBody>
        <Box>
          {tasks.map((task, i) => (
            <Box key={i} p={2} bg="gray.50" borderRadius="md" mb={2}>
              <HStack spacing={2}>
                <Input
                  bg="white"
                  flex={1}
                  value={task.title}
                  onChange={e => updateTask(i, { title: e.target.value })}
                  placeholder={`Task ${i + 1}`}
                />
                <IconButton
                  aria-label="Remove"
                  onClick={() => removeTask(i)}
                  isDisabled={tasks.length <= 1}
                  colorScheme="red"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </IconButton>
              </HStack>
              <HStack spacing={2} py={2} fontSize="sm">
                <Text flex={1}>Skills:</Text>
                {!task.flashcards && (
                  <Checkbox
                    size="sm"
                    mt={2}
                    isChecked={task.secondChance}
                    onChange={e => updateTask(i, { secondChance: e.target.checked })}
                  >
                    Second chance
                  </Checkbox>
                )}
                <Checkbox
                  size="sm"
                  mt={2}
                  isChecked={task.flashcards}
                  onChange={e => updateTask(i, { flashcards: e.target.checked })}
                >
                  Flashcards
                </Checkbox>
              </HStack>
              <Textarea
                bg="white"
                fontFamily="monospace"
                height={300}
                value={task.text}
                onChange={e => updateTask(i, { text: e.target.value })}
                placeholder="List of skill ids separated by new lines..."
              />
            </Box>
          ))}
          <Button onClick={addTask} colorScheme="buttonTeal">
            Add new task
          </Button>
        </Box>
      </ModalBody>
      {getFooter(onSubmit)}
    </>
  );
};
