export interface MediaFormat {
  name: string;
  hash: string;
  ext: string;
  mime: string;
  width: number;
  height: number;
  size: number;
  path: string;
  url: string;
}

export interface MediaFormats {
  thumbnail?: MediaFormat;
  small?: MediaFormat;
  medium?: MediaFormat;
  large?: MediaFormat;
}

export interface Media {
  id: number;
  attributes: {
    name: string;
    alternativeText: string | null;
    caption: string | null;
    width: number;
    height: number;
    formats: MediaFormats;
    hash: string;
    ext: string;
    mime: string;
    size: number;
    url: string;
    previewUrl: string | null;
    provider: string;
    createdAt: string;
    updatedAt: string;
  };
}

// getMediaURL will return the url of the media at the requested format, or the original url if the format is not available
export const getMediaURL = (m: Media, format?: keyof MediaFormats) => {
  const url = (format && m.attributes.formats[format]?.url) || m.attributes.url;
  return replaceStaticDomain(replaceAssetsDomain(url));
};

const assetsRE = new RegExp('^https://assets.sparxhomework.uk');
const replaceAssetsDomain = (url: string): string =>
  !window.settings?.ASSETS_URL ? url : url.replace(assetsRE, window.settings.ASSETS_URL);

const staticRE = new RegExp('^https://static.sparxhomework.uk');
const replaceStaticDomain = (url: string): string =>
  !window.settings?.STATIC_URL ? url : url.replace(staticRE, window.settings.STATIC_URL);
