import { Product } from '@sparx/api/apis/sparx/types/product';
import { createContext, useContext } from 'react';

export type CommonParams = {
  schoolName: string;
  product: Product;
};

export type CommonParamKeys = keyof CommonParams;

export const CommonParamsContext = createContext<CommonParams>({
  schoolName: '',
  product: Product.PRODUCT_UNKNOWN,
});

export const useCommonParams = () => useContext(CommonParamsContext);
