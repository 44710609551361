import { Product } from '@sparx/api/apis/sparx/types/product';

export const getProductName = (product: Product) => {
  switch (product) {
    case Product.SPARX_MATHS:
      return 'Sparx Maths';
    case Product.SPARX_READER:
      return 'Sparx Reader';
    case Product.SPARX_SCIENCE:
      return 'Sparx Science';
    default:
      return 'Unknown';
  }
};
