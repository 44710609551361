import { TaskItem } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { Subject } from '@sparx/api/apis/sparx/science/v1/subject';
import { HelpMaterialOption } from 'components/helpmaterials';
import { EquationsSheetModal } from 'components/helpmaterials/equationsheet/EquationSheet';
import { PeriodicTableModal } from 'components/helpmaterials/periodictable/PeriodicTable';
import { CurriculumNameAQAGCSE, CurriculumNameEdexcelGCSE } from 'utils/content';

type HelpMaterialsProps = {
  eventContext: { activity: string };
  taskItem: TaskItem;
};

export const HelpMaterials = ({ eventContext, taskItem }: HelpMaterialsProps) => {
  let curriculumName: string | undefined = undefined;
  if (taskItem.contents?.contents.oneofKind === 'skill') {
    curriculumName = taskItem.contents.contents.skill.topicName.split('/').slice(0, 2).join('/');
  }
  // gcse physics get the relevant equation sheet
  // all chemistry gets the periodic table
  // biology gets nowt
  // anything without a subject defaults to the periodic table, for backwards compatibility
  let helpToShow = HelpMaterialOption.NONE;
  switch (taskItem.contents?.subject) {
    case Subject.PHYSICS:
      if (curriculumName === CurriculumNameEdexcelGCSE) {
        helpToShow = HelpMaterialOption.EDEXCEL_EQUATION_SHEET;
      } else if (curriculumName === CurriculumNameAQAGCSE) {
        helpToShow = HelpMaterialOption.AQA_EQUATION_SHEET;
      }
      break;
    case Subject.BIOLOGY:
      helpToShow = HelpMaterialOption.NONE;
      break;
    case Subject.CHEMISTRY:
    default:
      helpToShow = HelpMaterialOption.PERIODIC_TABLE;
      break;
  }

  switch (helpToShow) {
    case HelpMaterialOption.PERIODIC_TABLE:
      return <PeriodicTableModal eventContext={eventContext} />;
    case HelpMaterialOption.AQA_EQUATION_SHEET:
      return (
        <EquationsSheetModal
          type={HelpMaterialOption.AQA_EQUATION_SHEET}
          eventContext={{ ...eventContext, equationSheetType: 'aqa' }}
        />
      );
    case HelpMaterialOption.EDEXCEL_EQUATION_SHEET:
      return (
        <EquationsSheetModal
          type={HelpMaterialOption.EDEXCEL_EQUATION_SHEET}
          eventContext={{ ...eventContext, equationSheetType: 'edexcel' }}
        />
      );
    case HelpMaterialOption.NONE:
      return null;
  }
};
