import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  type SortingStrategy,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { faArrowDown, faArrowUp, faGripVertical, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { motion } from 'motion/react';
import React, { ComponentProps, useCallback, useMemo, useRef, useState } from 'react';

import { CorrectIcon } from '../components/CorrectIcon';
import { QuestionAction } from '../question/input';
import { LayoutElementProps, useSparxQuestionContext } from '../question/SparxQuestionContext';
import {
  ICardElement,
  IElement,
  IInput,
  ISlotElement,
  ISortingListElement,
} from '../question/types';
import { CustomKeyboardSensor } from '../utils/DnDKeyboardSensor';
import { LayoutElement, LayoutElements } from './LayoutElement';
import styles from './SortingListElement.module.css';

export const SortingListElement = ({ element }: LayoutElementProps<ISortingListElement>) => {
  const { sendAction, readOnly, input } = useSparxQuestionContext();
  // Custom keyboard sensor for better list sorting + pointer default + other for old safari
  const dndSensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(CustomKeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
      onActivation: e => {
        e.event.stopPropagation();
      },
    }),
    useSensor(PointerSensor),
  );

  const [activeSlot, setActiveSlot] = useState<SlotWithCard | null>(null);
  // Refs for scrolling into view when shifting cards
  const containerRef = useRef<HTMLDivElement | null>(null);
  const shiftedCardRef = useRef<string | null>(null);
  const layoutTimeout = useRef<NodeJS.Timeout | null>(null);

  const slotsWithCards = useSlotsWithCards(element.slots, element.cards, input, activeSlot);

  const handleAbort = useCallback(() => {
    const noInput = slotsWithCards
      .filter(s => !s.locked)
      .every(s => !input.slots?.[s.element.ref]?.card_ref);
    if (!noInput && activeSlot) {
      sendAction({
        action: 'drop_card',
        cardRef: activeSlot.cardRef,
        slotRef: activeSlot.element.ref,
      });
    }
    setActiveSlot(null);
  }, [activeSlot, input.slots, sendAction, slotsWithCards]);

  const handleDragEnd = useCallback(
    (e: DragEndEvent) => {
      const over = e.over?.id;
      const active = e.active.id;
      if (typeof active !== 'string' || typeof over !== 'string' || over === active) {
        handleAbort();
        return;
      }

      // Get the starting order of the cards
      const cardRefs = slotsWithCards.filter(s => !s.locked).map(s => s.cardRef);

      // find the indexes of the active and over cards
      const overIdx = cardRefs.indexOf(over);
      const activeIdx = cardRefs.indexOf(active);
      if (overIdx === -1 || activeIdx === -1) {
        handleAbort();
        return;
      }

      // Move the active card to the over card position, shifting the cards up or down as needed
      cardRefs.splice(activeIdx, 1);
      cardRefs.splice(overIdx, 0, active);

      // Update the input slots with the new card order
      let cardIdx = 0;
      const actions: QuestionAction[] = [];
      for (const s of slotsWithCards) {
        if (s.locked) continue;
        if (cardIdx < cardRefs.length) {
          actions.push({
            action: 'drop_card',
            cardRef: cardRefs[cardIdx],
            slotRef: s.element.ref,
          });
          cardIdx += 1;
        }
      }
      setActiveSlot(null);

      sendAction({
        action: 'multi_action',
        actions,
      });
    },
    [handleAbort, sendAction, slotsWithCards],
  );

  const handleDragStart = useCallback(
    (e: DragStartEvent) => {
      if (typeof e.active.id !== 'string') {
        return;
      }

      const slot = slotsWithCards.find(s => s.cardRef === e.active.id);
      if (!slot) return;

      setActiveSlot({ ...slot });

      // Remove the card being dragged from the slot, this will make the input invalid so the user can't submit while dragging.
      sendAction({
        action: 'drop_card',
        cardRef: e.active.id,
        slotRef: '',
      });
    },
    [sendAction, slotsWithCards],
  );

  const shiftCard = useCallback(
    (cardRef: string, direction: -1 | 1) => {
      const cardRefs = slotsWithCards.filter(s => !s.locked).map(s => s.cardRef);

      // find the index of the card
      const idx = cardRefs.indexOf(cardRef);
      if (idx === -1) {
        return;
      }

      // find the new index
      const newIdx = idx + direction;
      if (newIdx < 0 || newIdx >= cardRefs.length) {
        return;
      }

      // Swap the positions
      [cardRefs[idx], cardRefs[newIdx]] = [cardRefs[newIdx], cardRefs[idx]];

      // Update the input slots with the new card order
      // We update all positions to handle the when this is the first move and they haven't been previously set
      let cardIdx = 0;
      const actions: QuestionAction[] = [];
      for (const s of slotsWithCards) {
        if (s.locked) continue;
        actions.push({
          action: 'drop_card',
          cardRef: cardRefs[cardIdx],
          slotRef: s.element.ref,
        });
        cardIdx += 1;
      }
      sendAction({
        action: 'multi_action',
        actions,
      });
      // Save the card ref we are shifting with, so we can scroll it into view if it's not already when the layout animation starts
      shiftedCardRef.current = cardRefs[idx];
    },
    [sendAction, slotsWithCards],
  );

  // When a card begins a layout animation, if we are shifting cards then bring the card we are swapping with into view
  const onLayoutAnimStart = () => {
    if (layoutTimeout.current) {
      clearTimeout(layoutTimeout.current);
    }
    if (!shiftedCardRef.current) return;

    layoutTimeout.current = setTimeout(() => {
      if (!containerRef.current) return;
      containerRef.current
        .querySelector(`[data-card-ref="${shiftedCardRef.current}"]`)
        ?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      shiftedCardRef.current = null;
    }, 0);
  };

  let columnEl: React.ReactNode = null;
  let labels: IElement[] = [];

  if (element.labelStyle === 'down-arrow') {
    columnEl = generateDownArrowLabel(element.labelContent, slotsWithCards.length);
  } else if (element.labelStyle) {
    labels = generateLabels(element.labelStyle, element.labelContent, slotsWithCards);
  }

  return (
    <DndContext
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragCancel={handleAbort}
      onDragAbort={handleAbort}
      sensors={dndSensors}
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
    >
      <SortableContext items={slotsWithCards.map(s => s.cardRef)} disabled={readOnly}>
        <div
          ref={containerRef}
          className={classNames(styles.SortingListElementGrid, {
            [styles.WithLabels]: columnEl || labels.length > 0,
          })}
        >
          {columnEl}
          {slotsWithCards.map((s, idx) => (
            <React.Fragment key={s.cardRef}>
              {labels.length == slotsWithCards.length && (
                <div className={styles.RowLabel}>
                  <LayoutElement element={labels[idx]} />
                </div>
              )}
              <Sortable slot={s} onLayoutAnimStart={onLayoutAnimStart}>
                {(props, isDragging) => (
                  <ListCard
                    slot={s}
                    dndProps={props}
                    isDragging={isDragging}
                    moveUp={
                      !readOnly && !s.firstUnlocked ? () => shiftCard(s.cardRef, -1) : undefined
                    }
                    moveDown={
                      !readOnly && !s.lastUnlocked ? () => shiftCard(s.cardRef, 1) : undefined
                    }
                  />
                )}
              </Sortable>
            </React.Fragment>
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
};

export const Sortable = ({
  slot,
  onLayoutAnimStart,
  children,
}: {
  slot: SlotWithCard;
  onLayoutAnimStart: () => void;
  children: (props: ComponentProps<'div'>, isDragging: boolean) => React.ReactNode;
}) => {
  const { shuffleSeed } = useSparxQuestionContext();
  const { isDragging, attributes, listeners, setNodeRef, transform } = useSortable({
    id: slot.cardRef,
    disabled: slot.locked,
    strategy: getSortingStrategy(slot.numLockedBefore, slot.numLockedAfter, slot.locked),
    // This fixes a bug when dropping an element after moving over a locked slot,
    // without it shows the move animation again when dropped
    animateLayoutChanges: () => false,
  });

  const anim = transform
    ? {
        x: transform.x,
        y: transform.y,
        scale: isDragging ? 1.02 : 1,
        zIndex: isDragging ? 2 : 1,
      }
    : {
        x: 0,
        y: 0,
        scale: 1,
        zIndex: slot.locked ? 0 : 1,
      };

  return (
    <motion.div
      ref={setNodeRef}
      // Include the shuffle seed in the layout id as a best effort so when we transition between
      // different questions with the same cardRefs the layout ids dont match
      layoutId={shuffleSeed + slot.cardRef}
      animate={anim}
      transition={{
        duration: !isDragging ? 0.2 : 0,
        scale: {
          duration: 0.2,
        },
        zIndex: {
          delay: isDragging ? 0 : 0.2,
        },
      }}
      onLayoutAnimationStart={onLayoutAnimStart}
    >
      {children({ ...listeners, ...attributes }, isDragging)}
    </motion.div>
  );
};

const getSortingStrategy = (
  numLockedAbove: number,
  numLockedBelow: number,
  isLocked: boolean,
): SortingStrategy => {
  if (isLocked) {
    return () => {
      return null;
    };
  }

  return props => {
    const transform = verticalListSortingStrategy(props);

    if (!transform) return null;

    if (transform.y > 0) return { ...transform, y: transform.y * (numLockedBelow + 1) };

    if (transform.y < 0) return { ...transform, y: transform.y * (numLockedAbove + 1) };
    return transform;
  };
};

interface SlotWithCard {
  element: ISlotElement;
  locked: boolean;
  numLockedBefore: number;
  numLockedAfter: number;
  firstUnlocked?: boolean;
  lastUnlocked?: boolean;
  cardRef: string;
  contentElement: ICardElement | ISlotElement;
}

// Returns the card that should be in the given slot
const getSlotCard = (
  slot: ISlotElement,
  locked: boolean,
  inputSlot: IInput['slots'],
  cards: ICardElement[],
  nextCard: () => ICardElement | undefined,
): { cardRef: string; contentElement: ICardElement | ISlotElement } => {
  const cardRef = inputSlot?.[slot.ref]?.card_ref;

  if (locked) {
    return {
      cardRef: cardRef || '',
      contentElement: slot,
    };
  }

  let card: ICardElement | undefined;

  if (cardRef) {
    card = cards.find(c => c.ref === cardRef);
    if (card) {
      return {
        cardRef: card.ref,
        contentElement: card,
      };
    }
  } else {
    // If the input doesn't have a card ref, then the question is brand new and we can use the cards in order
    card = nextCard();
  }

  if (card) {
    return {
      cardRef: card.ref,
      contentElement: card,
    };
  }

  // fallback
  return {
    cardRef: slot.ref,
    contentElement: slot,
  };
};

const useSlotsWithCards = (
  slotEls: ISlotElement[],
  cardEls: ICardElement[],
  input: IInput,
  activeSlot: SlotWithCard | null,
) => {
  return useMemo(() => {
    const cardList = [...cardEls];

    const noInput = slotEls
      .filter(s => !input.slots?.[s.ref].locked)
      .every(s => !input.slots?.[s.ref]?.card_ref);
    const nextCard = () => {
      if (!noInput && activeSlot) {
        return activeSlot.contentElement as ICardElement;
      }
      return cardList.shift();
    };

    const slots = slotEls.map<SlotWithCard>(element => {
      const locked = !!input.slots?.[element.ref]?.locked;
      return {
        element,
        locked,
        numLockedBefore: 0,
        numLockedAfter: 0,
        ...getSlotCard(element, locked, input.slots, cardEls, nextCard),
      };
    });

    // To handle locked slots we need to calculate the number of locked slots before and after each slot
    for (let i = 0; i < slots.length; i++) {
      for (let j = i - 1; j >= 0 && slots[j].locked; j--) {
        slots[i].numLockedBefore += 1;
      }

      for (let j = i + 1; j < slots.length && slots[j].locked; j++) {
        slots[i].numLockedAfter += 1;
      }
    }

    // Mark the first and last unlocked slots
    const firstUnlocked = slots.find(s => !s.locked);
    if (firstUnlocked) firstUnlocked.firstUnlocked = true;
    const lastUnlocked = slots.findLast(s => !s.locked);
    if (lastUnlocked) lastUnlocked.lastUnlocked = true;

    return slots;
  }, [cardEls, slotEls, input.slots, activeSlot]);
};

const ListCard = ({
  slot,
  isDragging,
  dndProps = {},
  moveUp,
  moveDown,
}: {
  slot: SlotWithCard;
  isDragging?: boolean;
  dndProps?: ComponentProps<'div'>;
  moveUp?: () => void;
  moveDown?: () => void;
}) => {
  const { readOnly, questionMarkingMode, gapEvaluations } = useSparxQuestionContext();

  // We only show the granular feedback if the question is in gap mode
  const showMark =
    questionMarkingMode === 'gap' &&
    gapEvaluations &&
    gapEvaluations[slot.element.ref] !== undefined;
  const correct = !!gapEvaluations && !!gapEvaluations[slot.element.ref]?.correct;

  const canDrag = !readOnly && !slot.locked;

  const disabledButtons = readOnly || isDragging;

  return (
    <div
      data-card-ref={slot.cardRef}
      className={classNames(styles.SortingListElementCard, {
        [styles.Dragging]: isDragging,
        [styles.ReadOnly]: readOnly,
        [styles.Correct]: showMark && correct,
        [styles.Incorrect]: showMark && !correct,
        [styles.Locked]: slot.locked,
      })}
    >
      {showMark && <CorrectIcon correct={correct} className={styles.MarkIcon} />}
      <div
        className={classNames(styles.DragHandle, { [styles.Draggable]: canDrag })}
        {...(canDrag ? { ...dndProps } : undefined)}
      >
        <FontAwesomeIcon
          className={styles.Handle}
          icon={slot.locked ? faLock : faGripVertical}
          fixedWidth
        />
        <div className={styles.Content}>
          <LayoutElements element={slot.contentElement} />
        </div>
      </div>
      {!slot.locked && (
        <div className={styles.ButtonsContainer}>
          <button
            aria-label="Move up"
            aria-disabled={disabledButtons || !moveUp}
            onKeyDown={e => {
              // Stop propagating this event so the submit handler doesn't fire
              if (e.key === 'Enter') e.stopPropagation();
            }}
            onClick={disabledButtons ? undefined : moveUp}
            disabled={disabledButtons || !moveUp}
          >
            <div>
              <FontAwesomeIcon icon={faArrowUp} fixedWidth />
            </div>
          </button>
          <button
            aria-label="Move down"
            aria-disabled={disabledButtons || !moveDown}
            onKeyDown={e => {
              // Stop propagating this event so the submit handler doesn't fire
              if (e.key === 'Enter') e.stopPropagation();
            }}
            onClick={disabledButtons ? undefined : moveDown}
            disabled={disabledButtons || !moveDown}
          >
            <div>
              <FontAwesomeIcon icon={faArrowDown} fixedWidth />
            </div>
          </button>
        </div>
      )}
    </div>
  );
};

const generateLabels = (
  type: 'steps' | 'labels',
  labels: IElement[],
  slots: SlotWithCard[],
): IElement[] => {
  if (type === 'steps') {
    return slots.map((_, i) => {
      return {
        element: 'text',
        text: `Step ${i + 1}`,
        type: ['markdown'],
      };
    });
  }

  if (labels.length !== slots.length) return [];

  return labels;
};

const generateDownArrowLabel = (labels: IElement[], numSlots: number): React.ReactNode => {
  return (
    <div className={styles.FullHeightLabel} style={{ gridRow: `span ${numSlots}` }}>
      {labels.length > 0 && <LayoutElement element={labels[0]} />}
      <div className={styles.DownArrow}>
        <div className={styles.Line} />
        <div className={styles.DownTriangle} />
      </div>

      {labels.length > 1 && <LayoutElement element={labels[1]} />}
    </div>
  );
};
