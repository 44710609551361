import { StaffRole } from '@sparx/api/apis/sparx/school/staff/v2/staff';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { useCommonParams } from '@sparx/query';
import { Checkbox } from '@sparx/sparx-design/components/checkbox/Checkbox';
import classnames from 'classnames';
import { ComponentProps, ReactNode } from 'react';

import { useStaffContext } from '../Context';
import { makeGetUserRoleName } from '../utils';
import { RoleToggleProps } from './RoleSelector';
import styles from './UserRoleRow.module.css';

/**
 * Exposes callbacks for determining if a row representing a role should be checked and for
 * setting its checked state (with a subject or without - in which case, the default subject is used)
 */
interface UserRoleRowProps extends RoleToggleProps {
  staffRole: StaffRole;
  product?: Product;
  tooltip?: ReactNode;
}

export const UserRoleRow = ({
  staffRole,
  product,
  hasRole,
  toggle,
  tooltip,
  className,
  ...rest
}: UserRoleRowProps & ComponentProps<'div'>) => {
  const { product: thisProduct } = useCommonParams();
  const { getInfoTooltip } = useStaffContext();
  const getUserRoleName = makeGetUserRoleName(thisProduct);
  const roleProduct = product ?? thisProduct;

  return (
    <div
      className={classnames('SpxUserRoleRow-root', styles.UserRoleRow, className)}
      key={staffRole}
      {...rest}
    >
      <Checkbox
        className={styles.Checkbox}
        checked={hasRole(staffRole, roleProduct)}
        onCheckedChange={() => toggle(staffRole, roleProduct)}
        label={
          <div>
            {getUserRoleName(staffRole)}
            {tooltip && getInfoTooltip(tooltip)}
          </div>
        }
      />
    </div>
  );
};
