import { ChakraProvider } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { ThemeProvider } from '@sparx/design/context';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ensureTokenFetcherStarted } from 'api';
import { queryClient } from 'api/client';
import { router } from 'app/Content';
import { NoCookies } from 'components/errorpages/NoCookies';
import { RouterProvider } from 'react-router-dom';
import { theme } from 'theme/theme';

import sparxTheme from './app.module.css';
import { NavigationControlProvider } from './BackLink';

const hasCookiesEnabled = (() => {
  try {
    // Create cookie
    document.cookie = 'cookietest=1';
    const ret = document.cookie.indexOf('cookietest=') !== -1;
    // Delete cookie
    document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';
    return ret;
  } catch (e) {
    return false;
  }
})();

// Start the token fetcher, but not if cookies arn't enabled
hasCookiesEnabled && ensureTokenFetcherStarted();

export const App = () => {
  return (
    <ThemeProvider themeStyles={sparxTheme}>
      <Sentry.ErrorBoundary showDialog>
        <QueryClientProvider client={queryClient}>
          <ChakraProvider
            theme={theme}
            toastOptions={{
              defaultOptions: {
                position: 'bottom-left',
              },
            }}
          >
            {hasCookiesEnabled ? (
              <NavigationControlProvider>
                <RouterProvider router={router} />
              </NavigationControlProvider>
            ) : (
              <NoCookies />
            )}
            <ReactQueryDevtools initialIsOpen={false} />
          </ChakraProvider>
        </QueryClientProvider>
      </Sentry.ErrorBoundary>
    </ThemeProvider>
  );
};
