import { Box, HStack, IconButton, Text } from '@chakra-ui/react';
import { faGripVertical, faInfoCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TopicWithStrand } from 'api/content';
import { HigherOnlyTopicTag, SeparateOnlyTopicTag } from 'components/Tag';
import React, { ComponentProps } from 'react';
import { isTopicHigherOnly, isTopicSeparateOnly } from 'utils/content';
import { QuestionBrowserModal } from 'views/planner/components/QuestionBrowser';
import { TopicBreadcrumb } from 'views/planner/components/TopicPanel';

interface TopicDataProps {
  topic: TopicWithStrand | undefined;
  dragged?: boolean;
  onRemove?: () => void;
  draggableProps?: ComponentProps<'div'>;
  readonly?: boolean;
}

export const TopicData = React.memo(
  ({ topic, dragged, onRemove, draggableProps, readonly }: TopicDataProps) => {
    const cursor = readonly ? undefined : dragged ? 'grabbing' : 'grab';

    const isHigher = topic?.topic && isTopicHigherOnly(topic?.topic);
    const isSeparate = topic?.topic && isTopicSeparateOnly(topic?.topic);

    return (
      <Box borderRadius="md" overflow="hidden" display="flex" alignItems="stretch">
        <Box
          px={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
          bg="teal.600"
          color="white"
          {...draggableProps}
          cursor={cursor}
          sx={{ touchAction: 'none' }}
        >
          <FontAwesomeIcon
            icon={faGripVertical}
            style={{ visibility: readonly ? 'hidden' : 'visible' }}
          />
        </Box>
        <Box
          px={4}
          py={2}
          bg="gray.100"
          flex={1}
          {...draggableProps}
          cursor={cursor}
          sx={{ touchAction: 'none' }}
        >
          <Text fontWeight="500">
            {topic?.topic?.displayName || 'Unknown topic'}
            <Text as="span" color="gray.400">
              {' '}
              - {topic?.topic?.code}
            </Text>
          </Text>
          <TopicBreadcrumb topic={topic} />
          {(isHigher || isSeparate) && (
            <HStack mt={1}>
              {isHigher && <HigherOnlyTopicTag />}
              {isSeparate && <SeparateOnlyTopicTag />}
            </HStack>
          )}
        </Box>
        <Box px={2} py={2} bg="gray.100" display="flex" alignItems="center" justifyContent="center">
          {!onRemove && !readonly && (
            <IconButton aria-label="" fontSize="lg" visibility="hidden">
              <FontAwesomeIcon icon={faTrash} />
            </IconButton>
          )}
          {topic && (
            <QuestionBrowserModal topic={topic}>
              {onOpen => (
                <IconButton aria-label="Info" fontSize="lg" color="blue.700" onClick={onOpen}>
                  <FontAwesomeIcon icon={faInfoCircle} />
                </IconButton>
              )}
            </QuestionBrowserModal>
          )}
          {onRemove && !readonly && (
            <IconButton aria-label="Remove" fontSize="lg" color="red.700" onClick={onRemove}>
              <FontAwesomeIcon icon={faTrash} />
            </IconButton>
          )}
        </Box>
      </Box>
    );
  },
);
TopicData.displayName = 'TopicData';

export const ConsolidationTopic = () => (
  <Box
    borderRadius="md"
    overflow="hidden"
    display="flex"
    alignItems="stretch"
    borderWidth={2}
    borderColor="gray.300"
  >
    <Box px={2} display="flex" alignItems="center" justifyContent="center" bg="gray.300">
      <FontAwesomeIcon icon={faGripVertical} visibility="hidden" />
    </Box>
    <Box px={4} py={2} bg="white" flex={1} color="gray.500">
      Consolidation
    </Box>
  </Box>
);
