import { Box, Button, Flex, Heading, Image, Text, VStack } from '@chakra-ui/react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Task } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { isComplete } from '@sparx/packageactivity';
import { useActivity, usePackage } from 'api/packages';
import { useBackLink } from 'app/BackLink';
import classNames from 'classnames';
import { NotFound } from 'components/errorpages/NotFound';
import FlashCardsIcon from 'components/flashcards/flashcards_white.svg';
import { useFlashCardCounts } from 'components/flashcards/hooks';
import { SmallFlashcard, SmallFlashcardContainer } from 'components/flashcards/SmallFlashcard';
import { LargeLoading } from 'components/loading/LargeLoading';
import { Title } from 'components/pagetitle/PageTitle';
import { AnimatePresence } from 'motion/react';
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { isTaskFlashcards, isTaskUnstartedFlashcards } from 'utils/flashcards';
import { useKeyPress } from 'utils/hooks/keypress';
import { HomeworkCancelledDialog } from 'views/package/HomeworkCancelledDialog';
import { AnimatedPage } from 'views/task/AnimatedPage';
import { HomeworkComplete } from 'views/task/HomeworkComplete';
import { Navigator } from 'views/task/Navigator';
import { Results } from 'views/task/Results';

import { ActivityDeliveryWithUnload } from './ActivityDelivery';
import styles from './TaskView.module.css';

export const TaskView = () => {
  const navigate = useNavigate();

  const params = useParams();
  const packageID = params.packageID;
  const taskID = params.taskID;
  const page = params.taskItem
    ? params.taskItem === 'results' ||
      params.taskItem === 'complete' ||
      params.taskItem === 'fcintro'
      ? params.taskItem
      : parseInt(params.taskItem)
    : undefined;

  const { data: pkgData } = usePackage(packageID || '', { enabled: !!packageID });

  // If this is an IL package then the back link should go back to IL
  const backlink = useMemo(() => {
    if (pkgData?.package?.type === 'independentlearning') {
      return `/independentlearning`;
    } else if (pkgData?.package?.type === 'assessment-fixup') {
      return `/assessments`;
    } else if (page === 'complete') {
      return '/packages';
    } else {
      return `/packages/${packageID}`;
    }
  }, [pkgData?.package?.type, page, packageID]);

  useBackLink(backlink);

  if (!packageID || !taskID) {
    return <NotFound />;
  }

  const taskName = `packages/${packageID}/tasks/${taskID}`;

  if (page === 'complete') {
    return (
      <HomeworkComplete
        pkg={pkgData?.package}
        onFinish={(replace = false) => navigate(backlink, { replace })}
      />
    );
  }

  return (
    <>
      <TaskDelivery
        packageID={packageID}
        taskID={taskID}
        page={page}
        onSetPage={(page, replace = false) => navigate(`/${taskName}/${page}`, { replace })}
        onBack={() => navigate(backlink)}
      />
      <HomeworkCancelledDialog pkg={pkgData?.package} />
    </>
  );
};

interface TaskDeliveryProps {
  packageID: string;
  taskID: string;
  page?: number | 'results' | 'fcintro';
  onSetPage: (page: number | 'results' | 'fcintro' | 'complete', replace?: boolean) => void;
  onBack: () => void;
}

export const TaskDelivery = ({ packageID, taskID, page, onSetPage, onBack }: TaskDeliveryProps) => {
  const taskName = `packages/${packageID}/tasks/${taskID}`;
  const taskItemName =
    page && page !== 'results' && page !== 'fcintro' ? `${taskName}/items/${page}` : '';

  const { data: activity } = useActivity(taskItemName);
  const { data: pkgData, isLoading: isLoadingPackages } = usePackage(packageID);
  const task = pkgData?.package?.contents?.tasks.find(t => t.name === taskName);
  const taskItem = task?.contents?.taskItems.find(i => i.name === taskItemName);

  const isAssessmentFixUp = pkgData?.package?.type === 'assessment-fixup';

  // Behaviour for navigating between task items and ensuring animation between them
  const [lastIndex, setLastIndex] = useState(-1);
  const fakePageIndex = page === 'results' || page === 'fcintro' ? 10000 : page || 0;
  const reverse = fakePageIndex < lastIndex;
  const openTaskItem = useCallback(
    (page: number | 'results' | 'fcintro', replace = false) => {
      setLastIndex(fakePageIndex);
      onSetPage(page, replace);
    },
    [fakePageIndex, onSetPage],
  );
  // Navigate to the first incomplete item if landing on the root page
  useEffect(() => {
    if (!page && task?.contents?.taskItems && task.contents.taskItems.length > 0) {
      const firstIncompleteItem = task.contents.taskItems.findIndex(itm => !itm.state?.completed);
      const nextPage =
        firstIncompleteItem === -1
          ? 'results'
          : isTaskUnstartedFlashcards(task)
            ? 'fcintro'
            : firstIncompleteItem + 1;
      console.log('Navigating to first incomplete task item:', nextPage);
      openTaskItem(nextPage, true);
    }
  }, [page, task, openTaskItem]);

  if (isLoadingPackages) {
    return <LargeLoading />;
  }
  if (!task) {
    return <NotFound />;
  }

  const toHomeworkComplete = () => {
    onSetPage('complete', false);
  };

  // if the package is an assignment, and has been complete, navigate to the homework complete page on finish
  const shouldShowHomeworkComplete = () => {
    const isAssignment =
      pkgData?.package?.type === '' && pkgData?.package?.assignment?.lessonId === '';
    return isAssignment && isComplete(pkgData.package?.state?.completion);
  };

  const actions = {
    onFinish: () => (shouldShowHomeworkComplete() ? toHomeworkComplete() : onBack()),
    onContinue: () => {
      for (const start of [page === 'results' || page === 'fcintro' ? 0 : page || 0, 0]) {
        for (let i = start; i < (task?.contents?.taskItems?.length || 0); i++) {
          const taskItem = task?.contents?.taskItems[i];
          if (!taskItem?.state?.completed) {
            return openTaskItem(i + 1);
          }
        }
      }
      return openTaskItem('results');
    },
  };

  const isFlashcards = isTaskFlashcards(task);
  const flashcardConf: FlashcardsConfig | undefined =
    isFlashcards && task.state?.completion
      ? {
          cardsRemaining: Math.max(
            0,
            task.state.completion.size - (task.state.completion.progress?.C || 0),
          ),
        }
      : undefined;

  const getContent = () => {
    switch (page) {
      case 'results':
        return (
          <AnimatedPage key="results" reverse={reverse}>
            <Results task={task} {...actions} />
          </AnimatedPage>
        );
      case 'fcintro':
        // const firstIncompleteItem =
        //   task.contents?.taskItems.findIndex(itm => !itm.state?.completed) || 0;
        // const nextPage = firstIncompleteItem === -1 ? 'results' : firstIncompleteItem + 1;
        return (
          <AnimatedPage key="respidintro" reverse={reverse}>
            <FlashcardsIntro task={task} next={actions.onContinue} />
          </AnimatedPage>
        );
      default:
        return (
          activity &&
          taskItem && (
            <AnimatedPage key={taskItemName} reverse={reverse}>
              <ActivityDeliveryWithUnload
                activity={activity}
                taskItem={taskItem}
                canSkip={
                  (task?.contents?.taskItems?.filter(i => !i.state?.completed).length || 0) > 1
                }
                showTaskItemTitle={isAssessmentFixUp}
                hidePeriodicTable={isAssessmentFixUp || isFlashcards}
                {...actions}
              />
            </AnimatedPage>
          )
        );
    }
  };

  return (
    <Box
      className={classNames(styles.TaskDeliveryOuter, {
        [styles.Flashcards]: isFlashcards,
      })}
      height="100%"
      width="100%"
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg={
        isFlashcards
          ? 'linear-gradient(122.89deg, #1AA2A1 28.58%, #308E8D 53.78%, #0A7B7A 85.76%)'
          : undefined
      }
    >
      <Box
        height="100%"
        width="100%"
        flexDirection="column"
        display="flex"
        className={classNames(styles.TaskDeliveryInner)}
      >
        {
          <>
            {task && pkgData?.package && (
              <Title title={`${task.title} - ${pkgData.package.title}`} />
            )}
            <Navigator task={task} selected={page || 0} setSelected={openTaskItem} />
          </>
        }
        <ContentAnimationWrapper reverse={reverse} flashcardConf={flashcardConf}>
          {getContent()}
        </ContentAnimationWrapper>
      </Box>
    </Box>
  );
};

interface FlashcardsConfig {
  cardsRemaining: number;
}

const ContentAnimationWrapper = ({
  children,
  reverse,
  flashcardConf,
}: PropsWithChildren<{ reverse: boolean; flashcardConf?: FlashcardsConfig }>) => {
  const container = useRef<HTMLDivElement | null>(null);
  const hasChildren = Boolean(children);

  const isFlashcards = !!flashcardConf;

  return (
    <Box width="100%" flex={1} position="relative">
      {isFlashcards &&
        Array.from({ length: Math.min(6, flashcardConf.cardsRemaining) }).map((_, i, arr) => {
          const j = arr.length - i;
          return <FlashcardFake key={i} num={j} />;
        })}
      <Box
        className={styles.ContentAnimationWrapper}
        bg="white"
        boxShadow="elevationMedium"
        flex={1}
        style={{ overflow: 'clip' }} // If not supported, fallback to overflow: hidden below
        overflow="hidden"
        // NOTE: Ideally we would use `overflow: clip` but this is a recent addition
        // so is not supported on a lot of browsers.
        //
        // I am finding that the scrollLeft of this container is changing somehow
        // which is causing glitches in the animation. `overflow: clip` should fix
        // it - but instead I am using this hack to ensure that the container is
        // always fixed to the left. You can see this behaviour by removing the
        // `animate` prop on the AnimatedPage component, then examining the scrollLeft
        // of this div element at runtime - you will see it can be non-zero.
        onScroll={() => container.current && (container.current.scrollLeft = 0)}
        position="relative"
        transform="translate(0px, 0px)"
        width="100%"
        height="100%"
        ref={container}
      >
        <ContentLoading active={!hasChildren} />
        <AnimatePresence mode="wait" custom={reverse}>
          {children}
        </AnimatePresence>
      </Box>
    </Box>
  );
};

const FlashcardFake = ({ num }: { num: number }) => (
  <Box
    width={`calc(100% - ${num * 8}px)`}
    height="100%"
    bg="white"
    position="absolute"
    top={`${num * 4}px`}
    left={`${num * 4}px`}
    boxShadow="elevationMediumDark"
    borderRadius="md"
  />
);

const ContentLoading = ({ active }: { active: boolean }) => (
  <Box
    inset="0 0 0 0"
    position="absolute"
    display="flex"
    alignItems="center"
    justifyContent="center"
    pointerEvents="none"
    opacity={active ? 1 : 0}
    style={{ transitionDelay: active ? '1s' : '0s' }}
    transition={active ? 'opacity 1s ease-in-out' : 'opacity 0.3s ease-in-out'}
  >
    <LargeLoading color="gray.100" />
  </Box>
);

const FlashcardsIntro = ({ task, next }: { task: Task; next: () => void }) => {
  const { taskCounts: counts } = useFlashCardCounts(task);
  useKeyPress({ Enter: next });

  useEffect(() => {
    if (counts === null) {
      next();
    }
  }, [next, counts]);

  if (!counts) {
    return <>Redirecting</>;
  }

  return (
    <VStack width="100%" height="100%" gap={0}>
      <Flex
        width={'100%'}
        alignItems="center"
        flexDirection="column"
        overflow="auto"
        p={4}
        flex={'1 1 auto'}
      >
        <Flex alignItems={'baseline'} mt="auto">
          <Heading
            mb={[2, 3]}
            bgGradient="linear-gradient(90deg, #FCA600 0%, #D34A59 100%)"
            bgClip="text"
            px={1}
            fontSize={[40, 60, 70]}
            letterSpacing="-0.3rem"
            textAlign={'center'}
          >
            Flashcards
          </Heading>
          <Image src={FlashCardsIcon} width={['40px', '60px', '70px']} ml={4} />
        </Flex>
        <Text color="gray.500" fontSize="lg" textAlign={'center'}>
          Key knowledge you need at your fingertips
        </Text>
        <Heading size="lg" mt={[2, 3]}>
          {counts.total} flashcards
        </Heading>
        <SmallFlashcardContainer mt={[2, 3]} mb="auto">
          <SmallFlashcard category="learning" value={counts.newCards} />
          <SmallFlashcard category="review" value={counts.reviewCards} />
        </SmallFlashcardContainer>
      </Flex>
      <Flex
        borderTop={'2px solid'}
        borderColor="gray.100"
        width={'100%'}
        justifyContent={'center'}
        flex={'0 0 auto'}
      >
        <Button
          onClick={next}
          variant="flashcard"
          rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
          minH={10}
          my={2}
        >
          Let&apos;s go
        </Button>
      </Flex>
    </VStack>
  );
};

export default TaskView;
