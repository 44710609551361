import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Flex,
  Heading,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  faArrowUpRightFromSquare,
  faCirclePlay as faCirclePlaySolid,
  faVideo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { useSession } from 'api/sessions';
import { UserSettings, useUpdateUserSetting, useUserSetting } from 'api/usersettings';
import { useWhatsNewPosts } from 'api/whatsnew';
import notificationDot from 'app/images/notification_dot_small.svg';
import { useClientEvent } from 'components/ClientEventProvider';
import { PageContainer } from 'components/PageContainer';
import { PageTitle } from 'components/pagetitle/PageTitle';
import { ScrollToAnchor } from 'components/scrollToAnchor';
import { BlocksRenderer } from 'components/StrapiBlocksRenderer';
import { SuspenseRoute } from 'components/suspenseroute/SuspenseRoute';
import { Warning } from 'components/warning';
import { format, isAfter, parseJSON } from 'date-fns';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { cssShadowElevation } from 'theme/utils';
import { getMediaURL } from 'utils/strapi-types/media';
import { ButtonLink, Chip, ScienceWhatsNew } from 'utils/strapi-types/whats-new';

export const WhatsNewView = () => (
  <SuspenseRoute>
    <SuspenseWhatsNewView />
  </SuspenseRoute>
);

const SuspenseWhatsNewView = () => {
  return (
    <PageContainer>
      <PageTitle title="What's new?" />
      <WhatsNewPostsList />
    </PageContainer>
  );
};

const WhatsNewPostsList = () => {
  const { data: lastViewed } = useUserSetting(UserSettings.whatsNewLastViewed, { suspense: true });
  const { mutate: updateSetting } = useUpdateUserSetting();
  const { data } = useWhatsNewPosts({ suspense: true });
  const { data: user } = useSession({ suspense: true });

  const lastViewedRef = useRef(
    parseJSON(lastViewed || (user?.firstLogin && Timestamp.toDate(user.firstLogin)) || new Date()),
  );

  useEffect(() => {
    if (
      data &&
      data.length > 0 &&
      data[0].attributes.publishedAt !== lastViewed &&
      !data[0].attributes.SparxStaffOnly
    ) {
      // update the last viewed time to the most recent post
      updateSetting({
        key: UserSettings.whatsNewLastViewed,
        value: data[0].attributes.publishedAt,
      });
    }
  }, [data, lastViewed, updateSetting]);

  const [openedVideo, setOpenedVideo] = useState<ScienceWhatsNew | null>(null);

  if (data === undefined) {
    // This shouldn't happen because of suspense = true
    return <Warning status="error">Error loading, please try again later.</Warning>;
  }

  return (
    <VStack alignItems="stretch" spacing={6}>
      {data.map((post, idx) => (
        <WhatsNewPost
          post={post}
          key={idx}
          lastViewedPost={lastViewedRef.current}
          openVideo={() => setOpenedVideo(post)}
        />
      ))}
      {data.length === 0 && (
        <Text>There&apos;s nothing to see here yet. Please come back later.</Text>
      )}
      <VideoModal post={openedVideo} onClose={() => setOpenedVideo(null)} />
      <ScrollToAnchor />
    </VStack>
  );
};

const WhatsNewPost = ({
  post: { id: postID, spxID, attributes },
  lastViewedPost,
  openVideo,
}: {
  post: ScienceWhatsNew;
  lastViewedPost: Date;
  openVideo: () => void;
}) => {
  const published = parseJSON(attributes.publishedAt || new Date());
  const isNew = isAfter(published, lastViewedPost);

  const location = useLocation();
  const highlightedPost = location.hash.slice(1) === spxID;

  const hasVideo = !!attributes.Video?.Video.data.attributes.url;

  return (
    <section id={spxID}>
      <Card
        direction={{ base: 'column', lg: 'row' }}
        p={4}
        alignItems={{ base: 'flex-start' }}
        borderWidth={2}
        borderColor={highlightedPost ? 'teal.400' : 'white'}
      >
        {attributes.SparxStaffOnly && (
          <Text
            position="absolute"
            top={0}
            right={0}
            color="white"
            fontWeight="bold"
            p={2}
            textTransform="uppercase"
            bg="red.500"
          >
            Sparx preview post
          </Text>
        )}
        <Box m={{ base: 'var(--card-padding)', lg: 'inherit' }} flexShrink={0} alignSelf="center">
          <Flex
            role="group"
            width={{ base: 96, lg: 80, xl: 96 }}
            height={{ base: 60, lg: 52, xl: 60 }}
            p={3}
            justifyContent="center"
            alignItems="center"
            position="relative"
            cursor={hasVideo ? 'pointer' : undefined}
            onClick={hasVideo ? openVideo : undefined}
          >
            <Image
              src={getMediaURL(attributes.Image.data, 'small')}
              transform={`rotateZ(${postID % 2 === 0 ? '-' : ''}1.5deg)`}
              alt={attributes.Image.data.attributes.alternativeText || undefined}
              maxW="100%"
              maxH="100%"
              boxShadow="elevationMedium"
              transition={'all 0.25s'}
              _groupHover={
                hasVideo ? { boxShadow: cssShadowElevation('medium', '181deg 47% 33%') } : undefined
              }
            />
            {hasVideo && (
              <Box
                width={0}
                height={0}
                opacity={0.4}
                top="50%"
                left="50%"
                position="absolute"
                display="flex"
                justifyContent={'center'}
                alignItems={'center'}
                _groupHover={{ opacity: 0.8 }}
                transition={'opacity 0.25s'}
                color="black"
              >
                <FontAwesomeIcon icon={faCirclePlaySolid} size="3x" />
              </Box>
            )}
          </Flex>
        </Box>
        <Stack flex={1} alignSelf="stretch">
          <CardBody>
            <Box>
              <Text fontSize="sm" color="teal.600">
                {format(published, 'EEEE do MMMM yyyy')}
              </Text>
              <HStack alignItems="center" my={2}>
                <WhatsNewChip chip={attributes.Chip} />
                <Heading size="md" position="relative">
                  {attributes.Title}
                  {isNew && (
                    <Image src={notificationDot} position="absolute" top="0px" right="-9px" />
                  )}
                </Heading>
              </HStack>
              <BlocksRenderer content={attributes.Body} />
            </Box>
          </CardBody>
          {(attributes.Buttons.length > 0 || hasVideo) && (
            <CardFooter justifyContent="flex-end" pt={0}>
              <HStack>
                {hasVideo && (
                  <Button
                    leftIcon={<FontAwesomeIcon icon={faVideo} />}
                    colorScheme="buttonTeal"
                    onClick={openVideo}
                  >
                    Watch video
                  </Button>
                )}
                {attributes.Buttons.map((btn, idx) => (
                  <WhatsNewButton
                    button={btn}
                    key={idx}
                    postID={postID}
                    postTitle={attributes.Title}
                  />
                ))}
              </HStack>
            </CardFooter>
          )}
        </Stack>
      </Card>
    </section>
  );
};

const chips = {
  [Chip.New]: {
    text: 'New',
    color: 'white',
    bg: 'yellow.500',
  },
  [Chip.Update]: {
    text: 'Update',
    color: 'white',
    bg: 'orange.500',
  },
};

export const WhatsNewChip = ({ chip }: { chip: Chip }) => {
  const conf = chips[chip];

  if (!conf) {
    return null;
  }

  return (
    <Text
      fontSize="sm"
      bg={conf.bg}
      color={conf.color}
      py={0.5}
      px={2}
      borderRadius="md"
      textTransform="uppercase"
      fontWeight="bold"
    >
      {conf.text}
    </Text>
  );
};

const WhatsNewButton = ({
  button,
  postID,
  postTitle,
}: {
  button: ButtonLink;
  postID: number;
  postTitle: string;
}) => {
  const { sendEvent } = useClientEvent();

  const sendAnalytic = () => {
    sendEvent(
      { category: 'whats_new', action: 'call_to_action' },
      {
        postID: postID.toString(),
        postTitle: postTitle,
        buttonText: button.Text,
        buttonURL: button.URL,
      },
    );
  };

  return (
    <Button
      as={Link}
      to={button.URL}
      target={button.External ? '_blank' : undefined}
      rightIcon={button.External ? <FontAwesomeIcon icon={faArrowUpRightFromSquare} /> : undefined}
      colorScheme="buttonTeal"
      onClick={sendAnalytic}
    >
      {button.Text}
    </Button>
  );
};

const VideoModal = ({ post, onClose }: { post: ScienceWhatsNew | null; onClose: () => void }) => {
  const title = post?.attributes.Title;
  const video = post?.attributes.Video?.Video.data.attributes;

  const isOpen = !!video?.url;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
      size={{ base: '2xl', lg: '3xl', xl: '4xl' }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={0}>
          <WhatsNewVideoPlayer post={post} />
        </ModalBody>
        <ModalFooter justifyContent="center" display="flex">
          <Button colorScheme="buttonTeal" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export interface VideoPos {
  curPos: number;
  maxPos: number;
  duration: number;
}

export const WhatsNewVideoPlayer = ({
  post,
  analyticsCategory,
}: {
  post: ScienceWhatsNew | null;
  analyticsCategory?: string;
}) => {
  const { sendEvent } = useClientEvent();

  const video = post?.attributes.Video?.Video.data;
  const poster = post?.attributes.Video?.Poster?.data;
  const subtitles = post?.attributes.Video?.Subtitles?.data;

  const videoPos = useRef<VideoPos>({
    curPos: 0,
    maxPos: 0,
    duration: 0,
  });

  const sendVideoEvent = useCallback(
    (action: string) => {
      if (!post) return;
      sendEvent(
        { category: analyticsCategory || 'whats_new', action: `video_${action}` },
        {
          postID: post.id.toString(),
          postTitle: post.attributes.Title,
          position: videoPos.current.curPos.toString(),
          maxPosition: videoPos.current.maxPos.toString(),
          videoDuration: videoPos.current.duration.toString(),
        },
      );
    },
    [sendEvent, post, analyticsCategory],
  );

  useEffect(() => {
    // Reset the video position back to 0
    videoPos.current = { curPos: 0, maxPos: 0, duration: 0 };
    if (post) {
      sendVideoEvent('open');
      return () => {
        sendVideoEvent('close');
      };
    }
  }, [post, sendVideoEvent]);

  return (
    <video
      src={video && getMediaURL(video)}
      controls={true}
      poster={poster ? getMediaURL(poster) : undefined}
      style={{
        width: '100%',
        maxHeight: '70vh',
        background: 'gray',
      }}
      onTimeUpdate={e => {
        videoPos.current.curPos = e.currentTarget.currentTime;
        videoPos.current.maxPos = Math.max(videoPos.current.maxPos, e.currentTarget.currentTime);
        videoPos.current.duration = e.currentTarget.duration;
      }}
      onPlay={() => sendVideoEvent('play')}
      onPause={() => sendVideoEvent('pause')}
      onEnded={() => sendVideoEvent('ended')}
    >
      {subtitles && (
        <track
          label="English"
          kind="captions"
          srcLang="en"
          src={getMediaURL(subtitles)}
          default={true}
        />
      )}
    </video>
  );
};
