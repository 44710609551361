import {
  SubmitAgreementInPrincipleRequest,
  SubmitAgreementInPrincipleResponse,
} from '@sparx/api/apis/sparx/school/subscription/v1/subscriptionactions';
import {
  QueryClient,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { useContext } from 'react';

import { CommonParamsContext } from '../../context';
import { getSchool } from '../../schools-service';
import { useSubscriptionActionsClient } from '../client';
import { getSubscriptionDetails } from '../get-subscription-details';

type Arg = Omit<SubmitAgreementInPrincipleRequest, 'schoolName' | 'product'>;

type MutationOptions = UseMutationOptions<SubmitAgreementInPrincipleResponse, Error, Arg>;

// TODO: Can we think of any reason why a consumer would *not* want this called on AIP?
const invalidate = async (queryClient: QueryClient) =>
  Promise.all([
    queryClient.invalidateQueries(getSchool.keyPrefix),
    queryClient.invalidateQueries(getSubscriptionDetails.keyPrefix),
  ]);

export const useSubmitAIP = ({ onSuccess, ...options }: MutationOptions = {}) => {
  const queryClient = useQueryClient();
  const subscriptionsClient = useSubscriptionActionsClient();
  const { schoolName, product } = useContext(CommonParamsContext);
  return useMutation<SubmitAgreementInPrincipleResponse, Error, Arg>(
    request =>
      subscriptionsClient.submitAgreementInPrinciple({ ...request, schoolName, product }).response,
    {
      onSuccess: async (...args) => {
        await invalidate(queryClient);
        if (onSuccess) {
          return onSuccess(...args);
        }
      },
      ...options,
    },
  );
};
