import { isRpcError, isSystemAccessError } from '@sparx/api/utils/rpc-error';
import { Button } from '@sparx/design/components/button/Button';
import { isRouteErrorResponse } from 'react-router-dom';

import Card from './Card';
import styles from './Errors.module.css';
import NotFound from './NotFound';
import UnknownError from './UnknownError';

export default function ErrorPage({
  error,
  reset,
  systemSelectUrl,
}: {
  error: Error;
  systemSelectUrl?: string;
  reset?: () => void;
}) {
  if (
    (isRpcError(error) && error?.code === 'PERMISSION_DENIED') ||
    error.message?.includes('Failed to fetch dynamically imported module') ||
    error.message?.includes('Importing a module script failed.')
  ) {
    // For permission denied errors make reset refresh the page, as this should
    // force us to get a new token too
    // Also force refresh for module import errors as that should fetch the latest index file
    reset = () => location.reload();
  }

  let content = <UnknownError reset={reset} />;

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      content = <NotFound />;
    }
  }

  if (isSystemAccessError(error)) {
    content = (
      <Card>
        <p className={styles.Title}>You don&apos;t have access to this system</p>
        <p className={styles.Content}>
          If you require access please contact your school&apos;s IT team, or select another Sparx
          Learning system
        </p>
        {systemSelectUrl && (
          <Button className={styles.Button} as="a" href={systemSelectUrl} variant="primary">
            Go to system select
          </Button>
        )}
      </Card>
    );
  }

  return <div className={styles.Container}>{content}</div>;
}
