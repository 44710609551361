import { RpcError } from '@protobuf-ts/runtime-rpc';
import { Status } from '../google/rpc/status';
import { Code } from '../google/rpc/code';
import {
  BadRequest,
  BadRequest_FieldViolation,
  ErrorInfo,
  PreconditionFailure,
  PreconditionFailure_Violation,
} from '../google/rpc/error_details';
import { base64decode } from '@protobuf-ts/runtime';
import { Any } from '../google/protobuf/any';

export const getFirstStatusFromRpcError = (error: Error): Status | null => {
  if (!(error instanceof RpcError)) {
    return null;
  }
  const detailsBin = error.meta['grpc-status-details-bin'];
  if (!detailsBin) {
    return null;
  }
  const firstDetailsBin = Array.isArray(detailsBin)
    ? detailsBin[0]
    : detailsBin;

  return Status.fromBinary(base64decode(firstDetailsBin));
};

export const extractViolationsFromFailedPreconditionStatus = (
  status: Status,
): PreconditionFailure_Violation[][] => {
  if (status.code !== Code.FAILED_PRECONDITION) {
    return [];
  }

  return status.details
    .filter(
      (d) => d.typeUrl === 'type.googleapis.com/google.rpc.PreconditionFailure',
    )
    .map((d) => Any.unpack(d, PreconditionFailure).violations);
};

export const extractFieldViolationsFromInvalidArgumentStatus = (
  status: Status,
): BadRequest_FieldViolation[][] => {
  if (status.code !== Code.INVALID_ARGUMENT) {
    return [];
  }

  return status.details
    .filter((d) => d.typeUrl === 'type.googleapis.com/google.rpc.BadRequest')
    .map((d) => Any.unpack(d, BadRequest).fieldViolations);
};

export const extractErrorInfoFromStatus = (status: Status): ErrorInfo[] =>
  status.details
    .filter((d) => d.typeUrl === 'type.googleapis.com/google.rpc.ErrorInfo')
    .map((d) => Any.unpack(d, ErrorInfo));

export const isRpcError = (error: Error): error is RpcError =>
  error instanceof RpcError;

export const isUnauthenticatedError = (error: Error | null): boolean =>
  error !== null && isRpcError(error) && error.code === 'UNAUTHENTICATED';

/**
 * Returns whether or not the provided error is a system access error. This is done by checking
 * for a specific reason in the attached grpc status details.
 */
export const isSystemAccessError = (error: Error): boolean => {
  const status = getFirstStatusFromRpcError(error);
  if (!status) {
    return false;
  }
  const info = extractErrorInfoFromStatus(status);
  return info[0]?.reason === 'SYSTEM_ACCESS';
};
