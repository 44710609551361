import {
  Box,
  Center,
  chakra,
  Flex,
  Image,
  LinkBox,
  LinkOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { faCheck, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Task } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { isComplete } from '@sparx/packageactivity';
import { CompletionBadge } from 'components/CompletionBadge';
import FlashcardsIcon from 'components/flashcards/flashcards_white.svg';
import React from 'react';
import { Link } from 'react-router-dom';
import { isTaskFlashcards } from 'utils/flashcards';

interface TaskRowProps {
  task: Task;
}

export const TaskRow = ({ task }: TaskRowProps) => {
  const complete = isComplete(task.state?.completion);
  const isFlashcards = isTaskFlashcards(task);
  return (
    <Flex
      alignItems="center"
      as={LinkBox}
      _hover={{
        boxShadow: 'elevationMedium',
      }}
      transition="box-shadow 0.2s"
      boxShadow="elevationLow"
      borderRadius="lg"
      bg="white"
      overflow="hidden"
      pr={[3, 5]}
    >
      <Box py={5} pl={[4, 5]}>
        <Text fontWeight="bold" color="blue.800" fontSize={{ base: 'md', md: 'lg' }}>
          <LinkOverlay as={Link} to={`/${task.name}`} display="flex" alignItems={'center'}>
            <chakra.span position="relative" top={1}>
              {task.title}
            </chakra.span>
            {isFlashcards && <Image src={FlashcardsIcon} alt="" height={8} ml={3} />}
          </LinkOverlay>
        </Text>
      </Box>
      <Spacer />
      <CompletionBadge completion={task.state?.completion} />
      <Center
        ml={4}
        bg={complete ? 'green.100' : 'gray.100'}
        borderRadius="full"
        w={10}
        h={10}
        fontSize="lg"
        color={complete ? 'green.800' : 'gray.800'}
      >
        <FontAwesomeIcon icon={complete ? faCheck : faChevronRight} />
      </Center>
    </Flex>
  );
};
