import {
  Deal,
  IndividualSubscriptionStatus,
  SubscriptionDetails,
  SubscriptionSummary,
} from '@sparx/api/apis/sparx/school/subscription/v1/subscriptionactions';
import { useIntercom } from '@sparx/intercom';
import { useCommonParams } from '@sparx/query';
import { getSchoolStaffMember } from '@sparx/query/school-staff-service';
import { getSubscriptionDetails } from '@sparx/query/subscription-actions-service';
import { Alert } from '@sparx/sparx-design/components/alert/Alert';
import { Button } from '@sparx/sparx-design/components/button/Button';
import classnames from 'classnames';
import { addDays } from 'date-fns';
import { Dispatch, useEffect, useReducer, useRef, useState } from 'react';

import { useSubscriptionContext } from '../Context';
import { useGetFinanceContact } from '../queries';
import subscriptionReducer, { Action, canSubmitAIP, initialiseState, State } from '../reducer';
import { CivilDate, fromCivilDate, toCivilDate } from '../types/civilDate';
import { formatDate } from '../utils/dates';
import { urlTermsAndConditions } from '../utils/knowledgeBaseArticles';
import { getProductName } from '../utils/products';
import { colourProp } from './colour-prop';
import { ExternalLink } from './ExternalLink';
import { FinanceModal } from './FinanceModal';
import { ReceivedTime } from './ReceivedTime';
import styles from './Subscription.module.css';
import { SuccessStatus } from './SuccessStatus';

interface StateProps {
  state: State;
  dispatch: Dispatch<Action>;
}

interface UIOptions {
  showWholeSchoolMessaging: boolean;
  notInterestedComponent?: JSX.Element;
}

/**
 * The top of the component tree for subscription settings, which loads the subscription details.
 * General structure of the component tree as below:
 * * Subscription
 *   * SubscriptionPage
 *     * SubscriptionDetails[PendingTrial|Trial|Subscribed|Other|Unsupported]
 *       * (for Trial and Subscribed) SelectDeal, RenewalDates, SubscribeSection or SubscriptionPipelineStatus
 */
export const Subscription = ({ notInterestedComponent, showWholeSchoolMessaging }: UIOptions) => {
  const { data: subscription, isLoading } = getSubscriptionDetails.useQuery();

  if (isLoading || subscription === undefined) {
    return null;
  }

  return (
    <div className={styles.Subscription}>
      <div className={styles.SubscriptionInner}>
        <SubscriptionPage
          subscription={subscription}
          notInterestedComponent={notInterestedComponent}
          showWholeSchoolMessaging={showWholeSchoolMessaging}
        />
      </div>
    </div>
  );
};

interface SubscriptionPageProps extends UIOptions {
  subscription: SubscriptionDetails;
}

const SubscriptionPage = ({
  subscription,
  notInterestedComponent,
  showWholeSchoolMessaging,
}: SubscriptionPageProps) => {
  const [state, dispatch] = useReducer(subscriptionReducer, initialiseState(subscription));

  useEffect(() => {
    dispatch({ type: 'RESET', value: subscription });
  }, [subscription]);

  switch (state.mode) {
    case 'PendingTrial':
      return <SubscriptionDetailsPendingTrial state={state} />;
    case 'Trial':
      return (
        <SubscriptionDetailsTrial
          state={state}
          dispatch={dispatch}
          notInterestedComponent={notInterestedComponent}
          showWholeSchoolMessaging={showWholeSchoolMessaging}
        />
      );
    case 'Subscribed':
      return (
        <SubscriptionDetailsSubscribed
          state={state}
          dispatch={dispatch}
          notInterestedComponent={notInterestedComponent}
          showWholeSchoolMessaging={showWholeSchoolMessaging}
        />
      );
    case 'Group':
    case 'Other':
      return <SubscriptionDetailsOther />;
    case 'Unsupported':
    default:
      return <SubscriptionDetailsUnsupported />;
  }
};

const ContactUsLink = () => {
  const { showNewMessage } = useIntercom();

  return (
    <Button variant="text" onClick={() => showNewMessage()} className={styles.Link}>
      contact us
    </Button>
  );
};

const SubscriptionDetailsPendingTrial = ({ state }: { state: State }) => {
  const { product } = useCommonParams();
  const startDate = formatDate(state.pendingSubscription.startDate);
  return (
    <>
      <p>
        Your {getProductName(product)} trial hasn&apos;t started yet. Once your trial has started on{' '}
        {startDate}, revisit this page to view the subscription options available to your school.
      </p>
      <p>
        If you don&apos;t think this is correct, <ContactUsLink />.
      </p>
    </>
  );
};

const SubscriptionDetailsOther = () => {
  const { product } = useCommonParams();
  return (
    <>
      <p>
        Your school has a custom subscription that cannot be managed via your{' '}
        {getProductName(product)} site.
      </p>
      <p>
        If you have a query about your subscription, or if you don't think this is correct,{' '}
        <ContactUsLink /> via our Support Centre.
      </p>
    </>
  );
};

const SubscriptionDetailsUnsupported = () => (
  <p>
    Your school does not use individual subscriptions. If you think this is a mistake, please{' '}
    <ContactUsLink />.
  </p>
);

const SubscriptionDetailsTrial = ({
  state,
  dispatch,
  notInterestedComponent,
  showWholeSchoolMessaging,
}: StateProps & UIOptions) => {
  const canSubmit = canSubmitAIP(state.pendingSubscription.status);

  return (
    <>
      <SelectDeal state={state} dispatch={dispatch} interactive={canSubmit} />

      <RenewalDates
        currentSubEnd={state.currentSubscription?.endDate}
        subscription={state.pendingSubscription}
        trial
      />

      <hr />

      {canSubmit ? (
        <SubscribeSection
          startDate={state.pendingSubscription.startDate}
          deal={state.selectedDeal}
          notInterestedComponent={notInterestedComponent}
          showWholeSchoolMessaging={showWholeSchoolMessaging}
        />
      ) : (
        <SubscriptionPipelineStatus subscription={state.pendingSubscription} />
      )}
    </>
  );
};

const SubscriptionDetailsSubscribed = ({
  state,
  dispatch,
  notInterestedComponent,
  showWholeSchoolMessaging,
}: StateProps & UIOptions) => {
  const canSubmit = canSubmitAIP(state.pendingSubscription.status);

  return (
    <>
      <SelectDeal state={state} dispatch={dispatch} interactive={canSubmit} />

      <RenewalDates
        currentSubEnd={state.currentSubscription?.endDate}
        subscription={state.pendingSubscription}
      />

      <hr />

      {canSubmit ? (
        <SubscribeSection
          startDate={state.pendingSubscription.startDate}
          deal={state.selectedDeal}
          notInterestedComponent={notInterestedComponent}
          showWholeSchoolMessaging={showWholeSchoolMessaging}
        />
      ) : (
        <SubscriptionPipelineStatus subscription={state.pendingSubscription} />
      )}
    </>
  );
};

interface SubscriptionPipelineProps {
  subscription: Pick<
    Partial<SubscriptionSummary>,
    'aipUserId' | 'status' | 'statusLastTransitionTime'
  >;
}

const SubscriptionPipelineStatus = ({ subscription }: SubscriptionPipelineProps) => {
  switch (subscription.status) {
    case IndividualSubscriptionStatus.AGREED_IN_PRINCIPLE: {
      return <SubscriptionPipelineStatusAIP subscription={subscription} />;
    }
    case IndividualSubscriptionStatus.PURCHASE_ORDER_RECEIVED: {
      const at = subscription.statusLastTransitionTime;
      const submittedAt = at ? (
        <>
          {' '}
          on <ReceivedTime timestamp={at} />
        </>
      ) : null;

      return <SuccessStatus>We received your purchase order{submittedAt}.</SuccessStatus>;
    }
    case IndividualSubscriptionStatus.PAYMENT_RECEIVED: {
      return <SuccessStatus>Your subscription has been paid.</SuccessStatus>;
    }
  }
  return null;
};

const SubscriptionPipelineStatusAIP = ({ subscription }: SubscriptionPipelineProps) => {
  const { data: by, status } = getSchoolStaffMember.useQuery(
    { name: subscription.aipUserId ? `staff/${subscription.aipUserId}` : '' },
    {
      enabled: !!subscription.aipUserId && !subscription.aipUserId.startsWith('sparxstaff/'),
      // If user no longer exists or is a TP sparxstaff, this will fail repeatedly
      retry: 3,
    },
  );
  const submittedBy =
    by && status === 'success' ? (
      <>
        {' '}
        by{' '}
        <strong>
          {by.givenName} {by.familyName}
        </strong>
      </>
    ) : null;

  const at = subscription.statusLastTransitionTime;
  const submittedAt = at ? (
    <>
      {' '}
      at <ReceivedTime timestamp={at} includeTime />
    </>
  ) : null;

  return (
    <SuccessStatus>
      Your agreement was submitted{submittedBy}
      {submittedAt}. We've sent a purchase order request to your finance contact, but have not yet
      received a purchase order.
    </SuccessStatus>
  );
};

const SelectDeal = ({ state, dispatch, interactive }: StateProps & { interactive?: boolean }) => {
  // If we've already submitted an AIP (i.e. `!interactive`), infer the selected
  // deal from the pending subscription.
  const deals: State['pendingSubscription']['deals'] = interactive
    ? state.pendingSubscription.deals
    : state.pendingSubscription.price && state.pendingSubscription.durationMonths
      ? [
          {
            priceGbp: (state.pendingSubscription.price ?? '').replace('£', ''),
            durationMonths: state.pendingSubscription.durationMonths,
            selected: true,
            optionName: state.pendingSubscription.selectedOption ?? '',
          },
        ]
      : [];

  const { product } = useCommonParams();

  return (
    <div className={styles.SelectDeal}>
      <h4 className={styles.Title}>
        {interactive
          ? `Select your ${getProductName(product)} subscription term`
          : 'Your selected subscription term'}
      </h4>

      <div className={styles.DealOptions}>
        {deals.map((deal, i) => (
          <div
            key={i}
            className={classnames(styles.DealOption, {
              [styles.DealOptionSelected]: deal.selected,
              [styles.ReadOnlyDealOption]: !interactive,
            })}
            onClick={() => {
              if (interactive) {
                dispatch({ type: 'SELECT_DEAL', value: i });
              }
            }}
          >
            <div className={styles.DealOptionRow}>
              <input type="radio" readOnly checked={!!deal.selected} />
              <div>
                <div>
                  <b>{deal.durationMonths} months</b>
                </div>
                <div>
                  <b>£{deal.priceGbp}</b> +VAT *
                </div>
              </div>
            </div>
            {i === 0 && interactive && <div className={styles.MostPopular}>Most popular</div>}
          </div>
        ))}
      </div>
    </div>
  );
};

const RenewalDates = ({
  currentSubEnd,
  subscription,
  trial,
}: {
  currentSubEnd?: CivilDate;
  subscription: Pick<SubscriptionSummary, 'startDate' | 'endDate'>;
  /** Whether the current subscription is a trial */
  trial?: boolean;
}) => {
  const { getInfoTooltip } = useSubscriptionContext();

  return (
    <div className={styles.RenewalDates}>
      <div>
        <div className={styles.RenewalDateHeading}>
          {trial ? 'Trial' : 'Current subscription'} end date
        </div>
        <div>{formatDate(currentSubEnd)}</div>
      </div>
      <div>
        <div className={styles.RenewalDateHeading}>
          {trial ? 'S' : 'New s'}ubscription start date
          {trial &&
            getInfoTooltip('Your subscription start date was agreed at the start of your trial')}
        </div>
        <div>{formatDate(subscription.startDate)}</div>
      </div>
      <div>
        <div className={styles.RenewalDateHeading}>{trial ? 'S' : 'New s'}ubscription end date</div>
        <div>{formatDate(subscription.endDate)}</div>
      </div>
    </div>
  );
};

interface SubscribeSectionProps extends UIOptions {
  startDate?: CivilDate;
  deal?: Deal;
}

const SubscribeSection = ({
  startDate,
  deal,
  notInterestedComponent,
  showWholeSchoolMessaging,
}: SubscribeSectionProps) => {
  const { product } = useCommonParams();
  const { externalLinkComponent: Link = ExternalLink } = useSubscriptionContext();
  const [isDisclosureOpen, setDisclosureOpen] = useState(false);
  const { data: financeContact, isLoading } = useGetFinanceContact();

  // To show and hide the shadow on the sticky agree to subscribe button, we use an IntersectionObserver.
  // There is a hidden div just below the sticky button in the DOM that we use as a trigger.
  // When it is visible on the screen, we know that the user has scrolled down and the shadow can be hidden.
  // When the element is hidden then we know that the button is "stuck" and needs the shadow applying.
  const interactionObserverTrigger = useRef<HTMLDivElement>(null);
  const [showShadow, setShowShadow] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      // NOTE: We only observe a single element, so there will only ever be one entry.
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setShowShadow(false);
          } else {
            setShowShadow(true);
          }
        });
      },
      {
        root: document.querySelector('body'),
      },
    );
    if (interactionObserverTrigger.current) {
      observer.observe(interactionObserverTrigger.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  if (!startDate) {
    return null;
  }

  const due = toCivilDate(addDays(fromCivilDate(startDate), 28));

  return (
    <div className={styles.Footer}>
      <h4>Subscribe</h4>

      <Alert status="info">
        <Alert.Icon />
        <Alert.Description>
          Payment will not be due until {formatDate(due)} (28 days after the start of your
          subscription).
        </Alert.Description>
      </Alert>

      <p>
        By subscribing you agree to our{' '}
        <Link href={urlTermsAndConditions}>Terms and Conditions</Link>. Your finance contact will be
        sent all the required information to organise payment.{' '}
        {showWholeSchoolMessaging && (
          <>
            {getProductName(product)} subscriptions are for the whole school, so you can add{' '}
            <b>unlimited students at no extra cost</b>.
          </>
        )}
      </p>

      <sub>* if applicable.</sub>

      <div className={classnames(styles.SubscriptionControls, showShadow && styles.Shadow)}>
        <Button
          variant="contained"
          colour={colourProp(product)}
          onClick={() => setDisclosureOpen(true)}
          isDisabled={!deal || isLoading}
        >
          Agree to subscribe to {getProductName(product)}
        </Button>
        {notInterestedComponent}
      </div>

      <div ref={interactionObserverTrigger} className={styles.InteractionObserverTrigger} />
      {/*
        Allow financeContact to be undefined (it might legitimately not be recorded yet) but
        don't load the modal until isLoading is false, as we need a correct initial value for the form
      */}
      {deal !== undefined && !isLoading && (
        <FinanceModal
          isOpen={isDisclosureOpen}
          onClose={() => setDisclosureOpen(false)}
          deal={deal}
          startDate={startDate}
          existingContact={financeContact}
        />
      )}
    </div>
  );
};
