import {
  Box,
  Button,
  HStack,
  Icon,
  Link,
  StyleProps,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  ThemingProps,
  Tr,
} from '@chakra-ui/react';
import { faExclamationTriangle, faExternalLink, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SchoolStaffMember } from '@sparx/api/apis/sparx/school/staff/schoolstaff/v2/schoolstaff';
import { StaffRole, StaffRoleAssignment } from '@sparx/api/apis/sparx/school/staff/v2/staff';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { FieldMask } from '@sparx/api/google/protobuf/field_mask';
import { useCommonParams } from '@sparx/query';
import { listSchoolStaffMembers, mutations } from '@sparx/query/school-staff-service';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { KeyContactsWarning } from '../components/KeyContactsWarning';
import { StaffDetailsField } from '../components/StaffDetailsField';
import { TabBar } from '../components/TabBar';
import { useStaffContext } from '../Context';
import { selectKeyContacts } from '../queries';
import {
  ContactEditModel,
  dirtyFieldsToMask,
  getProductName,
  getRoleName,
  hasRole,
  makeGetUserRoleName,
  productKeyContactRoles,
  sharedKeyContactRoles,
  StaffKeyContacts,
  userHasRoleInSystem,
} from '../utils';

interface KeyContactListViewProps {
  children: (args: { table: React.ReactNode }) => React.ReactElement;
}

export const KeyContactListView = ({ children }: KeyContactListViewProps) => {
  const { product } = useCommonParams();
  const { knowledgeBaseArticleURL } = useStaffContext();
  const { data } = listSchoolStaffMembers.useSuspenseQuery({
    select: selectKeyContacts(product),
  });
  const showWarning = data?.status.status !== 'ok';

  return children({
    table: data ? (
      <>
        <TabBar index={1} warning={showWarning} />
        <ExplainerText articleURL={knowledgeBaseArticleURL} product={product} />
        {showWarning && <KeyContactsWarning status={data.status} product={product} />}
        <KeyContactsTable contacts={data.keyContacts} />
      </>
    ) : null,
  });
};

const ExplainerText = ({ articleURL, product }: { articleURL: string; product: Product }) => {
  const { styles } = useStaffContext();
  return (
    <Box mb={4}>
      <Text as="span" lineHeight="unset">
        <Text>
          This area is for your key contacts that we may need to send specific information to.
        </Text>
        <Text>
          To give key contacts access to {getProductName(product)}, also add them under the Staff
          tab.
        </Text>
        <Text>
          Key contacts do not have access to any additional features. For more information on key
          contacts and why they&apos;re important, see our Support Centre article:{' '}
          <Link
            fontWeight="bold"
            textDecoration="underline"
            color={styles.linkColor}
            href={articleURL}
            target="_blank"
          >
            Which key contacts do you ask for and why?
            <Box as="span" pl={1.5}>
              <FontAwesomeIcon icon={faExternalLink} size="sm" />
            </Box>
          </Link>
        </Text>
      </Text>
    </Box>
  );
};

type KeyContactsTableProps = {
  contacts: StaffKeyContacts;
  productKeyContactsOnly?: boolean;
  readOnly?: boolean;
};

type SaveRow = (
  data: SchoolStaffMember,
  update: FieldMask | 'DELETE',
) => Promise<SchoolStaffMember>;

export const KeyContactsTable = ({
  contacts,
  productKeyContactsOnly,
  readOnly,
}: KeyContactsTableProps) => {
  const { product } = useCommonParams();
  const { styles } = useStaffContext();
  const { mutateAsync: doSave } = mutations.useUpsertStaff();
  const { mutateAsync: doDelete } = mutations.useSafeDeleteStaff();

  const [editRow, setEditRow] = useState<StaffRole | null>(null);
  const onSave: SaveRow = async (data, mask) => {
    if (readOnly) return data;
    let result: SchoolStaffMember;
    if (mask === 'DELETE') {
      result = await doDelete(data);
    } else {
      result = await doSave({ staff: data, updateMask: mask });
    }
    setEditRow(null);
    return result;
  };

  const headerStyles: StyleProps = {
    backgroundColor: styles.headerBackgroundColor,
    textTransform: 'none',
    fontSize: 'sm',
    color: 'white',
    zIndex: 10,
    top: 0,
    px: 6,
    py: 4,
  };

  return (
    <TableContainer whiteSpace="unset" boxShadow="elevationLow" borderRadius="md">
      <Table backgroundColor="white" overflow="hidden">
        <Thead>
          <Tr>
            <Th {...headerStyles} width="20%" borderTopLeftRadius="md">
              Role
            </Th>
            <Th {...headerStyles} width="17.5%">
              First name
            </Th>
            <Th {...headerStyles} width="17.5%">
              Last name
            </Th>
            <Th {...headerStyles}>Email address</Th>
            {!readOnly && <Th {...headerStyles} width="15%" borderTopRightRadius="md"></Th>}
          </Tr>
        </Thead>
        <Tbody>
          {productKeyContactRoles.map(r =>
            editRow === r ? (
              <KeyContactsEditRow
                key={r}
                role={{ role: r, product: product }}
                contact={contacts[r]}
                onSave={onSave}
                onCancel={() => setEditRow(null)}
                roleDescription={getRoleName(r, product)}
              />
            ) : (
              <KeyContactsTableRow
                key={r}
                role={{ role: r, product: product }}
                contact={contacts[r]}
                disableEdit={editRow !== null}
                onEdit={() => setEditRow(r)}
                readOnly={readOnly}
              />
            ),
          )}
          {!productKeyContactsOnly &&
            sharedKeyContactRoles.map(r =>
              editRow === r ? (
                <KeyContactsEditRow
                  key={r}
                  role={{ role: r, product: Product.PRODUCT_UNKNOWN }}
                  contact={contacts[r]}
                  onSave={onSave}
                  onCancel={() => setEditRow(null)}
                  roleDescription={getRoleName(r, Product.PRODUCT_UNKNOWN)}
                />
              ) : (
                <KeyContactsTableRow
                  key={r}
                  role={{ role: r, product: Product.PRODUCT_UNKNOWN }}
                  contact={contacts[r]}
                  disableEdit={editRow !== null}
                  onEdit={() => setEditRow(r)}
                  readOnly={readOnly}
                />
              ),
            )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const RoleNameCell = ({ role }: { role: StaffRole }) => {
  const { product } = useCommonParams();
  const getRoleName = makeGetUserRoleName(product);
  return (
    <Td>
      <Text color="blue.900" fontWeight="bold">
        {getRoleName(role)}
      </Text>
    </Td>
  );
};

const rowButtonStyle: ThemingProps<'Button'> = {
  size: 'sm',
  variant: 'outline',
  colorScheme: 'buttonTeal',
};

const KeyContactsTableRow = ({
  role,
  contact,
  disableEdit,
  onEdit,
  readOnly,
}: {
  role: StaffRoleAssignment;
  contact: SchoolStaffMember | undefined;
  disableEdit: boolean;
  onEdit: () => void;
  readOnly?: boolean;
}) => (
  <Tr>
    <Td>
      <Text color="blue.900" fontWeight="bold">
        {getRoleName(role.role, role.product)}
      </Text>
    </Td>
    {contact === undefined ? (
      <Td colSpan={3}>
        <Box display="flex" flexDirection="row" color="orange">
          <Icon as={FontAwesomeIcon} icon={faExclamationTriangle} />
          <Text pl={1}>Not assigned</Text>
        </Box>
      </Td>
    ) : (
      <>
        <Td>
          <Text>{contact.givenName}</Text>
        </Td>
        <Td>
          <Text>{contact.familyName}</Text>
        </Td>
        <Td width="0.1%">
          <Text
            width={200}
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            title={contact.emailAddress}
          >
            {contact.emailAddress}
          </Text>
        </Td>
      </>
    )}
    {!readOnly && (
      <Td textAlign="end">
        <Button
          {...rowButtonStyle}
          leftIcon={<Icon as={FontAwesomeIcon} icon={faPencil} />}
          isDisabled={disableEdit}
          onClick={onEdit}
        >
          Edit
        </Button>
      </Td>
    )}
  </Tr>
);

const KeyContactsEditRow = ({
  role,
  contact,
  onSave: innerOnSave,
  onCancel,
  roleDescription,
}: {
  role: StaffRoleAssignment;
  contact: SchoolStaffMember | undefined;
  onSave: SaveRow;
  onCancel: () => void;
  roleDescription: string;
}) => {
  const { product } = useCommonParams();
  const { data } = listSchoolStaffMembers.useQuery({
    select: selectKeyContacts(product),
    refetchOnMount: false,
  });
  const methods = useForm<ContactEditModel>({
    defaultValues: {
      blockSave: false,
      staffMember:
        contact ||
        SchoolStaffMember.create({
          productAccess:
            role.role === StaffRole.NETWORK_MANAGER || role.product !== Product.PRODUCT_UNKNOWN,
          roles: [role],
        }),
    },
  });
  const blockSave = methods.watch('blockSave');
  const name = methods.watch('staffMember.name');
  const mountRef = useRef(false);
  useEffect(() => {
    if (!mountRef.current) {
      mountRef.current = true;
      return;
    }
    methods.setValue(
      'staffMember.roles',
      (roles => (hasRole(roles, role.role, role.product) ? roles : roles.concat(role)))(
        methods.getValues('staffMember.roles') || [],
      ),
      {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      },
    );
    methods.trigger();
  }, [methods, name, role]);

  const { dirtyFields, isValid, isSubmitting, isDirty } = methods.formState;

  const onSave = methods.handleSubmit(async ({ staffMember, willRemove }) => {
    if (willRemove) {
      // User is CLEARING the current contact without replacing them;
      // perform an update on the current contact which REMOVES the role from them

      // Remove the role from the user record
      const update = SchoolStaffMember.create({
        name: contact?.name,
        roles: contact?.roles.filter(sr => !(sr.role === role.role && sr.product === role.product)),
      });

      // If we're removing this staff member's last `product` role grant them the Other role
      // So they aren't unexpectedly deleted
      if (role.product === product && !update.roles.find(({ product: p }) => p === product)) {
        update.roles.push({ role: StaffRole.OTHER, product: product });
      }

      return innerOnSave(
        update,
        update.roles.length > 0 ? FieldMask.create({ paths: ['roles'] }) : 'DELETE',
      );
    }

    // User is REPLACING the current contact with a new contact;
    // perform an update on the new contact which ADDS the role to them
    // (the server will remove the role from the old contact)

    const mask = dirtyFieldsToMask(dirtyFields.staffMember || {});
    const willHaveProductAccess =
      staffMember.roles.some(r => r.role === StaffRole.NETWORK_MANAGER) ||
      userHasRoleInSystem(product, staffMember.roles);

    if (!staffMember.productAccess && willHaveProductAccess) {
      staffMember.productAccess = true;
      mask.push('product_access');
    }

    return innerOnSave(staffMember, FieldMask.create({ paths: mask }));
  });

  const allStaff = data?.allStaff || [];
  const removeText = `Remove ${roleDescription}`;

  return (
    <FormProvider {...methods}>
      <Tr>
        <RoleNameCell role={role.role} />
        <Td>
          <StaffDetailsField
            field="givenName"
            allStaff={allStaff}
            mode="free-entry"
            keyContactRole={role}
            removeText={removeText}
          />
        </Td>
        <Td>
          <StaffDetailsField
            field="familyName"
            allStaff={allStaff}
            mode="free-entry"
            keyContactRole={role}
            removeText={removeText}
          />
        </Td>
        <Td>
          <StaffDetailsField
            field="emailAddress"
            allStaff={allStaff}
            mode="selection"
            keyContactRole={role}
            removeText={removeText}
          />
        </Td>
        <Td>
          <HStack justify="end">
            <Button
              {...rowButtonStyle}
              onClick={onSave}
              isDisabled={blockSave || isSubmitting || !isValid || !isDirty}
              isLoading={isSubmitting}
            >
              Save
            </Button>
            <Button {...rowButtonStyle} onClick={onCancel}>
              Cancel
            </Button>
          </HStack>
        </Td>
      </Tr>
    </FormProvider>
  );
};
