import { Box } from '@chakra-ui/react';
import { Subscription } from '@sparx/subscription-manager';

import { SubscriptionContext } from './context';

export const SubscriptionView = () => {
  return (
    <SubscriptionContext>
      <Box>
        <Subscription showWholeSchoolMessaging={false} />
      </Box>
    </SubscriptionContext>
  );
};
