import { StyleProps, SystemStyleObject } from '@chakra-ui/react';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { School } from '@sparx/api/apis/sparx/school/v2/schools';
import { Product } from '@sparx/api/apis/sparx/types/product';
import React, { createContext, useContext } from 'react';

export interface SortOptions {
  upIcon: IconDefinition;
  downIcon: IconDefinition;
  hoverStyle: SystemStyleObject;
}

/** TODO: A more sustainable way of customising this module to each product is required */
interface StaffManagerStyles {
  linkColor: StyleProps['color'];
  editIconColor: StyleProps['color'];
  headerBackgroundColor: StyleProps['backgroundColor'];
  tableSortOptions: SortOptions;
  checkboxColor?: StyleProps['color'];
}

/**
 * On completion of an operation, navigate and then optionally display a
 * success toast with a given message
 */
interface NavigationAction {
  (toastMessage?: string): void;
}

export interface NavigationState {
  toastMessage?: string;
}

interface Context {
  school: School | undefined;
  currentUserID: string | undefined;
  knowledgeBaseArticleURL: string;

  /**
   * The default value for the product dropdown.
   * The value -1 denotes all products. If omitted, defaults to the current product
   */
  defaultFilter?: Product | -1;

  // include training progress in the table?
  // We could remove this once both science and reader have training?
  showTrainingProgress?: boolean;

  onSelectStaff: (staffName: string, args?: { replace?: boolean }) => void;
  onStaffDeleted: NavigationAction;
  onStaffCreated: NavigationAction;
  getInfoTooltip: (text: React.ReactNode) => React.ReactNode;
  onSwitchTab: (tabIndex: number) => void;
  styles: StaffManagerStyles;
  useNavigationMessage: () => NavigationState['toastMessage'];
  keyContactsDisabled?: boolean;
}

const context = createContext<Context | null>(null);

export const useStaffContext = () => {
  const ctx = useContext(context);
  if (ctx === null) {
    throw new Error('useStaffContext must be used within a StaffContextProvider');
  }
  return ctx;
};

export const StaffContextProvider = context.Provider;

export type StaffContext = Context;
