import {
  Alert,
  AlertIcon,
  Box,
  Button,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { useCommonParams } from '@sparx/query';
import { mutations } from '@sparx/query/school-staff-service';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { HeaderField } from '../components/FormFields';
import { useStaffContext } from '../Context';
import { ContactEditModel, filterUserRolesForProduct, getProductName } from '../utils';

export const RemoveStaffMemberSection = () => {
  const { product } = useCommonParams();
  const { onStaffDeleted, currentUserID } = useStaffContext();
  const { watch, getValues } = useFormContext<ContactEditModel>();
  const [confirmation, setConfirmation] = useState(false);
  const toast = useToast();
  const staffName = getValues('staffMember.name');
  const multipleSchools = getValues('staffMember.multipleSchools');
  const [data, givenName, familyName, roles] = watch([
    'staffMember',
    'staffMember.givenName',
    'staffMember.familyName',
    'staffMember.roles',
  ]);
  const { mutateAsync: deleteStaff, isLoading: isDeleting } = mutations.useSafeDeleteStaff();

  const isCurrentUser = `staff/${currentUserID}` === staffName;
  const inOtherProducts = roles.some(
    r => r.product !== product && r.product !== Product.PRODUCT_UNKNOWN,
  );

  const doDelete = () =>
    deleteStaff(data)
      .then(() => {
        // Navigate back to the staff list. It will handle displaying a success toast itself
        onStaffDeleted('Staff member removed');
      })
      .catch(() => {
        toast({
          title: 'Error removing staff member',
          description: undefined,
          status: 'error',
          position: 'bottom-left',
          duration: 5000,
        });
      });

  const onClose = () => {
    // if we are doing the delete don't close the modal
    !isDeleting && setConfirmation(false);
  };

  return (
    <>
      <HeaderField name={`Remove ${givenName} ${familyName}`} />
      <GridItem colSpan={2} display="flex">
        <Stack width="100%">
          {isCurrentUser && (
            <Alert status="warning" borderRadius="md">
              <AlertIcon />
              <Box>
                <Text fontWeight="bold">You cannot remove your own account</Text>
              </Box>
            </Alert>
          )}
          <Button
            maxW={80}
            variant="outline"
            colorScheme="red"
            isDisabled={isCurrentUser || filterUserRolesForProduct(roles, product).length === 0}
            onClick={() => setConfirmation(true)}
          >
            Remove staff member immediately...
          </Button>
        </Stack>
      </GridItem>
      <Modal isOpen={confirmation} onClose={onClose} isCentered={true} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Remove staff member</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Alert status="error" fontSize="2xl" justifyContent="center" mb={4}>
              {givenName} {familyName}
            </Alert>
            {inOtherProducts ? (
              <Text>
                This staff member has roles in other Sparx Learning systems.{' '}
                <strong>
                  If you remove them from {getProductName(product)} they will still keep their roles
                  in other systems.
                </strong>{' '}
                You will be able to re-add them to {getProductName(product)} in the future.
              </Text>
            ) : multipleSchools ? (
              <Text>
                If you remove {givenName} {familyName}, they will no longer be able to log in to any
                Sparx Learning system in your school.{' '}
                <strong>This will not affect other schools they have access to.</strong>
              </Text>
            ) : (
              <Text>
                If you remove {givenName} {familyName}, they will no longer be able to log in to any
                Sparx Learning system and it won't be possible to retrieve their account.{' '}
                <strong>This action is final!</strong>
              </Text>
            )}
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onClose} isLoading={isDeleting}>
              Cancel
            </Button>
            <Button variant="outline" colorScheme="red" onClick={doDelete} isLoading={isDeleting}>
              I understand, remove staff member
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
