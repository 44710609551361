import { Box, HStack, Text } from '@chakra-ui/react';
import {
  Package_Contents,
  Task,
  TaskItem_Contents_Skill,
  TaskItem_Contents_Skill_Section,
  TaskItem_Status,
} from '@sparx/api/apis/sparx/science/packages/v1/package';
import { useUserType } from 'api/sessions';
import React from 'react';
import { CombinedIcon, findSubject, TasksBySubject } from 'utils/subjects';

interface PackageContentsViewerProps {
  contents: Package_Contents | undefined;
  selectedItemName?: string;
  onClickItem: (itemName: string) => void;
  getAttemptCount?: (itemName: string) => number;
  getWrongCount?: (itemName: string) => number;
  showSectionType?: boolean;
  highlightedTopicName?: string;
  noTopMarginOnFirstSibject?: boolean;
}

export const PackageContentsViewer = ({
  contents,
  selectedItemName,
  onClickItem,
  getAttemptCount,
  getWrongCount,
  showSectionType,
  highlightedTopicName,
  noTopMarginOnFirstSibject,
}: PackageContentsViewerProps) => {
  const subjectTasksMap = TasksBySubject(contents?.tasks || []);

  const Task = ({ task }: { task: Task }) => (
    <Box>
      <Text py={2} color="blue.800">
        {task.title}
      </Text>
      <Box display="flex" gap={2} flexWrap="wrap">
        {task.contents?.taskItems.map((item, taskItemIndex) => {
          const selected = selectedItemName === item.name;
          const itemSkill =
            item?.contents?.contents.oneofKind === 'skill'
              ? item.contents.contents.skill
              : undefined;

          return (
            <TaskItemButton
              title={`${taskItemIndex + 1}`}
              onClick={() => onClickItem(item.name)}
              status={item.state?.status}
              key={item.name}
              selected={selected}
              attemptCount={getAttemptCount?.(item.name) || 0}
              wrongCount={getWrongCount?.(item.name) || 0}
              skill={itemSkill}
              showSectionType={showSectionType}
              highlighted={
                highlightedTopicName === itemSkill?.topicName && itemSkill?.topicName !== undefined
              }
            />
          );
        })}
      </Box>
    </Box>
  );

  if (subjectTasksMap) {
    return (
      <>
        {Array.from(subjectTasksMap.keys()).map((subject, subIdx) => {
          const tasks = subjectTasksMap.get(subject);
          if (!tasks) {
            // This shouldn't happen so this is really only keeping typescript happy
            return null;
          }

          const subjectDetails = findSubject(subject);
          const SubjectIcon = subjectDetails?.iconComponent || CombinedIcon;

          return (
            <React.Fragment key={subIdx}>
              <HStack
                borderTopColor="gray.200"
                borderTopWidth={subIdx !== 0 ? 2 : 0}
                pt={4}
                mt={4}
                _first={noTopMarginOnFirstSibject ? { mt: 0 } : undefined}
                mb={2}
              >
                <SubjectIcon width={8} height={8} />
                <Text fontSize="lg" fontWeight="bold" pt={1}>
                  {subjectDetails?.name}
                </Text>
              </HStack>
              {tasks.map(task => (
                <Task key={task.name} task={task} />
              ))}
            </React.Fragment>
          );
        })}
      </>
    );
  }

  return <>{contents?.tasks.map(task => <Task key={task.name} task={task} />)}</>;
};

interface TaskItemButtonProps {
  selected?: boolean;
  attemptCount?: number;
  wrongCount?: number;
  status?: TaskItem_Status;
  title?: string;
  onClick?: () => void;
  skill?: TaskItem_Contents_Skill;
  showSectionType?: boolean;
  highlighted?: boolean;
}

const TaskItemButton = ({
  selected,
  attemptCount,
  wrongCount,
  status,
  title,
  onClick,
  skill,
  showSectionType,
  highlighted,
}: TaskItemButtonProps) => {
  const { isSparxStaff } = useUserType();

  const color =
    attemptCount === 0
      ? 'gray.200'
      : status === TaskItem_Status.CORRECT || status === TaskItem_Status.PENDING_CORRECT
        ? 'green.400'
        : status === TaskItem_Status.SKIPPED
          ? 'gray.400'
          : 'red.400';

  // Get the config for the chip
  const [sectionColor, sectionSymbol] =
    sectionConfig[skill?.section || TaskItem_Contents_Skill_Section.SECTION_UNSPECIFIED];

  return (
    <Box
      bg={selected ? 'blue.100' : highlighted ? 'cyan.700' : 'gray.100'}
      borderWidth={1}
      borderColor={selected ? 'blue.800' : 'white'}
      borderRadius="md"
      px={4}
      py={2}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      onClick={() => onClick?.()}
      transition="all 0.2s"
      _hover={{
        cursor: 'pointer',
        bg: 'blue.100',
      }}
      overflow="hidden"
      position="relative"
    >
      {!showSectionType && isSparxStaff && (
        <Text
          fontSize="xs"
          color="gray.600"
          px={1}
          borderRadius="sm"
          bg={sectionColor}
          position="absolute"
          top={0}
          right={0}
        >
          {sectionSymbol}
        </Text>
      )}
      <Text fontSize="xs" color={highlighted ? 'white' : 'gray.600'} mb={1}>
        {title}
      </Text>
      <Box
        w={showSectionType ? 8 : 10}
        h={showSectionType ? 8 : 10}
        bg={showSectionType ? sectionColor : color}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        borderRadius="md"
        color="white"
        fontWeight="bold"
        border="1px solid white"
      >
        {showSectionType ? sectionSymbol : (wrongCount || 0) > 0 ? wrongCount : ''}
      </Box>
    </Box>
  );
};

export const sectionConfig: Record<TaskItem_Contents_Skill_Section, [string, string]> = {
  [TaskItem_Contents_Skill_Section.SECTION_UNSPECIFIED]: ['red.100', '?'],
  [TaskItem_Contents_Skill_Section.INFOCUS]: ['teal.300', 'T'],
  [TaskItem_Contents_Skill_Section.CONSOLIDATION]: ['purple.300', 'C'],
  [TaskItem_Contents_Skill_Section.FLASHCARDS]: ['orange.300', 'F'],
};

export const SectionIconKey = ({ section }: { section: TaskItem_Contents_Skill_Section }) => {
  const [color, symbol] = sectionConfig[section];
  return (
    <Box
      w={5}
      h={5}
      bg={color}
      color="white"
      fontWeight="bold"
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="md"
      fontSize="xs"
    >
      {symbol}
    </Box>
  );
};
