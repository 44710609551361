import { SchoolStaffMember } from '@sparx/api/apis/sparx/school/staff/schoolstaff/v2/schoolstaff';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { makeOptionalSuspenseQuery } from '@sparx/query';
import { listSchoolStaffMembers } from '@sparx/query/school-staff-service';
import { filterUserRolesForProduct } from '@sparx/staff-manager/src/utils';
import { Options } from 'api/school';
import { useSession, useUserType } from 'api/sessions';
import { v5 as v5uuid } from 'uuid';

// Root namespace UUID used to generate science staff IDs from sparx staff IDs
// This matches the on used server side in 'science/server/pkg/api/accesscheck.go'
const staffRootUUID = '8a0e9cb7-6a4a-4cad-a6a7-f47495dd9e81';

// Staff have a ID specific to science, this function calculates it from the staff object
export const getSciStaffID = (s: SchoolStaffMember) =>
  v5uuid(`${s.name}/${s.school.slice('schools/'.length)}`, staffRootUUID);

const staffListToLookup = (staff: SchoolStaffMember[]) => {
  return staff.reduce<Record<string, SchoolStaffMember | undefined>>((p, s) => {
    // Staff have a sparx staff ID and a science staff ID, add both to
    // the record so call sites don't need to worry about using the right one.
    p[s.name.slice('staff/'.length)] = s;
    p[getSciStaffID(s)] = s;
    return p;
  }, {});
};

const useListStaff = makeOptionalSuspenseQuery(listSchoolStaffMembers);

/**
 * A query which returns a map from sparx staff ID or science staff ID to `SchoolStaffMember`.
 * Based on `useListStaff` from the staff manager package, this must be used inside staff context.
 */
export const useStaffLookup = (options: Options) => {
  return useListStaff({ select: staffListToLookup, ...options });
};

/**
 * A query which returns a `SchoolStaffMember` object for the currently logged-in
 * user, if they are a teacher. Does not require staff context.
 */
export const useCurrentStaffUser = () => {
  const { data: user } = useSession();
  const { isTeacher } = useUserType();

  return listSchoolStaffMembers.useSuspenseQuery({
    select: list => staffListToLookup(list)[user?.userId || ''],
    enabled: isTeacher,
  });
};

/**
 * A query which returns a subset of `SchoolStaffMember`s with a science role for a school.
 * Based on `useListStaff` from the staff manager package, this must be used inside staff context.
 */
export const useScienceStaffv2 = (options: Options) => {
  return useListStaff({
    select: staff =>
      staff.filter(
        s =>
          s.productAccess && filterUserRolesForProduct(s.roles, Product.SPARX_SCIENCE).length > 0,
      ),
    ...options,
  });
};
