import { ComponentType, createContext, ReactNode, useContext } from 'react';

import { ExternalLinkProps } from './components/ExternalLink';

interface Context {
  /**
   * Optional: provide a component type for presenting external links,
   * for consistency with other links within an app. Falls back to an
   * internal implementation if not overridden.
   */
  externalLinkComponent?: ComponentType<ExternalLinkProps>;

  // Should probably have client events in a shared package, so we have a standard type
  sendClientEvent: (
    event: { action: string; category: string },
    labels?: {
      [key: string]: string;
    },
  ) => void;

  getInfoTooltip: (text: ReactNode) => ReactNode;
}

const context = createContext<Context | null>(null);

export const useSubscriptionContext = () => {
  const ctx = useContext(context);
  if (ctx === null) {
    throw new Error('useSubscriptionContext must be used within a SubscriptionContextProvider');
  }
  return ctx;
};

export const SubscriptionContextProvider = context.Provider;
