// Due to the functions and properties of KeyboardSensor being marked private which the extending class needs to
// access there are quite a few errors in this file which the below is disabling.
//
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { KeyboardSensor, KeyboardSensorProps } from '@dnd-kit/core';

// Based of off: https://github.com/clauderic/dnd-kit/issues/1367#issuecomment-2292433281

/**
 * Creates a new keyboard sensor, extending the default `KeyboardSensor` class.
 * Stop propagation of the end and cancel events to prevent other event listeners acting on them, such as submit on enter.
 * Adds extra listenser to cancel the drag on resize, visibilitychange and focus lost.
 */

export class CustomKeyboardSensor extends KeyboardSensor {
  constructor(props: KeyboardSensorProps) {
    super(props);
    /**
     * Bind the new methods to the instance.
     */
    this.handleBlur = this.handleBlur.bind(this);
  }

  /**
   * Override the inherited `attach` method to add our new event handlers.
   */
  protected attach() {
    this.handleStart();

    this.windowListeners.add('resize', this.handleCancel);
    this.windowListeners.add('visibilitychange', this.handleCancel);

    setTimeout(() => {
      /**
       * Updates this event listener to fire in the capture phase.
       */
      this.listeners.add('keydown', this.handleKeyDown, { capture: true });
      /**
       * Add a blur event listener in the capture phase.
       */
      this.listeners.add('blur', this.handleBlur, { capture: true });
    });
  }

  /**
   * Cancel the event if the focus is lost.
   */
  protected handleBlur(event: FocusEvent) {
    this.handleCancel(event);
  }

  /**
   * Override the inherited `handleEnd` method and stop propagation.
   * This is helpful because things like modals listen to escape keys,
   * and it is possible that someone marks the escape key as a trigger
   * for `end`.
   */
  protected handleEnd(event: Event) {
    const { onEnd } = this.props;

    event.preventDefault();
    event.stopPropagation();

    this.detach();
    onEnd();
  }

  /**
   * Override the inherited `handleCancel` method and stop propagation.
   * This is helpful because things like modals listen to escape keys,
   * and it is likely that people use the escape key to trigger a `cancel`,
   * is is done in the default implementation.
   */
  protected handleCancel(event: Event) {
    const { onCancel } = this.props;

    event.preventDefault();
    event.stopPropagation();

    this.detach();
    onCancel();
  }
}
