import { StyleProps, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { SchoolStaffMember } from '@sparx/api/apis/sparx/school/staff/schoolstaff/v2/schoolstaff';
import { StaffRole } from '@sparx/api/apis/sparx/school/staff/v2/staff';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { listSchoolStaffMembers } from '@sparx/query/school-staff-service';
import { RoleDisplay } from '@sparx/staff-manager';
import { filterUserRolesForProduct } from '@sparx/staff-manager/src/utils';
import { TrainingProgressCell } from '@sparx/staff-manager/src/views/TrainingProgressCell';
import { useStaffTrainingSummaries } from '@sparx/teacher-training';
import { articleIdStaffTraining } from 'utils/knowledgeBaseArticles';

import { IsRolloverProps, PageTab, TabDetails } from '..';
import { ActionBox } from '../components/ActionBox';
import { teachingRoles } from './ManageStaff';

const title = 'Complete Staff Training';

const reqNoTrainingRoles = [StaffRole.TRUST_DIRECTOR];

const filterToTrainingRequired = (staffMembers: SchoolStaffMember[]) =>
  staffMembers.filter(
    sm =>
      sm.productAccess &&
      !sm.roles.some(
        r => r.product === Product.SPARX_SCIENCE && reqNoTrainingRoles.includes(r.role),
      ) &&
      sm.roles.some(r => r.product === Product.SPARX_SCIENCE && teachingRoles.includes(r.role)),
  );

const Page = (props: IsRolloverProps) => {
  const { data: staffMembers = [] } = listSchoolStaffMembers.useSuspenseQuery();
  const scienceTeachers = filterToTrainingRequired(staffMembers);
  const tabDetails = useTabDetails(props);

  const headerStyles: StyleProps = {
    backgroundColor: 'blue.800',
    textTransform: 'none',
    fontSize: 'sm',
    color: 'white',
    zIndex: 10,
    top: 0,
    px: 6,
    py: 4,
  };

  return (
    <>
      <ActionBox
        heading="Ask staff members to complete their Staff Training"
        complete={tabDetails.status === 'complete'}
        faIcon={faGraduationCap}
        my={4}
        button={{
          text: 'Your Training',
          path: '/teacher/training',
        }}
        knowledgeBaseArticleId={articleIdStaffTraining}
      >
        Click your name in the top right of Sparx and then select <strong>Your Training</strong>
      </ActionBox>
      <TableContainer whiteSpace="unset" boxShadow="elevationLow" borderRadius="md">
        <Table backgroundColor="white" overflow="hidden">
          <Thead>
            <Tr>
              <Th {...headerStyles} borderTopLeftRadius="md">
                First name
              </Th>
              <Th {...headerStyles}>Last name</Th>
              <Th {...headerStyles}>Role</Th>
              <Th {...headerStyles} borderTopRightRadius="md">
                Training progress
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {scienceTeachers.length === 0 && (
              <Tr>
                <Td colSpan={4} textAlign="center" color="gray.500">
                  No Science Teachers have been added
                </Td>
              </Tr>
            )}
            {scienceTeachers.map(staffMember => (
              <Tr key={staffMember.name}>
                <Td data-sentry-mask>{staffMember.givenName}</Td>
                <Td data-sentry-mask>{staffMember.familyName}</Td>
                <Td>
                  <RoleDisplay
                    product={Product.SPARX_SCIENCE}
                    roles={filterUserRolesForProduct(staffMember.roles, Product.SPARX_SCIENCE)}
                  />
                </Td>
                <Td>
                  <TrainingProgressCell staffName={staffMember.name} />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

const useTabDetails = (_: IsRolloverProps): TabDetails => {
  const { data: staffMembers = [] } = listSchoolStaffMembers.useSuspenseQuery();
  const scienceTeachers = filterToTrainingRequired(staffMembers);
  const { data: summaries = {}, isSuccess: summariesSuccess } = useStaffTrainingSummaries(
    scienceTeachers.map(sm => sm.name.split('/')[1]),
    { suspense: false },
  );

  const numComplete = Object.values(summaries).reduce(
    (acc, summary) => (summary.completed >= summary.total ? acc + 1 : acc),
    0,
  );
  const total = scienceTeachers.length;

  const status = !summariesSuccess
    ? 'loading'
    : total > 0 && numComplete >= total
      ? 'complete'
      : undefined;

  return {
    title,
    subtitle: !summariesSuccess
      ? undefined
      : numComplete === 0
        ? 'Not completed'
        : numComplete >= total
          ? 'All complete'
          : `${numComplete} of ${total} completed training`,
    icon: faGraduationCap,
    status,
  };
};

const TrainingTab: PageTab = {
  path: 'training',
  detailsHook: useTabDetails,
  page: Page,
};
export default TrainingTab;
