import {
  Box,
  Button,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  Task,
  TaskItem_Contents_Skill_Section,
} from '@sparx/api/apis/sparx/science/packages/v1/package';
import { Subject } from '@sparx/api/apis/sparx/science/v1/subject';
import { useTopicLookup } from 'api/content';
import { usePackage } from 'api/packages';
import { useBackLink } from 'app/BackLink';
import { useClientEvent } from 'components/ClientEventProvider';
import { StatusBadge } from 'components/CompletionBadge';
import { PageContainer } from 'components/PageContainer';
import { PageTitle } from 'components/pagetitle/PageTitle';
import { PrettyTimestamp } from 'components/timestamp/PrettyTimestamp';
import React, { useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { CombinedIcon, findSubject, TasksBySubject } from 'utils/subjects';
import { TaskRow } from 'views/package/TaskRow';

import { HomeworkCancelledDialog } from './HomeworkCancelledDialog';

export const PackageView = () => {
  const params = useParams();
  const packageId = params.packageID || '';

  const { data } = usePackage(packageId, { suspense: true });

  useBackLink(`/packages`);

  // Redirect IL & assessment packages to the first task
  if (
    data?.package &&
    ['independentlearning', 'assessment-fixup'].includes(data.package.type) &&
    data.package.contents &&
    data.package.contents.tasks.length > 0
  ) {
    return <Navigate to={`/${data.package.contents.tasks[0].name}`} replace={true} />;
  }

  const tasks = data?.package?.contents?.tasks || [];

  const subjectTaskMap = TasksBySubject(tasks);

  return (
    <PageContainer>
      <PageTitle
        title={data?.package?.title || ''}
        subtitle={
          <>
            Due <PrettyTimestamp>{data?.package?.endTimestamp}</PrettyTimestamp>
          </>
        }
      >
        <StatusBadge
          completion={data?.package?.state?.completion}
          due={data?.package?.endTimestamp}
        />
      </PageTitle>

      {subjectTaskMap ? (
        <SubjectSplitTaskList tasksMap={subjectTaskMap} />
      ) : (
        <VStack spacing={[2, 3]} align="stretch">
          {tasks.map(task => (
            <TaskRow key={task.name} task={task} />
          ))}
        </VStack>
      )}
      <HomeworkCancelledDialog pkg={data?.package} />
    </PageContainer>
  );
};

const SubjectSplitTaskList = ({ tasksMap }: { tasksMap: Map<Subject, Task[]> }) => (
  <>
    <TopicsTooltip tasksMap={tasksMap} />
    <VStack spacing={[4, 6]} align="stretch">
      {Array.from(tasksMap.keys()).map(subject => {
        const tasks = tasksMap.get(subject);
        return tasks && <SubjectTasks key={subject} subject={subject} tasks={tasks} />;
      })}
    </VStack>
  </>
);

const TopicsTooltip = ({ tasksMap }: { tasksMap: Map<Subject, Task[]> }) => {
  const { sendEvent } = useClientEvent();
  const [infocus, consolidation] = useMemo(() => {
    // Make list of infocus and consolidation topics
    const hwTopics = {
      [TaskItem_Contents_Skill_Section.INFOCUS]: new Map<string, Subject>(),
      [TaskItem_Contents_Skill_Section.CONSOLIDATION]: new Map<string, Subject>(),
    };
    for (const [subject, tasks] of tasksMap.entries()) {
      for (const task of tasks) {
        for (const item of task.contents?.taskItems || []) {
          const contents = item.contents?.contents;
          if (contents?.oneofKind === 'skill') {
            if (contents.skill.topicName && contents.skill.section) {
              if (
                contents.skill.section === TaskItem_Contents_Skill_Section.INFOCUS ||
                contents.skill.section === TaskItem_Contents_Skill_Section.CONSOLIDATION
              ) {
                hwTopics[contents.skill.section].set(contents.skill.topicName, subject);
              }
            }
          }
        }
      }
    }
    return [
      hwTopics[TaskItem_Contents_Skill_Section.INFOCUS],
      hwTopics[TaskItem_Contents_Skill_Section.CONSOLIDATION],
    ];
  }, [tasksMap]);

  const hasTopics = infocus.size > 0 || consolidation.size > 0;

  const popoverInitialRef = React.useRef(null);

  const topicLookup = useTopicLookup({ suspense: false, enabled: hasTopics });

  if (!hasTopics) {
    return null;
  }

  return (
    <Popover
      placement="top"
      initialFocusRef={popoverInitialRef}
      // Force the popover to be disabled when the topic lookup hasn't loaded, this
      // is necessary as it could otherwise be triggered even when the button is disabled
      isOpen={topicLookup.data ? undefined : false}
      onOpen={() => sendEvent({ category: 'package_view', action: 'open_topics_tooltip' })}
    >
      <PopoverTrigger>
        <Button
          variant="unstyled"
          color="blue.500"
          textDecoration="underline"
          textDecorationStyle="dotted"
          textUnderlineOffset={2}
          cursor="help"
          mb={[2, 4]}
          mt={-6}
          fontSize={['sm', 'md']}
          // If the topic lookup hasn't loaded yet don't display the button, but still take up the same screen real estate
          opacity={topicLookup.data ? 1 : 0}
          isDisabled={!topicLookup.data}
        >
          What am I learning about this week?
        </Button>
      </PopoverTrigger>
      <PopoverContent ref={popoverInitialRef} _focus={{ boxShadow: 'none' }}>
        {topicLookup.data && (
          <>
            <PopoverArrow />
            <PopoverBody overflowY="auto" maxH="60vh">
              {Array.from(infocus.keys()).map(name => {
                const topic = topicLookup.data[name];
                if (!topic) {
                  return null;
                }
                const SubjectIcon =
                  findSubject(infocus.get(name) || Subject.SUBJECT_UNDEFINED)?.iconComponent ||
                  CombinedIcon;
                return (
                  <Text key={name}>
                    <SubjectIcon width={4} height={4} mr={1} mt={-1} />
                    {topic.topic.displayName}
                  </Text>
                );
              })}
              {consolidation.size > 0 &&
                (infocus.size > 0 ? (
                  <Text>
                    And {consolidation.size} topic{consolidation.size !== 1 ? 's' : ''} from
                    previous weeks
                  </Text>
                ) : (
                  <Text>
                    {consolidation.size} topic{consolidation.size !== 1 ? 's' : ''} from previous
                    weeks
                  </Text>
                ))}
            </PopoverBody>
          </>
        )}
      </PopoverContent>
    </Popover>
  );
};

const SubjectTasks = ({ subject, tasks }: { subject: Subject; tasks: Task[] }) => {
  const subjectDetails = findSubject(subject);

  const SubjectIcon = subjectDetails?.iconComponent || CombinedIcon;

  return (
    <Box>
      <HStack mb={[2, 4]}>
        <SubjectIcon width={8} height={8} />
        <Text fontSize="lg" fontWeight="bold" pt={1}>
          {subjectDetails?.name}
        </Text>
      </HStack>
      <VStack spacing={[2, 3]} align="stretch">
        {tasks.map(task => (
          <TaskRow key={task.name} task={task} />
        ))}
      </VStack>
    </Box>
  );
};
