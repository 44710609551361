import { Flex } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { ErrorPage } from '@sparx/errors';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { useClientEvent } from 'components/ClientEventProvider';
import { LargeLoading } from 'components/loading/LargeLoading';
import React, { PropsWithChildren, useEffect } from 'react';

export const SuspenseRoute = ({
  children,
  verticalCenter,
}: PropsWithChildren<{ verticalCenter?: boolean }>) => (
  <React.Suspense fallback={<FallbackWithPerformance verticalCenter={verticalCenter} />}>
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <Sentry.ErrorBoundary
          onReset={reset}
          fallback={({ resetError, error }) => (
            <ErrorPage
              error={error}
              reset={resetError}
              systemSelectUrl={window.settings?.SYSTEM_SELECT_URL}
            />
          )}
        >
          {children}
        </Sentry.ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  </React.Suspense>
);

const FallbackWithPerformance = ({ verticalCenter }: { verticalCenter?: boolean }) => {
  const { sendEvent } = useClientEvent();
  useEffect(() => {
    const begin = performance.now();
    return () => {
      const end = performance.now();
      sendEvent(
        { action: 'suspense_route_loading', category: 'performance' },
        { timeSuspended: Math.round(end - begin).toString() },
      );
    };
  }, [sendEvent]);

  return verticalCenter ? (
    <Flex width="100%" height="100%" justifyContent="center" alignItems="center">
      <LargeLoading />
    </Flex>
  ) : (
    <LargeLoading />
  );
};
