// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/content/v2/learningunit.proto" (package "sparx.content.v2", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
/**
 * A LearningUnit is an ordered collection of skills covering an objective.
 *
 * Learning Units contain Learning Tasks that specify a method of teaching
 * the objective. Learning Units also contain additional metadata which can
 * be displayed to users intending to teach the objective.
 *
 * @generated from protobuf message sparx.content.v2.LearningUnit
 */
export interface LearningUnit {
  /**
   * The resource name of the Learning Unit.
   * LearningUnit names have the form `learningunits/{lu_id}`
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  // Fields reserved for future use - likely to be used in some form.
  // bool deleted = 2;
  // Timestamp create_time = 3;
  // Timestamp update_time = 4;

  /**
   * The objective the Learning Unit is teaching. This can contain LaTeX
   * markup which should be handled appropriately.
   *
   * @generated from protobuf field: string objective = 5;
   */
  objective: string;
  /**
   * Whether this Learning Unit contains content to be answered online.
   * Deprecated. Replaced by objective_type.
   *
   * @generated from protobuf field: bool online = 6;
   */
  online: boolean;
  /**
   * The type of the objective.
   *
   * @generated from protobuf field: sparx.content.v2.LearningUnitObjectiveType objective_type = 11;
   */
  objectiveType: LearningUnitObjectiveType;
  /**
   * Additional details that may be presented to a teacher.
   *
   * @generated from protobuf field: sparx.content.v2.LearningUnit.TeachingDetails details = 7;
   */
  details?: LearningUnit_TeachingDetails;
  /**
   * A string that provides a specification to determine the level This
   * Learning Unit is designed to be. E.g. "5", "G3".
   *
   * @generated from protobuf field: string level_spec = 8;
   */
  levelSpec: string;
  /**
   * Flags for this Learning Unit. See LearningUnitFlag for details.
   * Any flag that appears at least once is considered to be `set`.
   *
   * @generated from protobuf field: repeated sparx.content.v2.LearningUnitFlag flags = 9;
   */
  flags: LearningUnitFlag[];
  // Future Note - if we end up with a more complicated flag style we may be
  // better to create a generic map[string]string labels field.

  /**
   * Tasks contained within this Learning Unit. The order of the tasks is
   * important.
   *
   * @generated from protobuf field: repeated sparx.content.v2.LearningTask tasks = 10;
   */
  tasks: LearningTask[];
}
/**
 * @generated from protobuf message sparx.content.v2.LearningUnit.TeachingDetails
 */
export interface LearningUnit_TeachingDetails {
  /**
   * A summary of what learners will be taught. This can contain LaTeX
   * markup which should be handled appropriately.
   *
   * @generated from protobuf field: string summary = 1;
   */
  summary: string;
  /**
   * Notes for a teacher documenting anything unexpected or noteworthy, such
   * as methods. This can contain LaTeX markup which should be handled
   * appropriately.
   *
   * @generated from protobuf field: string teaching_notes = 2;
   */
  teachingNotes: string;
  /**
   * The ID for an image attached to the teaching notes, if it exists.
   *
   * @generated from protobuf field: string teaching_image_id = 103;
   */
  teachingImageId: string; // Reserved for future image resource name use.
  // string teaching_image_name = 3;
  /**
   * Any additional resources required to answer the content in this
   * Learning Unit. Examples might include "compass", "dictionary".
   *
   * @generated from protobuf field: repeated string additional_resources = 4;
   */
  additionalResources: string[];
}
/**
 * A LearningTask is an ordered set of Learning Items.
 *
 * @generated from protobuf message sparx.content.v2.LearningTask
 */
export interface LearningTask {
  /**
   * The type of this Learning Task.
   *
   * @generated from protobuf field: sparx.content.v2.LearningTaskType type = 1;
   */
  type: LearningTaskType;
  /**
   * The Learning Items for this Learning Task. The order of the items is
   * important.
   *
   * @generated from protobuf field: repeated sparx.content.v2.LearningItem items = 2;
   */
  items: LearningItem[];
}
/**
 * A LearningItem is a specification for a deliverable item, along with some
 * associated metadata.
 *
 * @generated from protobuf message sparx.content.v2.LearningItem
 */
export interface LearningItem {
  /**
   * An item is key if it represents a key learning point of the objective.
   *
   * @generated from protobuf field: bool key = 1;
   */
  key: boolean;
  /**
   * The name of the Skill for this Learning Item.
   *
   * @generated from protobuf field: string skill_name = 2;
   */
  skillName: string;
}
// Begin request messages definitions.

/**
 * Request message for LearningUnits.ListLearningUnits.
 *
 * @generated from protobuf message sparx.content.v2.ListLearningUnitsRequest
 */
export interface ListLearningUnitsRequest {
  /**
   * Requested page size. Server may return fewer results.
   * If unspecified, server will pick an arbitrary default.
   *
   * @generated from protobuf field: int32 page_size = 1;
   */
  pageSize: number;
  /**
   * A token identifying a page of results to return.
   * Typically the value of [ListLearningUnitsResponse.next_page_token] from
   * a previous `ListLearningUnits` request.
   *
   * @generated from protobuf field: string page_token = 2;
   */
  pageToken: string;
  /**
   * Specifies which fields of the Learning Unit will be contained within
   * the responses.
   *
   * @generated from protobuf field: sparx.content.v2.LearningUnitView view = 3;
   */
  view: LearningUnitView;
}
/**
 * Response message for LearningUnits.ListLearningUnits.
 *
 * @generated from protobuf message sparx.content.v2.ListLearningUnitsResponse
 */
export interface ListLearningUnitsResponse {
  /**
   * The list of Learning Units
   *
   * @generated from protobuf field: repeated sparx.content.v2.LearningUnit learning_units = 1;
   */
  learningUnits: LearningUnit[];
  /**
   * A token to retrieve the next page of results.
   * Pass this value in the `ListLearningUnitsRequest.page_token` in a
   * subsequent call to ListLearningUnits to retrieve the next page.
   *
   * @generated from protobuf field: string next_page_token = 2;
   */
  nextPageToken: string;
}
/**
 * Request message for LearningUnits.BatchGetLearningUnits.
 *
 * @generated from protobuf message sparx.content.v2.BatchGetLearningUnitsRequest
 */
export interface BatchGetLearningUnitsRequest {
  /**
   * Required. The names of the Learning Units to retrieve. The server may refuse to respond if
   * the list is too long. Learning Units that do not exist will not cause an error.
   *
   * @generated from protobuf field: repeated string names = 1;
   */
  names: string[];
}
/**
 * Response message for LearningUnits.BatchGetLearningUnits.
 *
 * @generated from protobuf message sparx.content.v2.BatchGetLearningUnitsResponse
 */
export interface BatchGetLearningUnitsResponse {
  /**
   * The list of Learning Units. May be different order and length to request.
   *
   * @generated from protobuf field: repeated sparx.content.v2.LearningUnit learning_units = 1;
   */
  learningUnits: LearningUnit[];
}
/**
 * Request message for LearningUnits.GetLearningUnit.
 *
 * @generated from protobuf message sparx.content.v2.GetLearningUnitRequest
 */
export interface GetLearningUnitRequest {
  /**
   * Required. The name of the Learning Unit to retrieve.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * LearningUnitFlags control various aspects of Learning Unit usage and delivery.
 *
 * @generated from protobuf enum sparx.content.v2.LearningUnitFlag
 */
export enum LearningUnitFlag {
  /**
   * Default enum value mapping to no flag.
   *
   * @generated from protobuf enum value: LEARNING_UNIT_FLAG_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * Calculators are allowed to be used to answer this content. This information
   * should be flagged to teachers and student's appropriately.
   *
   * @generated from protobuf enum value: LEARNING_UNIT_FLAG_MATHS_CALCULATOR_ALLOWED = 1;
   */
  MATHS_CALCULATOR_ALLOWED = 1,
  /**
   * This is an objective which contains skills intended for use as flashcards
   *
   * @generated from protobuf enum value: LEARNING_UNIT_FLAG_FLASHCARDS = 2;
   */
  FLASHCARDS = 2,
}
/**
 * @generated from protobuf enum sparx.content.v2.LearningUnitObjectiveType
 */
export enum LearningUnitObjectiveType {
  /**
   * Default enum value.
   *
   * @generated from protobuf enum value: LEARNING_UNIT_OBJECTIVE_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * Learn objectives are standard objectives designed to teach and assess
   * the skills.
   *
   * @generated from protobuf enum value: LEARNING_UNIT_OBJECTIVE_TYPE_LEARN = 1;
   */
  LEARN = 1,
  /**
   * Offline objectives are entirely offline and intended to provide teachers
   * with materials to teach skills that are difficult to assess online.
   *
   * @generated from protobuf enum value: LEARNING_UNIT_OBJECTIVE_TYPE_OFFLINE = 2;
   */
  OFFLINE = 2,
  /**
   * Practice objectives cover skills where additional offline learning is
   * required to fully teach the skill.
   *
   * @generated from protobuf enum value: LEARNING_UNIT_OBJECTIVE_TYPE_PRACTICE = 3;
   */
  PRACTICE = 3,
}
/**
 * LearningTaskType is the different styles of Learning Tasks we use.
 *
 * @generated from protobuf enum sparx.content.v2.LearningTaskType
 */
export enum LearningTaskType {
  /**
   * Default enum value.
   *
   * @generated from protobuf enum value: LEARNING_TASK_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * Core items are of a difficulty that all assigned learners should be able
   * to complete.
   *
   * @generated from protobuf enum value: LEARNING_TASK_TYPE_CORE = 1;
   */
  CORE = 1,
  /**
   * Extension items are intended to stretch stronger learners or provide extra
   * material for hard workers.
   *
   * @generated from protobuf enum value: LEARNING_TASK_TYPE_EXTENSION = 2;
   */
  EXTENSION = 2,
  /**
   * Challenge items are expected to test stronger learners, often bringing
   * in cross topic material and thought provoking content.
   *
   * @generated from protobuf enum value: LEARNING_TASK_TYPE_CHALLENGE = 3;
   */
  CHALLENGE = 3,
  /**
   * Teaching items summarise key skills for the teacher to teach. They are
   * not intended to be answered by learners.
   *
   * @generated from protobuf enum value: LEARNING_TASK_TYPE_TEACHING = 4;
   */
  TEACHING = 4,
}
/**
 * @generated from protobuf enum sparx.content.v2.LearningUnitView
 */
export enum LearningUnitView {
  /**
   * Not specified, equivalent to LEARNING_UNIT_VIEW_FULL
   *
   * @generated from protobuf enum value: LEARNING_UNIT_VIEW_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * Service responses will not include task.
   *
   * @generated from protobuf enum value: LEARNING_UNIT_VIEW_SUMMARY = 1;
   */
  SUMMARY = 1,
  /**
   * Service responses will include all fields.
   * The default value.
   *
   * @generated from protobuf enum value: LEARNING_UNIT_VIEW_FULL = 2;
   */
  FULL = 2,
}
// @generated message type with reflection information, may provide speed optimized methods
class LearningUnit$Type extends MessageType<LearningUnit> {
  constructor() {
    super('sparx.content.v2.LearningUnit', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: 'objective', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 6, name: 'online', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 11,
        name: 'objective_type',
        kind: 'enum',
        T: () => [
          'sparx.content.v2.LearningUnitObjectiveType',
          LearningUnitObjectiveType,
          'LEARNING_UNIT_OBJECTIVE_TYPE_',
        ],
      },
      {
        no: 7,
        name: 'details',
        kind: 'message',
        T: () => LearningUnit_TeachingDetails,
      },
      { no: 8, name: 'level_spec', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 9,
        name: 'flags',
        kind: 'enum',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => [
          'sparx.content.v2.LearningUnitFlag',
          LearningUnitFlag,
          'LEARNING_UNIT_FLAG_',
        ],
      },
      {
        no: 10,
        name: 'tasks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => LearningTask,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.LearningUnit
 */
export const LearningUnit = new LearningUnit$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LearningUnit_TeachingDetails$Type extends MessageType<LearningUnit_TeachingDetails> {
  constructor() {
    super('sparx.content.v2.LearningUnit.TeachingDetails', [
      { no: 1, name: 'summary', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'teaching_notes',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 103,
        name: 'teaching_image_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'additional_resources',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.LearningUnit.TeachingDetails
 */
export const LearningUnit_TeachingDetails =
  new LearningUnit_TeachingDetails$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LearningTask$Type extends MessageType<LearningTask> {
  constructor() {
    super('sparx.content.v2.LearningTask', [
      {
        no: 1,
        name: 'type',
        kind: 'enum',
        T: () => [
          'sparx.content.v2.LearningTaskType',
          LearningTaskType,
          'LEARNING_TASK_TYPE_',
        ],
      },
      {
        no: 2,
        name: 'items',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => LearningItem,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.LearningTask
 */
export const LearningTask = new LearningTask$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LearningItem$Type extends MessageType<LearningItem> {
  constructor() {
    super('sparx.content.v2.LearningItem', [
      { no: 1, name: 'key', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 2, name: 'skill_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.LearningItem
 */
export const LearningItem = new LearningItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListLearningUnitsRequest$Type extends MessageType<ListLearningUnitsRequest> {
  constructor() {
    super('sparx.content.v2.ListLearningUnitsRequest', [
      { no: 1, name: 'page_size', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'page_token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'view',
        kind: 'enum',
        T: () => [
          'sparx.content.v2.LearningUnitView',
          LearningUnitView,
          'LEARNING_UNIT_VIEW_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.ListLearningUnitsRequest
 */
export const ListLearningUnitsRequest = new ListLearningUnitsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListLearningUnitsResponse$Type extends MessageType<ListLearningUnitsResponse> {
  constructor() {
    super('sparx.content.v2.ListLearningUnitsResponse', [
      {
        no: 1,
        name: 'learning_units',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => LearningUnit,
      },
      {
        no: 2,
        name: 'next_page_token',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.ListLearningUnitsResponse
 */
export const ListLearningUnitsResponse = new ListLearningUnitsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetLearningUnitsRequest$Type extends MessageType<BatchGetLearningUnitsRequest> {
  constructor() {
    super('sparx.content.v2.BatchGetLearningUnitsRequest', [
      {
        no: 1,
        name: 'names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.BatchGetLearningUnitsRequest
 */
export const BatchGetLearningUnitsRequest =
  new BatchGetLearningUnitsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetLearningUnitsResponse$Type extends MessageType<BatchGetLearningUnitsResponse> {
  constructor() {
    super('sparx.content.v2.BatchGetLearningUnitsResponse', [
      {
        no: 1,
        name: 'learning_units',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => LearningUnit,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.BatchGetLearningUnitsResponse
 */
export const BatchGetLearningUnitsResponse =
  new BatchGetLearningUnitsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLearningUnitRequest$Type extends MessageType<GetLearningUnitRequest> {
  constructor() {
    super('sparx.content.v2.GetLearningUnitRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.v2.GetLearningUnitRequest
 */
export const GetLearningUnitRequest = new GetLearningUnitRequest$Type();
/**
 * @generated ServiceType for protobuf service sparx.content.v2.LearningUnits
 */
export const LearningUnits = new ServiceType('sparx.content.v2.LearningUnits', [
  {
    name: 'ListLearningUnits',
    options: { 'google.api.http': { get: '/v2/learningunits' } },
    I: ListLearningUnitsRequest,
    O: ListLearningUnitsResponse,
  },
  {
    name: 'BatchGetLearningUnits',
    options: { 'google.api.http': { get: '/v2/learningunits:batchGet' } },
    I: BatchGetLearningUnitsRequest,
    O: BatchGetLearningUnitsResponse,
  },
  {
    name: 'GetLearningUnit',
    options: { 'google.api.http': { get: '/v2/{name=learningunits/*}' } },
    I: GetLearningUnitRequest,
    O: LearningUnit,
  },
]);
