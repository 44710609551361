import {
  Box,
  Button,
  IconButton,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import classNames from 'classnames';
import { useClientEvent } from 'components/ClientEventProvider';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { Textfit } from 'react-textfit';

import rawElements from './elements.json';
import icon from './icon_periodic_table.svg';
import styles from './PeriodicTable.module.css';

interface PeriodicTableModalProps {
  eventContext?: Record<string, string>;
}

export const PeriodicTableModal = ({ eventContext }: PeriodicTableModalProps) => {
  const { sendEvent } = useClientEvent();
  const { isOpen, onOpen, onClose } = useDisclosure({
    onOpen: () => sendEvent({ category: 'periodic_table', action: 'open' }, eventContext),
    onClose: () => sendEvent({ category: 'periodic_table', action: 'close' }, eventContext),
  });

  return (
    <>
      <Button
        onClick={onOpen}
        variant="outline"
        colorScheme="buttonTeal"
        leftIcon={<Image src={icon} alt="" style={{ width: 30 }} />}
        display={['none', 'none', 'flex']}
        flex="1 0 auto"
      >
        Periodic Table
      </Button>
      <IconButton
        onClick={onOpen}
        variant="outline"
        colorScheme="buttonTeal"
        size={['sm', 'md']}
        display={['flex', 'flex', 'none']}
        px={1}
        aria-label="Periodic Table"
        flex="1 0 auto"
      >
        <Image src={icon} alt="" style={{ width: 30 }} />
      </IconButton>
      <Modal isOpen={isOpen} onClose={onClose} allowPinchZoom={true} isCentered={true}>
        <ModalOverlay />
        <ModalContent
          padding={2}
          width={'max-content'}
          maxWidth={['100%', 'calc(100% - var(--chakra-space-5))']}
          maxHeight={['100%', 'calc(100% - var(--chakra-space-5))']}
          overflow={'hidden'}
        >
          <ModalHeader pb={0} pt={2} pl={3}>
            The Periodic Table of Elements
          </ModalHeader>
          <ModalCloseButton />
          <Box px={2} width="100%" overflow="scroll" as={ScrollContainer} hideScrollbars={true}>
            <PeriodicTable
              onClickElement={el =>
                sendEvent(
                  { category: 'periodic_table', action: 'click' },
                  {
                    ...eventContext,
                    element: el.symbol,
                  },
                )
              }
            />
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};

// Elements from https://github.com/Bowserinator/Periodic-Table-JSON/blob/master/PeriodicTableJSON.json
// Then filtered
interface ElementData {
  name: string;
  symbol: string;
  number: number | string;
  atomic_mass: number | string;
  atomic_mass_not_rounded?: boolean;
  xpos: number;
  ypos: number;
  starred?: boolean;
  unstable?: boolean;
}

const elements = rawElements as ElementData[];

interface PeriodicTableProps {
  onClickElement?: (el: ElementData) => void;
}

export const PeriodicTable = ({ onClickElement }: PeriodicTableProps) => {
  const [selected, setSelected] = React.useState<string>('');

  const ref = useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState(0);
  const updateWidth = useCallback(() => {
    if (ref.current) {
      setWidth(ref.current.clientWidth);
    }
  }, [setWidth, ref]);
  // Call initially
  useEffect(updateWidth, [updateWidth]);

  // Check when the user resizes the window
  useEffect(() => {
    const callback = debounce(updateWidth, 100);
    window.addEventListener('resize', callback);
    return () => window.removeEventListener('resize', callback);
  }, [updateWidth]);

  const elementWidth = width / 18;

  const tableContents = width > 0 && (
    <>
      {[1, 2].map((number, i) => (
        <ElementNumber key={number} number={number} w={elementWidth} l={elementWidth * i} />
      ))}
      {[3, 4, 5, 6, 7, 0].map((number, i) => (
        <ElementNumber key={number} number={number} w={elementWidth} l={elementWidth * (i + 12)} />
      ))}
      {elements.map(el => (
        <ElementCell
          container={ref.current}
          key={el.name}
          element={el}
          w={elementWidth - 2} // -2 for border
          h={elementWidth - 2} // -2 for border
          l={elementWidth * (el.xpos - 1)}
          t={elementWidth * el.ypos}
          selected={el.name === selected}
          onClick={() => {
            setSelected(selected === el.name ? '' : el.name);
            if (selected !== el.name) onClickElement?.(el);
          }}
        />
      ))}
      <ElementCell
        container={ref.current}
        element={{
          name: 'name',
          symbol: 'atomic symbol',
          number: 'atomic (proton) number',
          atomic_mass: 'relative atomic mass',
          xpos: 0, // unused
          ypos: 0, // unused
        }}
        label="Key"
        w={elementWidth * 3}
        h={elementWidth}
        l={elementWidth * 3}
        t={elementWidth * 2}
      />
      <div className={styles.Disclaimer} style={{ top: elementWidth * 8 }}>
        <p>
          * The Lanthanides (atomic numbers 58 – 71) and the Actinides (atomic numbers 90 – 103)
          have been omitted.
        </p>
        <p>
          Relative atomic masses for <strong>Cu</strong> and <strong>Cl</strong> have not been
          rounded to the nearest whole number.
        </p>
      </div>
    </>
  );

  return (
    <div
      className={styles.Table}
      ref={ref}
      style={
        {
          height: `${elementWidth * 9}px`,
          '--unit-width': `${width / 100}px`,
        } as React.CSSProperties
      }
    >
      {tableContents}
    </div>
  );
};

interface ElementNumberProps {
  number: number;
  w: number;
  l: number;
}

const ElementNumber = ({ number, w, l }: ElementNumberProps) => (
  <div className={styles.ElementNumber} style={{ left: l, width: w, height: w }}>
    {number}
  </div>
);

interface ElementCellProps {
  element: ElementData;
  container?: HTMLDivElement | null;
  w: number;
  h: number;
  l: number;
  t: number;
  selected?: boolean;
  label?: string;
  onClick?: () => void;
}

const ElementCell = React.memo(
  ({ element, container, w, h, l, t, selected, label, onClick }: ElementCellProps) => {
    const { offsetX, offsetY } = useMemo(() => {
      const bounds = container?.getBoundingClientRect();
      if (!bounds || !selected) return { offsetX: 0, offsetY: 0 };

      const realLeft = l + w / 2 - w * 1.1;
      const realRight = realLeft + w * 2.2;
      const realTop = t + h / 2 - w * 1.1;
      const realBottom = realTop + h * 3;

      let offsetX = 0;
      let offsetY = 0;
      if (realLeft < 0) offsetX = realLeft;
      if (realRight > bounds.width) offsetX = -(bounds.width - realRight);
      if (realTop < 0) offsetY = realTop;
      if (realBottom > bounds.height) offsetY = -(bounds.height - realBottom);

      return { offsetX, offsetY };
    }, [l, t, w, h, container, selected]);

    return (
      <div
        className={classNames(
          styles.Cell,
          selected && styles.CellSelected,
          onClick && styles.CellClickable,
        )}
        onClick={onClick}
        style={{
          left: l - offsetX,
          top: t - offsetY,
          width: w,
          height: h,
        }}
      >
        <div className={styles.CellBackground} />
        {element.starred && <div className={styles.CellBorderOmitted} />}
        <div className={styles.CellBox}>
          {label && <div className={styles.CellLabel}>{label}</div>}
          <span className={styles.AtomicMass}>
            {element.unstable ? `[${element.atomic_mass}]` : element.atomic_mass}
          </span>
          <span className={styles.Symbol} translate="no">
            {element.symbol}
            {element.starred && '*'}
          </span>
          <Textfit max={(w * 18) / 100} className={styles.ElementName}>
            {element.name}
          </Textfit>
          <span className={styles.ProtonNumber}>{element.number}</span>
        </div>
      </div>
    );
  },
);

ElementCell.displayName = 'ElementCell';
