import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import { SchoolStaffMember } from '@sparx/api/apis/sparx/school/staff/schoolstaff/v2/schoolstaff';
import { StaffRole, StaffRoleAssignment } from '@sparx/api/apis/sparx/school/staff/v2/staff';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { FieldMask } from '@sparx/api/google/protobuf/field_mask';
import { listSchoolStaffMembers, mutations } from '@sparx/query/school-staff-service';
import { Stack } from '@sparx/sparx-design/components/stack/Stack';
import {
  getDuplicateRoleWarningMessage,
  hasRole,
  roleAssignmentsEqual,
  willStealUniqueRoles,
} from '@sparx/staff-manager';
import { RoleSelector } from '@sparx/staff-manager/src/views/RoleSelector';
import { useScienceEnabledDate } from 'api/school';
import { useSchoolCalendar } from 'api/schoolcalendar';
import { FeatureFlag, useBooleanFlag, useStringFlag } from 'api/sessions';
import { useCurrentStaffUser } from 'api/staff';
import { UserSettings, useUpdateUserSetting, useUserSetting } from 'api/usersettings';
import { useClientEvent } from 'components/ClientEventProvider';
import { Warning } from 'components/warning';
import { addDays, addMonths, format, isAfter, isBefore, parse } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { useCallback, useMemo, useRef, useState } from 'react';

// We show the role prompt if the teacher hasn't seen in this academic year, with the following exceptions:
//  - don't show it within 9 months of science being turned on for the school
//  - don't show it within 21 days of the academic year start date
//
// If an override date is set, we show if the override date is in the past and after the submitted date, and don't show
// if its not, ignoring the other criteria
// If the suppress flag is set the we don't show ever
const useShouldShowRolePrompt = () => {
  const storedOverrideDate = useStringFlag(FeatureFlag.RolePromptOverrideDate);
  const overrideDate = parseStoredDate(storedOverrideDate);
  const suppressRolePrompt = useBooleanFlag(FeatureFlag.SuppressRolePrompt);
  const now = useRef(Date.now());
  const { data: storedLastSubmittedPrompt } = useUserSetting(UserSettings.lastSubmittedRolePrompt, {
    suspense: true,
  });
  const lastSubmittedPrompt = parseStoredDate(storedLastSubmittedPrompt);

  const scienceEnabledDate = useScienceEnabledDate();
  const { data: academicYearStartProtoDate } = useSchoolCalendar({
    suspense: true,
    select: c => c.currentYear?.startDate,
  });
  const academicYearStartDate = academicYearStartProtoDate
    ? new Date(
        academicYearStartProtoDate.year,
        academicYearStartProtoDate.month - 1,
        academicYearStartProtoDate.day,
      )
    : undefined;

  if (!scienceEnabledDate || !academicYearStartDate || suppressRolePrompt) {
    return false;
  }

  if (overrideDate) {
    return (
      isAfter(now.current, overrideDate) &&
      (!lastSubmittedPrompt || isBefore(lastSubmittedPrompt, overrideDate))
    );
  }

  const afterSchoolCreatedDelay = isAfter(now.current, addMonths(scienceEnabledDate, 9));
  const afterYearStart = isAfter(now.current, addDays(academicYearStartDate, 21));
  const notPromptedThisYear =
    !lastSubmittedPrompt || isBefore(lastSubmittedPrompt, academicYearStartDate);

  return afterSchoolCreatedDelay && afterYearStart && notPromptedThisYear;
};

export const RolePrompt = () => {
  const toast = useToast();
  const shouldShow = useShouldShowRolePrompt();
  const [isOpen, setOpen] = useState(shouldShow);
  const onClose = () => setOpen(false);
  const { sendEvent } = useClientEvent();

  // get update mutations
  const { mutate: updateSetting } = useUpdateUserSetting();
  const { mutateAsync: updateStaff, isLoading: updateIsLoading } = mutations.useUpsertStaff();

  // get current roles
  const { data: staffUser } = useCurrentStaffUser();
  const [proposedRoles, setProposedRoles] = useState(staffUser?.roles || []);

  // get other staff
  const { data: otherStaff } = listSchoolStaffMembers.useSuspenseQuery({
    select: useCallback(
      (d: SchoolStaffMember[]) => d.filter(s => s.name !== staffUser?.name),
      [staffUser?.name],
    ),
  });

  // check for duplicate unique roles
  const duplicateRoleWarning = useMemo(() => {
    const duplicateUniqueRoles = willStealUniqueRoles(
      proposedRoles,
      staffUser?.roles || [],
      otherStaff,
    );
    return duplicateUniqueRoles
      ? getDuplicateRoleWarningMessage(duplicateUniqueRoles, 'role-prompt')
      : undefined;
  }, [otherStaff, proposedRoles, staffUser?.roles]);

  // check that at least 1 science role is selected
  const noRolesWarning = useMemo(
    () => getNoRolesWarningMessage(proposedRoles, Product.SPARX_SCIENCE),
    [proposedRoles],
  );

  // make toggle function
  const toggleRole = useCallback(
    (role: StaffRole, product: Product) => {
      const assignment = { role, product };
      const roleHeld = proposedRoles.some(pr => roleAssignmentsEqual(pr, assignment));
      const newRoles = roleHeld
        ? proposedRoles.filter(pr => !roleAssignmentsEqual(pr, assignment))
        : [...proposedRoles, assignment];
      setProposedRoles(newRoles);
    },
    [proposedRoles],
  );

  if (!shouldShow || !staffUser) {
    return null;
  }
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      size={'xl'}
      scrollBehavior="outside"
      isCentered={true}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Please let us know if your role has changed</ModalHeader>
        <ModalBody py={0}>
          <Stack direction="column" spacing={4}>
            <div>
              To optimise your Sparx experience, please review the role(s) that we hold for you
              below and update if necessary. All staff have the same administrative rights.
            </div>
            {noRolesWarning && <Warning status="error">{noRolesWarning}</Warning>}
            {duplicateRoleWarning && <Warning>{duplicateRoleWarning}</Warning>}
            <RoleSelector
              compact
              hasRole={(role: StaffRole, product: Product) => hasRole(proposedRoles, role, product)}
              toggle={toggleRole}
            />
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              onClose();
              sendEvent({
                action: 'Dismissed User Role Prompt with "Not now"',
                category: 'user role prompt',
              });
            }}
            colorScheme="buttonTeal"
            variant="outline"
            mr={3}
          >
            Not now
          </Button>
          <Button
            colorScheme="buttonTeal"
            isDisabled={updateIsLoading || !!noRolesWarning}
            isLoading={updateIsLoading}
            onClick={() => {
              updateStaff({
                staff: SchoolStaffMember.create({ name: staffUser.name, roles: proposedRoles }),
                updateMask: FieldMask.create({ paths: ['roles'] }),
              })
                .then(() => {
                  updateSetting({
                    key: UserSettings.lastSubmittedRolePrompt,
                    value: makeStoredDate(new Date()),
                  });
                  toast({ status: 'success', description: 'Thanks for keeping us up to date!' });
                })
                .catch(() => {
                  toast({
                    status: 'error',
                    description:
                      "We couldn't update your roles at this time. Please try again or use Staff Manager to update your details",
                  });
                })
                .finally(() => {
                  sendEvent({
                    action: 'Dismissed User Role Prompt with "Save and continue"',
                    category: 'user role prompt',
                  });
                  onClose();
                });
            }}
          >
            Save and continue
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const parseStoredDate = (v: string | undefined) =>
  (typeof v === 'string' && v.length > 0 && parse(v, 'yyyy-MM-dd', new Date(), { locale: enGB })) ||
  undefined;

const makeStoredDate = (d: Date) => format(d, 'yyyy-MM-dd', { locale: enGB });

const getNoRolesWarningMessage = (
  roles: StaffRoleAssignment[],
  product: Product,
): string | undefined => {
  const productRoles = roles.filter(r => r.product === product);
  return productRoles.length === 0 ? `Please select at least one role.` : undefined;
};
