import { getDate, getMonth, getYear } from 'date-fns';

export type CivilDate = {
  day: number;
  month: number;
  year: number;
};

export const fromCivilDate = ({ day, month, year }: CivilDate | Readonly<CivilDate>) =>
  new Date(year, month - 1, day);

export const toCivilDate = (date: Date) => ({
  day: getDate(date),
  month: getMonth(date) + 1,
  year: getYear(date),
})
