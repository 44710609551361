import { chakra, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { useRef } from 'react';
import { useCountUp } from 'react-countup';

type FlashcardCategory = 'learning' | 'confident' | 'mastered' | 'review';

interface CategoryConfig {
  headingColour: string;
  bg: string;
}

const config: Record<FlashcardCategory, CategoryConfig> = {
  learning: {
    headingColour: '#30518F',
    bg: 'linear-gradient(0deg, #2D4E8A 0%, #4570BF 100%)',
  },
  confident: {
    headingColour: '#279A59',
    bg: 'linear-gradient(136.81deg, #54D88B 5.35%, #269A59 77.19%)',
  },
  mastered: {
    headingColour: '#B7791F',
    bg: 'linear-gradient(327.14deg, #D68102 17.03%, #DF972A 29.01%, #FAB752 51.35%, #F0B728 72.03%, #E6A708 86.81%)',
  },
  review: {
    headingColour: '#0F8685',
    bg: 'linear-gradient(335.07deg, #0C7C7B 3.62%, #1CC2C1 103.49%)',
  },
};

export const SmallFlashcard = ({
  value,
  category,
  countUp,
  start,
}: {
  value: number;
  category: FlashcardCategory;
  countUp?: boolean;
  start?: number;
}) => {
  const ref = useRef<HTMLParagraphElement>(null);
  useCountUp({
    ref: ref,
    start: countUp ? start || 0 : value,
    end: value,
    duration: 1,
    delay: 0.5,
    startOnMount: !!countUp,
    easingFn: (t: number, b: number, c: number, d: number) =>
      Math.sin(((t / d) * Math.PI) / 2) * c + b,
  });

  return (
    <VStack spacing={[1, 2]} position="relative" flex={'0 1 auto'} flexBasis={['85px', '105px']}>
      <Text
        fontWeight="bold"
        fontSize={['medium', 'lg']}
        letterSpacing="-0.05rem"
        color={config[category].headingColour}
        textTransform={'capitalize'}
      >
        {category}
      </Text>
      <Flex
        color={'white'}
        bg={config[category].bg}
        justifyContent="center"
        alignItems="center"
        borderRadius="2xl"
        flexDirection="column"
        w="100%"
        h={['120px', '140px']}
      >
        <Text
          fontSize={['3xl', '4xl']}
          fontWeight="bold"
          ref={ref}
          textShadow={'0px 3px rgba(0, 0, 0, 0.2)'}
        >
          {countUp ? start || 0 : value}
        </Text>
      </Flex>
    </VStack>
  );
};

export const SmallFlashcardContainer = chakra(HStack, {
  baseStyle: {
    justifyContent: 'center',
    mx: 2,
    gap: [2, 4, 8],
    w: 'calc(100% - 2 * var(--chakra-space-2))',
  },
});
