import {
  Deal as IDeal,
  IndividualSubscriptionStatus,
  SubscriptionDetails as ISubscriptionDetails,
  SubscriptionSummary as ISubscriptionSummary,
} from '@sparx/api/apis/sparx/school/subscription/v1/subscriptionactions';
import { SubscriptionType } from '@sparx/api/apis/sparx/school/subscription/v1/types';
import { Draft, Immutable, produce } from 'immer';

/**
 * @fileoverview
 * To achieve identical behaviour, this reducer is almost an exact copy of the one used by Teacher Portal
 * (see teacherportal/tpclient2/src/components/schoolSettings/Subscription/subscriptionReducer.ts). When
 * making changes here, consider if the maths version requires the same updates. In the long term we should
 * use this module directly in Teacher Portal.
 * */

type Mode =
  | 'Subscribed'
  | 'Trial'
  | 'PendingTrial'
  | 'Group'
  | 'Other'
  | 'Unsupported';

type Deal = Immutable<IDeal & { selected?: boolean }>;

export type State = Immutable<{
  mode: Mode;
  currentSubscription: Partial<ISubscriptionSummary>;
  pendingSubscription: Partial<ISubscriptionSummary> & {
    deals: readonly Deal[];
  };
  selectedDeal?: Deal;
  submitStatus: null | boolean | 'loading';
}>;

type ResetAction = {
  type: 'RESET';
  value: ISubscriptionDetails;
};

type SelectDealAction = {
  type: 'SELECT_DEAL';
  value: number;
};

type SetSubmitStatus = {
  type: 'SET_SUBMIT';
  value: State['submitStatus'];
};

export type Action = ResetAction | SelectDealAction | SetSubmitStatus;

const initialState: State = {
  mode: 'Unsupported',
  submitStatus: null,
  currentSubscription: {},
  pendingSubscription: { deals: [] },
};

const reducer = produce<State, [Action]>(
  (draft: Draft<State>, action: Action) => {
    switch (action.type) {
      case 'RESET': {
        return {
          ...initialiseState(action.value),
          submitStatus: draft.submitStatus,
        };
      }
      case 'SELECT_DEAL': {
        const deals = draft.pendingSubscription.deals;
        if (action.value >= 0 && action.value < deals.length) {
          handleSelectDeal(draft, deals[action.value]);
        }
        return;
      }
      case 'SET_SUBMIT': {
        draft.submitStatus = action.value;
        return;
      }
    }
  }
);

export default reducer;

export const initialiseState = (value: ISubscriptionDetails): State => {
  const st: Draft<State> = {
    ...initialState,
    currentSubscription: {
      ...value.currentSubscription,
    },
    pendingSubscription: {
      ...value.pendingSubscription,
      deals: value.deals,
    },
  };
  if (isZero(st.currentSubscription.startDate)) {
    st.currentSubscription.startDate = undefined;
  }
  if (isZero(st.currentSubscription.endDate)) {
    st.currentSubscription.endDate = undefined;
  }
  if (isZero(st.pendingSubscription.startDate)) {
    st.pendingSubscription.startDate = undefined;
  }
  if (isZero(st.pendingSubscription.endDate)) {
    st.pendingSubscription.endDate = undefined;
  }

  if (
    st.currentSubscription.type === SubscriptionType.TRIAL &&
    st.pendingSubscription.type === SubscriptionType.INDIVIDUAL
  ) {
    st.mode = 'Trial';
  }
  if (
    st.currentSubscription.type !== SubscriptionType.TRIAL &&
    st.pendingSubscription.type === SubscriptionType.INDIVIDUAL
  ) {
    st.mode = 'Subscribed';
  }
  if (st.pendingSubscription.type === SubscriptionType.TRIAL) {
    // TODO: should we only show this if the upcoming subscription is
    // Individual (else use Group/Other mode)?
    st.mode = 'PendingTrial';
  }
  // We don't support Group / Other regardless of whether we're currently in
  // trial or renewing, no need to check current subscription type.
  if (st.pendingSubscription.type === SubscriptionType.SCHOOL_GROUP) {
    st.mode = 'Group';
  }
  if (st.pendingSubscription.type === SubscriptionType.OTHER) {
    st.mode = 'Other';
  }

  // Select the first deal by default
  if (st.pendingSubscription.deals.length >= 1) {
    return produce(st, (draft) =>
      handleSelectDeal(draft, draft.pendingSubscription.deals[0])
    );
  }
  return st;
};

export const modeIs = (mode: Mode, ...oneOf: Mode[]): boolean =>
  oneOf.includes(mode);

const handleSelectDeal = (draft: Draft<State>, deal: Draft<Deal>) => {
  if (
    modeIs(draft.mode, 'Unsupported', 'Group', 'Other') ||
    !canSubmitAIP(draft.pendingSubscription.status)
  ) {
    return;
  }
  draft.pendingSubscription.deals.forEach((d) => {
    d.selected = false;
  });
  deal.selected = true;
  draft.pendingSubscription.endDate = deal.endDate;
  draft.selectedDeal = deal;
};

const isZero = (date: ISubscriptionSummary['startDate']): boolean =>
  !date?.day && !date?.month && !date?.year;

// Ideally we'd only accept NotYetAgreed, but currently the APIs dont return
// that consistently enough.
export const canSubmitAIP = (status?: IndividualSubscriptionStatus): boolean =>
  status == null ||
  status ===
    IndividualSubscriptionStatus.INDIVIDUAL_SUBSCRIPTION_STATUS_UNKNOWN ||
  status === IndividualSubscriptionStatus.NOT_YET_AGREED;
