import { SchoolStaffMember } from '@sparx/api/apis/sparx/school/staff/schoolstaff/v2/schoolstaff';
import { IdentityUserType } from '@sparx/api/apis/sparx/school/v2/schoolactions';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { UserType } from '@sparx/api/apis/sparx/types/user';
import { useCommonParams } from '@sparx/query';
import { useSchoolActionsClient } from '@sparx/query/school-actions-service';
import { useSchoolStaffClient } from '@sparx/query/school-staff-service';
import { useMutation } from '@tanstack/react-query';

import { useStaffContext } from './Context';
import {
  getKeyContactsStatus,
  productKeyContactRoles,
  productOrder,
  sharedKeyContactRoles,
  StaffKeyContacts,
} from './utils';

export type KeyContactData = {
  allStaff: SchoolStaffMember[];
  keyContacts: StaffKeyContacts;
  status: ReturnType<typeof getKeyContactsStatus>;
};

export const keyContactRoles = [...productKeyContactRoles, ...sharedKeyContactRoles] as const;

export const useStaffProducts = () => {
  // Get the school products, don't include the Curriculum
  const { product } = useCommonParams();
  const { school } = useStaffContext();
  const products = school?.products.filter(
    p => p !== Product.SPARXMATHS_CURRICULUM && p !== Product.PRODUCT_UNKNOWN,
  ) || [product];
  return products.sort((a, b) =>
    productOrder[a] > productOrder[b] ? 1 : productOrder[a] < productOrder[b] ? -1 : 0,
  );
};

export const selectKeyContacts =
  (thisProduct: Product) =>
  (staffMembers: SchoolStaffMember[]): KeyContactData => {
    const keyContacts = staffMembers.reduce<StaffKeyContacts>((acc, sm) => {
      sm.roles.forEach(sr => {
        if (sr.product === thisProduct && productKeyContactRoles.includes(sr.role)) {
          acc[sr.role] = sm;
        }
        if (sharedKeyContactRoles.includes(sr.role)) {
          acc[sr.role] = sm;
        }
      });
      return acc;
    }, {} as StaffKeyContacts);
    const status = getKeyContactsStatus(keyContacts);
    return {
      allStaff: staffMembers,
      keyContacts,
      status,
    };
  };

export const useUnlinkSSOAccount = () => {
  const { schoolName } = useCommonParams();
  const schoolActionsClient = useSchoolActionsClient();

  return useMutation({
    mutationFn: async (staffName: string) => {
      if (staffName === '') {
        throw new Error('staff name is required to unlink sso account');
      }

      return schoolActionsClient.unlinkSSOIdentity({
        userId: staffName.replace(/staff\//, ''),
        userType: IdentityUserType.USER_TYPE_UNKNOWN, // This is deprecated but still a required field
        apiUserType: UserType.STAFF_MEMBER,
        schoolName,
      }).response;
    },
  });
};

export const useSendWelcomeEmails = () => {
  const staffClient = useSchoolStaffClient();
  const { product, schoolName } = useCommonParams();
  return useMutation({
    mutationFn: async ({ names }: { names: string[] }) => {
      return await Promise.all(
        names.map(name =>
          staffClient.sendWelcomeEmail({
            name,
            school: schoolName,
            product,
          }),
        ),
      );
    },
  });
};
