import classNames from 'classnames';
import { PropsWithChildren, ReactNode } from 'react';

import { Stack } from '../components/Stack';
import { TextElement } from '../elements/TextElement';
import styles from '../question/SparxQuestion.module.css';
import { IGroupElement } from '../question/types';

interface AnswerContentProps {
  groupElement: IGroupElement;
  keypad: ReactNode;
  contentRef: React.Ref<HTMLDivElement>;
  labelRef: React.Ref<HTMLDivElement>;
  inlineInputs?: boolean;
}

export const AnswerContent = ({
  groupElement,
  children,
  keypad,
  contentRef,
  labelRef,
  inlineInputs,
}: PropsWithChildren<AnswerContentProps>) => {
  const style = groupElement.style;
  const content =
    style === 'matrix-static' ? (
      <div
        ref={contentRef}
        className={styles.MatrixGroup}
        style={{
          gridTemplateColumns: `repeat(${groupElement.columns || 1}, 1fr)`,
        }}
      >
        {children}
      </div>
    ) : (
      <div
        ref={contentRef}
        className={classNames({
          [styles.FractionAnswerContent]: style === 'fraction',
        })}
      >
        <Stack
          dir={style === 'fraction' || style === 'vector' ? 'vertical' : 'horizontal'}
          className={classNames({
            [styles.VectorGroup]: style === 'vector',
            [styles.InlineTextGroup]: inlineInputs,
          })}
          dataTag="answer-content"
        >
          {children}
        </Stack>
      </div>
    );

  return (
    <Stack>
      {groupElement.label && (
        <div ref={labelRef}>
          <TextElement
            element={{
              element: 'text',
              text: groupElement.label,
            }}
          />
        </div>
      )}
      {content}
      {keypad}
    </Stack>
  );
};

AnswerContent.displayName = 'AnswerContent';
