import { GetAssessmentSummaryRequest } from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { CreateFixUpPackageRequest } from '@sparx/api/apis/sparx/science/packages/v1/assessment';
import { useMutation, useQuery } from '@tanstack/react-query';
import { assessmentClient, scienceAssessmentClient } from 'api';

import { updatePackage } from './packages';
import { getSchoolID } from './sessions';
import { BasicQueryOptions } from './utils';

export const useAssessmentSummary = (options?: BasicQueryOptions) => {
  // Only students can make this call, so if we are a teacher disable the query
  // const { isTeacher } = useUserType();
  return useQuery({
    queryKey: ['assessment', 'summary'],
    queryFn: async () =>
      assessmentClient.getAssessmentSummary(
        GetAssessmentSummaryRequest.create({
          schoolName: `schools/${await getSchoolID()}`,
          subjectName: 'subjects/science',
        }),
      ).response,
    staleTime: 1000 * 60 * 15, // 15 minutes
    ...options,
    // enabled: !isTeacher && (options?.enabled === undefined || options.enabled),
    enabled: false,
  });
};

export const useCreateAssessmentFixUpPackage = () =>
  useMutation({
    mutationFn: async ({ assessmentName }: { assessmentName: string }) =>
      scienceAssessmentClient.createFixUpPackage(
        CreateFixUpPackageRequest.create({
          assessmentName,
        }),
      ).response,
    onSuccess: data => {
      data.package.forEach(updatePackage);
    },
  });
