import {
  JoinLessonResponse,
  StudentActiveState,
  StudentPosition,
  UpdateStudentLessonStateRequest,
} from '@sparx/api/apis/sparx/science/lessons/v1/lessons';
import { useDebounce } from '@sparx/react-utils/hooks/use-debounce';
import { useQuery } from '@tanstack/react-query';
import { lessonsClient } from 'api';
import lifecycle, { StateChangeEvent } from 'page-lifecycle';
import { useEffect, useState } from 'react';

export type LessonDeliveryPage =
  | {
      page: 'landing';
      lastActivityId?: string;
    }
  | {
      page: 'setto';
      activityId: string;
    }
  | {
      page: 'task';
      lessonActivityID: string;
      packageID: string;
      taskID: string;
      taskItem?: number | 'results' | 'fcintro';
    };

export const useLessonStatePusher = (
  currentLesson: JoinLessonResponse | null | undefined,
  page: LessonDeliveryPage,
) => {
  const [activeState, setActiveState] = useState<StudentActiveState>(StudentActiveState.ACTIVE);
  const debouncedActiveState = useDebounce(activeState, 250);
  const position = usePositionFromPage(page);

  const state = {
    lessonName: currentLesson?.lessonName || '',
    position,
    activeState: debouncedActiveState,
  };

  useQuery({
    queryKey: ['lesson', state, 'state'],
    queryFn: async () =>
      lessonsClient.updateStudentLessonState(UpdateStudentLessonStateRequest.create(state)),
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: true,
    enabled: Boolean(currentLesson?.lessonName && currentLesson?.sessionId),
    refetchInterval: 15000,
    cacheTime: 0,
  });

  useEffect(() => {
    const handler = (e: StateChangeEvent) => {
      if (e.newState !== e.oldState) {
        setActiveState(
          {
            active: StudentActiveState.ACTIVE,
            passive: StudentActiveState.PASSIVE,
            hidden: StudentActiveState.HIDDEN,
          }[e.newState],
        );
      }
    };
    lifecycle.addEventListener('statechange', handler);
    return () => lifecycle.removeEventListener('statechange', handler);
  }, [setActiveState]);
};

export const usePositionFromPage = (page: LessonDeliveryPage): StudentPosition => {
  let taskItemName = '';
  let lessonActivityId = '';
  if (page.page === 'task') {
    lessonActivityId = page.lessonActivityID;
    if (page.page === 'task') {
      taskItemName = `packages/${page.packageID}/tasks/${page.taskID}/items/${page.taskItem}`;
    }
  }
  return { lessonActivityId, taskItemName };
};
