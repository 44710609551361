import classNames from 'classnames';
import { ComponentProps, forwardRef, useContext } from 'react';

import { FormControlContext } from './FormControlContext';
import styles from './FormLabel.module.css';

interface FormLabelProps extends ComponentProps<'div'> {}

/**
 * FormErrorMessage is a component that is used to display an error message for a form element.
 *
 * It should be used within a `<FormControl/>` component to use the `isInvalid` context from the
 * parent.
 *
 * @example
 * <FormControl isInvalid={!!errors.emailAddress}>
 *   <FormLabel>Email address:</FormLabel>
 *   <Input type="email" />
 *   <FormErrorMessage>
 *     Email is invalid
 *   </FormErrorMessage>
 * </FormControl>
 */
export const FormErrorMessage = forwardRef<HTMLDivElement, FormLabelProps>(
  function FormErrorMessage(props, ref) {
    const { className, children, ...rest } = props;

    // Hide if not invalid
    const formControlContext = useContext(FormControlContext);
    if (!formControlContext?.isInvalid) {
      return null;
    }

    return (
      <div ref={ref} className={classNames(styles.ErrorMessage, className)} {...rest}>
        {children}
      </div>
    );
  },
);
