import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getSchoolStaffMember } from '../get-school-staff-member';
import { listSchoolStaffMembers } from '../list-school-staff-members';
import { useUpsertMutationFn } from './mutation-fns';

export const useUpsertStaff = () => {
  const queryClient = useQueryClient();
  const mutationFn = useUpsertMutationFn();
  return useMutation({
    mutationFn,
    onSuccess: async data => {
      // Invalidate all school staff queries
      await queryClient.invalidateQueries([listSchoolStaffMembers.keyPrefix[0]]);
      // Update the query data in cache for this staff member's get query
      queryClient.setQueryData(getSchoolStaffMember.makeKey({ name: data.name }), data);
    },
  });
};
