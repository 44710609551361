import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { RpcError } from '@protobuf-ts/runtime-rpc';
import {
  TaskItem_Contents_Flashcards,
  TaskItem_Contents_Skill,
  TaskItem_Contents_Skill_Section,
} from '@sparx/api/apis/sparx/science/packages/v1/package';
import { Assignment } from '@sparx/api/apis/sparx/science/packages/v1/planner';
import { GetCurrentSessionResponse } from '@sparx/api/apis/sparx/science/sessions/v1/session';
import { Subject } from '@sparx/api/apis/sparx/science/v1/subject';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { useCreateOrUpdateAssignment } from 'api/planner';
import { useSession } from 'api/sessions';
import { addDays } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

interface CustomAssignmentFormProps {
  children: (callback: {
    form: React.ReactNode;
    formId: string;
    isSubmitting: boolean;
  }) => React.ReactNode;
  onSuccess?: (assignment: Assignment) => void;
}

export const CustomAssignmentForm = ({ children, onSuccess }: CustomAssignmentFormProps) => {
  const { data: user } = useSession();
  const { mutateAsync, isLoading, error } = useCreateOrUpdateAssignment();

  const [assignment, setAssignment] = useState<Assignment>(makeDefaultEmptyAssignment(user));

  const [valid, setValid] = useState(true);
  const [value, _setValue] = useState(
    Assignment.toJsonString(assignment, { prettySpaces: 2, emitDefaultValues: true }),
  );

  const format = () =>
    _setValue(Assignment.toJsonString(assignment, { prettySpaces: 2, emitDefaultValues: true }));

  const setValue = (value: string) => {
    _setValue(value);
    try {
      setAssignment(Assignment.fromJsonString(value));
      setValid(true);
    } catch (e) {
      setValid(false);
    }
  };

  const formId = useMemo(() => `assignment-form-${uuid()}`, []);
  const form = (
    <>
      <VStack
        as="form"
        id={formId}
        alignItems="flex-start"
        spacing={4}
        onSubmit={e => {
          e.preventDefault();
          mutateAsync({ ...assignment, action: 'create' }).then(resp => onSuccess?.(resp));
        }}
      >
        <>
          {error && (
            <Alert status="error">
              <AlertIcon />
              {(error as RpcError).message}
            </Alert>
          )}
          {valid ? (
            <Alert status="success">
              <Flex>
                <AlertIcon />
                Valid input
              </Flex>
              <Box flex={1} />
              <Button my={-2} onClick={format} colorScheme="green" variant="outline" size="sm">
                Format
              </Button>
            </Alert>
          ) : (
            <Alert status="error">
              <AlertIcon />
              Invalid input
            </Alert>
          )}
          <Textarea
            placeholder="Contents"
            value={value}
            onChange={e => setValue(e.target.value)}
            height="70vh"
            fontSize="14px"
            fontFamily="monospace"
          />
        </>
      </VStack>
      <Heading my={2} size={'md'}>
        Ol' favourites
      </Heading>
      <HStack>
        <Button
          onClick={() =>
            setValue(
              Assignment.toJsonString(makeDefaultEmptyAssignment(user), {
                prettySpaces: 2,
                emitDefaultValues: true,
              }),
            )
          }
        >
          Empty Assignment
        </Button>
        <Button
          onClick={() =>
            setValue(
              Assignment.toJsonString(makeFlashCardsAssignment(user), {
                prettySpaces: 2,
                emitDefaultValues: true,
              }),
            )
          }
        >
          Flashcards
        </Button>
      </HStack>
    </>
  );
  return <>{children({ form, isSubmitting: isLoading, formId })}</>;
};

const makeFlashCardsAssignment = (user: GetCurrentSessionResponse | undefined) =>
  Assignment.create({
    generatedTimestamp: undefined,
    groups: undefined,
    name: '',
    schoolName: '',
    title: 'Flashcards',
    userIds: user ? [user.userId] : [],
    startTimestamp: Timestamp.fromDate(new Date()),
    endTimestamp: Timestamp.fromDate(addDays(new Date(), 1)),
    spec: {
      contents: {
        oneofKind: 'staticAssignment',
        staticAssignment: {
          annotations: {},
          tasks: [1, 2, 3].map(() => ({
            title: '',
            annotations: { 'xp/value': (50).toString(), flashcards: 'is' },
            items: [1].map(() => ({
              title: '',
              annotations: { flashcards: 'is' },
              contents: {
                subject: Subject.SUBJECT_UNDEFINED,
                contents: {
                  oneofKind: 'flashcards',
                  flashcards: TaskItem_Contents_Flashcards.create({
                    skills: [
                      '82aa0cfa-de3e-45e3-a3d5-2f25dfe84310',
                      'ccbbb9db-8055-4299-9995-5577b2f62eee',
                      '14118e86-34be-44d5-a865-295d13061a7c',
                      '5d4afd13-d7a5-4aaf-a960-dece58be0953',
                      'bbab72ad-3c77-400c-8042-d5b5a91ea78f',
                    ].map(id =>
                      TaskItem_Contents_Skill.create({
                        skillId: id,
                        section: TaskItem_Contents_Skill_Section.FLASHCARDS,
                      }),
                    ),
                    initialStates: {
                      learning: 5,
                      review: 0,
                    },
                  }),
                },
              },
            })),
          })),
        },
      },
    },
  });

const makeDefaultEmptyAssignment = (user: GetCurrentSessionResponse | undefined) =>
  Assignment.create({
    generatedTimestamp: undefined,
    groups: undefined,
    name: '',
    schoolName: '',
    title: 'Testo',
    userIds: user ? [user.userId] : [],
    startTimestamp: Timestamp.fromDate(new Date()),
    endTimestamp: Timestamp.fromDate(addDays(new Date(), 7)),
    spec: {
      contents: {
        oneofKind: 'staticAssignment',
        staticAssignment: {
          annotations: {},
          tasks: [
            {
              title: '',
              annotations: {},
              items: [
                {
                  title: '',
                  annotations: { 'xp/value': (100).toString() },
                  contents: {
                    subject: Subject.SUBJECT_UNDEFINED,
                    contents: {
                      oneofKind: 'skill',
                      skill: TaskItem_Contents_Skill.create(),
                    },
                  },
                },
              ],
            },
          ],
        },
      },
    },
  });
