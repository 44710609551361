export type EquationRow = {
  words: string;
  symbols?: string;
  higherTier?: boolean;
  searchString?: string;
};
export const aqaEquations: EquationRow[] = [
  {
    words: '\\text{kinetic energy} = 0.5 \\times \\text{mass} \\times (\\text{speed})^2',
    symbols: 'E_k = \\dfrac{1}{2} m v^2',
    searchString: 'kinetic energy = 1/2 0.5 x mass x speed 2',
  },
  {
    words:
      '\\text{elastic potential energy} = 0.5 \\times \\text{spring constant} \\times (\\text{extension})^2',
    symbols: 'E_e = \\dfrac{1}{2} k e^2',
    searchString: 'elastic potential energy = 1/2 0.5 x spring constant x extension 2',
  },
  {
    words:
      '\\text{gravitational potential energy} = \\text{mass} \\times \\text{gravitational field strength} \\times \\text{height}',
    symbols: 'E_p = m g h',
    searchString: 'gravitational potential energy = mass x gravitational field strength x height',
  },
  {
    words:
      '\\text{change in thermal energy} = \\text{mass} \\times \\text{specific heat capacity} \\times \\text{temperature change}',
    symbols: '\\Delta E = m c \\Delta \\theta',
    searchString: 'change in thermal energy = mass x specific heat capacity x temperature change',
  },
  {
    words: '\\text{power} = \\dfrac{\\text{energy transferred}} {\\text{time}}',
    symbols: 'P = \\dfrac{E}{t}',
    searchString: 'power = energy transferred / time',
  },
  {
    words: '\\text{power} = \\dfrac{\\text{work done}}{\\text{time}}',
    symbols: 'P = \\dfrac{W}{t}',
    searchString: 'power = work done / time',
  },
  {
    words:
      '\\text{efficiency} = \\dfrac{\\text{useful output energy transfer}}{\\text{total input energy transfer}}',
    searchString: 'efficiency = useful output energy transfer / total input energy transfer',
  },
  {
    words: '\\text{efficiency} = \\dfrac{\\text{useful power output}}{\\text{total power input}}',
    searchString: 'efficiency = useful power output / total power input',
  },
  {
    words: '\\text{charge flow} = \\text{current} \\times \\text{time}',
    symbols: 'Q = I t',
    searchString: 'charge flow = current x time',
  },
  {
    words: '\\text{potential difference} = \\text{current} \\times \\text{resistance}',
    symbols: 'V = I R',
    searchString: 'potential difference voltage = current x resistance',
  },
  {
    words: '\\text{power} = \\text{potential difference}\\times \\text{current}',
    symbols: 'P = V I',
    searchString: 'power = potential difference x current',
  },
  {
    words: '\\text{power} = (\\text{current})^2 \\times \\text{resistance}',
    symbols: 'P = I^2R',
    searchString: 'power = current 2 x resistance',
  },
  {
    words: '\\text{energy transferred} = \\text{power} \\times \\text{time}',
    symbols: 'E = P t',
    searchString: 'energy transferred = power x time',
  },
  {
    words: '\\text{energy transferred} = \\text{charge flow} \\times \\text{potential difference}',
    symbols: 'E = QV',
    searchString: 'energy transferred = charge flow x potential difference',
  },
  {
    words: '\\text{density}=\\dfrac {\\text{mass}}{\\text{volume}}',
    symbols: '\\rho = \\dfrac{m}{V}',
    searchString: 'density = mass / volume',
  },
  {
    words:
      '\\text{thermal\\ }\\allowbreak \\text{energy\\ }\\allowbreak \\text{for\\ }\\allowbreak \\text{a\\ }\\allowbreak \\text{change\\ }\\allowbreak \\text{of\\ }\\allowbreak \\text{state}= \\text{mass} \\times \\text{specific\\ }\\allowbreak \\text{latent\\ }\\allowbreak \\text{heat}',
    symbols: 'E = m L',
    searchString: 'thermal energy for a change of state = mass x specific latent heat',
  },
  {
    words: '\\text{For gases: pressure} \\times \\text{volume} = \\text{constant}',
    symbols: 'p V = \\text{constant}',
    searchString: 'For gases: pressure x volume = constant',
  },
  {
    words: '\\text{weight} = \\text{mass} \\times \\text{gravitational field strength}',
    symbols: 'W = m g',
    searchString: 'weight = mass x gravitational field strength',
  },
  {
    words:
      '\\text{work done} = \\text{force} \\times \\text{distance\\ }\\allowbreak \\text{(along\\ }\\allowbreak \\text{the\\ }\\allowbreak \\text{line\\ }\\allowbreak \\text{of\\ }\\allowbreak \\text{action\\ }\\allowbreak \\text{of\\ }\\allowbreak \\text{the\\ }\\allowbreak \\text{force)}',
    symbols: 'W = F s',
    searchString: 'work done = force x distance along the line of action of the force',
  },
  {
    words: '\\text{force} = \\text{spring constant} \\times \\text{extension}',
    symbols: 'F = k e',
    searchString: 'force = spring constant x extension',
  },
  {
    words:
      '\\text{moment of a force} = \\text{force} \\times \\text{distance (normal to direction of force)}',
    symbols: 'M = F d',
    searchString: 'moment of a force = force x distance normal to direction of force',
  },
  {
    words:
      '\\text{pressure} = \\dfrac{\\text{force normal to a surface}}{\\text{area of that surface}}',
    symbols: 'p = \\dfrac{F}{A}',
    searchString: 'pressure = force normal to a surface / area of that surface',
  },
  {
    words:
      '\\text{pressure due to a column of liquid} = \\text{height of column} \\times \\text{density of liquid} \\times \\text{gravitational field strength}',
    symbols: 'p = h \\rho g',
    higherTier: true,
    searchString:
      'higher pressure due to a column of liquid = height of column x density of liquid x gravitational field strength',
  },
  {
    words: '\\text{distance travelled} = \\text{speed} \\times \\text{time}',
    symbols: 's = v t',
    searchString: 'distance travelled = speed x time',
  },
  {
    words: '\\text{acceleration} = \\dfrac{\\text{change in velocity}}{\\text{time taken}}',
    symbols: 'a = \\dfrac{\\Delta v}{t}',
    searchString: 'acceleration = change in velocity / time taken',
  },
  {
    words:
      '(\\text{final velocity})^2 - (\\text{initial velocity})^2 = 2 \\times \\text{acceleration} \\times \\text{distance}',
    symbols: 'v^2 - u^2 = 2 a s',
    searchString: 'final velocity 2 - initial velocity 2 = 2 x acceleration x distance',
  },
  {
    words: '\\text{resultant force} = \\text{mass} \\times \\text{acceleration}',
    symbols: 'F = m a',
    searchString: 'resultant force = mass x acceleration',
  },
  {
    words: '\\text{momentum} = \\text{mass} \\times \\text{velocity}',
    symbols: 'p = m v',
    higherTier: true,
    searchString: 'higher momentum = mass x velocity',
  },
  {
    words: '\\text{force} = \\dfrac{\\text{change in momentum}}{\\text{time taken}}',
    symbols: 'F = \\dfrac{m \\Delta v}{\\Delta t}',
    higherTier: true,
    searchString: 'higher force = change in momentum / time taken',
  },
  {
    words: '\\text{period} = \\dfrac{1}{\\text{frequency}}',
    symbols: 'T = \\dfrac{1}{f}',
    searchString: 'period = 1 / frequency',
  },
  {
    words: '\\text{wave speed} = \\text{frequency} \\times \\text{wavelength}',
    symbols: 'v = f \\lambda',
    searchString: 'wave speed = frequency x wavelength',
  },
  {
    words: '\\text{magnification} = \\dfrac{\\text{image height}}{\\text{object height}}',
    searchString: 'magnification = image height / object height',
  },
  {
    words:
      '\\text{force\\ }\\allowbreak \\text{on\\ }\\allowbreak \\text{a\\ }\\allowbreak \\text{conductor\\ }\\allowbreak \\text{(at\\ }\\allowbreak \\text{right\\ }\\allowbreak \\text{angles\\ }\\allowbreak \\text{to\\ }\\allowbreak \\text{a\\ }\\allowbreak \\text{magnetic\\ }\\allowbreak \\text{field\\ }\\allowbreak \\text{carrying\\ }\\allowbreak \\text{a\\ }\\allowbreak \\text{current)}\\allowbreak = \\text{magnetic\\ }\\allowbreak \\text{flux\\ }\\allowbreak \\text{density\\ }\\allowbreak \\times \\text{current} \\times \\text{length}',
    symbols: 'F = B I l',
    higherTier: true,
    searchString:
      'higher force on a conductor at right angles to a magnetic field carrying a current = magnetic flux density x current x length',
  },
  {
    words:
      '\\htmlClass{katex-possible-wrapping-fraction}{\\dfrac {\\text{potential difference across primary coil}} {\\text{potential difference across secondary coil}}} \\allowbreak =\\allowbreak \\htmlClass{katex-possible-wrapping-fraction}{\\dfrac{\\text{number of turns in primary coil}} {\\text{number of turns in secondary coil}}}',
    symbols: '\\dfrac{V_p}{V_s} = \\dfrac{n_p}{n_s}',
    higherTier: true,
    searchString:
      'higher potential difference across primary coil / potential difference across secondary coil = number of turns in primary coil / number of turns in secondary coil',
  },
  {
    words:
      '\\text{potential\\ }\\allowbreak \\text{difference\\ }\\allowbreak \\text{across\\ }\\allowbreak \\text{primary\\ }\\allowbreak \\text{coil} \\times \\text{current\\ }\\allowbreak \\text{in\\ }\\allowbreak \\text{primary\\ }\\allowbreak \\text{coil} = \\text{potential\\ }\\allowbreak \\text{difference\\ }\\allowbreak \\text{across\\ }\\allowbreak \\text{secondary\\ }\\allowbreak \\text{coil} \\times \\text{current\\ }\\allowbreak \\text{in\\ }\\allowbreak \\text{secondary\\ }\\allowbreak \\text{coil}',
    symbols: 'V_p I_p = V_s I_s',
    higherTier: true,
    searchString:
      'higher potential difference across primary coil x current in primary coil = potential difference across secondary coil x current in secondary coil',
  },
];

export const edexcelJointEquations: EquationRow[] = [
  {
    words: '\\text{distance travelled} = \\text{average speed} \\times \\text{time}',
    searchString: 'distance travelled = average speed x time',
  },
  {
    words: '\\text{acceleration} = \\text{change in velocity} \\div \\text{time taken}',
    symbols: 'a = \\dfrac{(v - u)}{t}',
    searchString: 'acceleration = change in velocity / time taken',
  },
  {
    words: '\\text{force} = \\text{mass} \\times \\text{acceleration}',
    symbols: 'F = m \\times a',
    searchString: 'force = mass x acceleration',
  },
  {
    words: '\\text{weight} = \\text{mass} \\times \\text{gravitational field strength}',
    symbols: 'W = m \\times g',
    searchString: 'weight = mass x gravitational field strength',
  },
  {
    words: '\\text{momentum} = \\text{mass} \\times \\text{velocity}',
    symbols: 'p = m \\times v',
    higherTier: true,
    searchString: 'higher momentum = mass x velocity',
  },
  {
    words:
      '\\text{change\\ }\\allowbreak \\text{in\\ }\\allowbreak \\text{gravitational\\ }\\allowbreak \\text{potential\\ }\\allowbreak \\text{energy} = \\text{mass\\ } \\times \\text{gravitational\\ }\\allowbreak \\text{field\\ }\\allowbreak \\text{strength\\ }\\allowbreak \\times \\text{change\\ }\\allowbreak \\text{in\\ }\\allowbreak \\text{vertical\\ }\\allowbreak \\text{height}\\allowbreak',
    symbols: '\\Delta GPE = m \\times g \\times \\Delta h',
    searchString:
      'change in gravitational potential energy = mass x gravitational field strength x change in vertical height',
  },
  {
    words: '\\text{kinetic energy} = 1 / 2 \\times \\text{mass} \\times (\\text{speed})^2',
    symbols: 'KE = \\dfrac{1}{2} \\times m \\times v^2',
    searchString: 'kinetic energy = 1/2 0.5 x mass x speed 2',
  },
  {
    words:
      '\\text{efficiency} = \\htmlClass{katex-possible-wrapping-fraction}{\\dfrac{\\text{(useful energy transferred by the device)}}{\\text{(total energy supplied to the device)}}}',
  },
  {
    words: '\\text{wave speed} = \\text{frequency} \\times \\text{wavelength}',
    symbols: 'v = f \\times \\lambda',
    searchString: 'wave speed = frequency x wavelength',
  },
  {
    words: '\\text{wave speed} = \\text{distance}\\div\\text{time}',
    symbols: 'v = \\dfrac{x}{t}',
    searchString: 'wave speed = distance / time',
  },
  {
    words:
      '\\text{work done} = \\text{force} \\times \\text{distance\\ }\\allowbreak \\text{moved\\ }\\allowbreak \\text{in\\ }\\allowbreak \\text{the\\ }\\allowbreak \\text{direction\\ }\\allowbreak \\text{of\\ }\\allowbreak \\text{the\\ }\\allowbreak \\text{force}\\allowbreak',
    symbols: 'E = F \\times d',
    searchString: 'work done = force x distance moved in the direction of the force',
  },
  {
    words: '\\text{power} = \\text{work done} \\div \\text{time taken}',
    symbols: 'P = \\dfrac{E}{t}',
    searchString: 'power = work done / time taken',
  },
  {
    words: '\\text{energy transferred} = \\text{charge moved} \\times \\text{potential difference}',
    symbols: 'E = Q \\times V',
    searchString: 'energy transferred = charge moved x potential difference',
  },
  {
    words: '\\text{charge} = \\text{current} \\times \\text{time}',
    symbols: 'Q = I \\times t',
    searchString: 'charge = current x time',
  },
  {
    words: '\\text{potential difference} = \\text{current} \\times \\text{resistance}',
    symbols: 'V = I \\times R',
    searchString: 'potential difference = current x resistance',
  },
  {
    words: '\\text{power} = \\text{energy transferred}\\div \\text{time taken}',
    symbols: 'P = \\dfrac{E}{t}',
    searchString: 'power = energy transferred / time taken',
  },
  {
    words: '\\text{electrical power} = \\text{current} \\times \\text{potential difference}',
    symbols: 'P = I \\times V',
    searchString: 'electrical power = current x potential difference',
  },
  {
    words: '\\text{electrical power} = (\\text{current})^2 \\times \\text{resistance}',
    symbols: 'P = I^2 \\times R',
    searchString: 'electrical power = current 2 x resistance',
  },

  {
    words: '\\text{density} = \\text{mass}\\div\\text{volume}',
    symbols: '\\rho = \\dfrac{m}{V}',
    searchString: 'density = mass / volume',
  },
  {
    words: '\\text{force exerted on a spring} = \\text{spring constant} \\times \\text{extension}',
    symbols: 'F = k \\times x',
    searchString: 'force exerted on a spring = spring constant x extension',
  },
  {
    words:
      '(\\text{final velocity})^2 - (\\text{initial velocity})^2 = 2 \\times \\text{acceleration} \\times \\text{distance}',
    symbols: 'v^2 - u^2 = 2 \\times a \\times x',
    searchString: 'final velocity 2 - initial velocity 2 = 2 x acceleration x distance',
  },
  {
    words: '\\text{force} = \\text{change in momentum}\\div \\text{time}',
    symbols: 'F = \\dfrac{(mv - mu)}{t}',
    higherTier: true,
    searchString: 'higher force = change in momentum / time',
  },
  {
    words:
      '\\text{energy transferred} = \\text{current} \\times \\text{potential difference} \\times \\text{time}',
    symbols: 'E = I \\times V \\times t',
    searchString: 'energy transferred = current x potential difference x time',
  },

  {
    words:
      '\\text{force\\ }\\allowbreak \\text{on\\ }\\allowbreak \\text{a\\ }\\allowbreak \\text{conductor\\ }\\allowbreak \\text{at\\ }\\allowbreak \\text{right\\ }\\allowbreak \\text{angles\\ }\\allowbreak \\text{to\\ }\\allowbreak \\text{a\\ }\\allowbreak \\text{magnetic\\ }\\allowbreak \\text{field\\ }\\allowbreak \\text{carrying\\ }\\allowbreak \\text{a\\ }\\allowbreak \\text{current}\\allowbreak = \\text{magnetic\\ }\\allowbreak \\text{flux\\ }\\allowbreak \\text{density}\\allowbreak \\times \\text{current} \\times \\text{length}',
    symbols: 'F = B \\times I \\times l',
    higherTier: true,
    searchString:
      'higher force on a conductor at right angles to a magnetic field carrying a current = magnetic flux density x current x length',
  },
  {
    words:
      '\\text{For\\ }\\allowbreak \\text{transformers\\ }\\allowbreak \\text{with\\ }\\allowbreak \\text{100\\%\\ }\\allowbreak \\text{efficiency, \\ }\\allowbreak  \\text{potential\\ }\\allowbreak \\text{difference\\ }\\allowbreak \\text{across\\ }\\allowbreak \\text{primary\\ }\\allowbreak \\text{coil}\\allowbreak \\times \\text{current\\ }\\allowbreak \\text{in\\ }\\allowbreak \\text{primary\\ }\\allowbreak \\text{coil}\\allowbreak = \\text{potential\\ }\\allowbreak \\text{difference\\ }\\allowbreak \\text{across\\ }\\allowbreak \\text{secondary\\ }\\allowbreak \\text{coil}\\allowbreak \\times \\text{current\\ }\\allowbreak \\text{in\\ }\\allowbreak \\text{secondary\\ }\\allowbreak \\text{coil}\\allowbreak',
    symbols: 'V_p \\times I_p = V_s \\times I_s',
    searchString:
      'For transformers with 100% efficiency, potential difference across primary coil x current in primary coil = potential difference across secondary coil x current in secondary coil',
  },
  {
    words:
      '\\text{change in thermal energy} = \\text{mass} \\times \\text{specific heat capacity} \\times \\text{change in temperature}',
    symbols: '\\Delta Q = m \\times c \\times \\Delta \\theta',
    searchString:
      'change in thermal energy = mass x specific heat capacity x change in temperature',
  },
  {
    words:
      '\\text{thermal\\ }\\allowbreak \\text{energy\\ }\\allowbreak \\text{for\\ }\\allowbreak \\text{a\\ }\\allowbreak \\text{change\\ }\\allowbreak \\text{of\\ }\\allowbreak \\text{state} = \\text{mass} \\times \\text{specific\\ }\\allowbreak \\text{latent\\ }\\allowbreak \\text{heat}',
    symbols: 'Q = m \\times L',
    searchString: 'thermal energy for a change of state = mass x specific latent heat',
  },
  {
    words:
      '\\text{energy transferred in stretching} = 0.5 \\times \\text{spring constant} \\times (\\text{extension})^2',
    symbols: 'E = \\dfrac{1}{2} \\times k \\times x^2',
    searchString: 'energy transferred in stretching = 1/2 0.5 x spring constant x extension 2',
  },
];

export const edexcelPhysEquations: EquationRow[] = [
  {
    words:
      '\\text{moment\\ }\\allowbreak \\text{of\\ }\\allowbreak \\text{a\\ }\\allowbreak \\text{force}\\allowbreak = \\text{force} \\times \\text{distance\\ }\\allowbreak \\text{normal\\ }\\allowbreak \\text{to\\ }\\allowbreak \\text{the\\ }\\allowbreak \\text{direction\\ }\\allowbreak \\text{of\\ }\\allowbreak \\text{the\\ }\\allowbreak \\text{force}\\allowbreak',
    searchString: 'moment of a force = force x distance normal to the direction of the force',
  },
  {
    words: '\\text{pressure} = \\text{force normal to surface} \\div \\text{area of surface}',
    symbols: 'P = \\dfrac{F}{A}',
    searchString: 'pressure = force normal to surface / area of surface',
  },
  {
    words:
      '\\htmlClass{katex-possible-wrapping-fraction}{\\dfrac{\\text{potential difference across primary coil}}{\\text{potential difference across secondary coil}}}\\allowbreak = \\htmlClass{katex-possible-wrapping-fraction}{\\dfrac{\\text{number of turns in primary coil}}{\\text{number of turns in secondary coil}}}',
    symbols: '\\dfrac{V_p}{V_s} = \\dfrac{N_p}{N_s}',
    higherTier: true,
    searchString:
      'higher potential difference across primary coil / potential difference across secondary coil = number of turns in primary coil / number of turns in secondary coil',
  },
  {
    words:
      '\\text{to\\ }\\allowbreak \\text{calculate\\ }\\allowbreak \\text{pressure\\ }\\allowbreak \\text{or\\ }\\allowbreak \\text{volume\\ }\\allowbreak \\text{for\\ }\\allowbreak \\text{gases\\ }\\allowbreak \\text{of\\ }\\allowbreak \\text{fixed\\ }\\allowbreak \\text{mass\\ }\\allowbreak \\text{at\\ }\\allowbreak \\text{constant\\ }\\allowbreak \\text{temperature\\ }\\allowbreak',
    symbols: 'P_1 \\times V_1 = P_2 \\times V_2',
    searchString: 'to calculate pressure or volume for gases of fixed mass at constant temperature',
  },
  {
    words:
      '\\text{pressure\\ }\\allowbreak \\text{due\\ }\\allowbreak \\text{to\\ }\\allowbreak \\text{a\\ }\\allowbreak \\text{column\\ }\\allowbreak \\text{of\\ }\\allowbreak \\text{liquid} = \\text{height\\ }\\allowbreak \\text{of\\ }\\allowbreak \\text{column} \\times \\text{density\\ }\\allowbreak \\text{of\\ }\\allowbreak \\text{liquid} \\times \\text{gravitational\\ }\\allowbreak \\text{field\\ }\\allowbreak \\text{strength}',
    symbols: 'P = h \\times \\rho \\times g',
    higherTier: true,
    searchString:
      'higher pressure due to a column of liquid = height of column x density of liquid x gravitational field strength',
  },
];
