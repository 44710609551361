import { SchoolStaffMember } from '@sparx/api/apis/sparx/school/staff/schoolstaff/v2/schoolstaff';
import { FieldMask } from '@sparx/api/google/protobuf/field_mask';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';

import { CommonParamsContext } from '../../context';
import { listSchoolStaffMembers } from '../list-school-staff-members';
import { useDeleteMutationFn, useUpsertMutationFn } from './mutation-fns';

export const useSafeDeleteStaff = () => {
  const queryClient = useQueryClient();
  const { product } = useContext(CommonParamsContext);
  const upsertMutationFn = useUpsertMutationFn();
  const deleteMutationFn = useDeleteMutationFn();

  return useMutation({
    mutationFn: async (data: SchoolStaffMember): Promise<SchoolStaffMember> => {
      if (data.name === '') {
        throw new Error('Name is required for delete operation');
      }

      // Shallow copy the input and update the roles
      data = { ...data };
      data.roles = data.roles.filter(r => r.product !== product);

      if (data.roles.length === 0) {
        return deleteMutationFn({ name: data.name });
      }

      const mask = FieldMask.create({ paths: ['roles'] });
      return upsertMutationFn({ updateMask: mask, staff: data });
    },
    onSuccess: async () => {
      // Invalidate all school staff queries
      return queryClient.invalidateQueries([listSchoolStaffMembers.keyPrefix[0]]);
    },
  });
};
