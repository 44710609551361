import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { format } from 'date-fns';

import { timestampToDate } from '../utils/dates';

export const ReceivedTime = ({
  timestamp,
  includeTime,
}: {
  timestamp: Timestamp;
  includeTime?: boolean;
}) => {
  const received = timestampToDate(timestamp);
  return (
    <>
      {includeTime && (
        <>
          <strong>{format(received, 'HH:mm')}</strong> on{' '}
        </>
      )}
      <strong>{format(received, 'dd/MM/yyyy')}</strong>
    </>
  );
};
