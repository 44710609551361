import {
  CreateSchoolStaffMemberRequest,
  DeleteSchoolStaffMemberRequest,
  SchoolStaffMember,
  UpdateSchoolStaffMemberRequest,
} from '@sparx/api/apis/sparx/school/staff/schoolstaff/v2/schoolstaff';
import { FieldMask } from '@sparx/api/google/protobuf/field_mask';
import { useContext } from 'react';

import { CommonParamsContext } from '../../context';
import { useSchoolStaffClient } from '../client';

/**
 * Returns a wrapper around the `update` and `create` server RPC methods, which
 * calls the appropriate one depending on whether or not the staff member has a resource name.
 */
export const useUpsertMutationFn = () => {
  const staffClient = useSchoolStaffClient();
  const { schoolName, product } = useContext(CommonParamsContext);

  return async (update: { staff: SchoolStaffMember; updateMask: FieldMask }) => {
    if (update.staff.name) {
      return await staffClient.updateSchoolStaffMember(
        UpdateSchoolStaffMemberRequest.create({
          staffMember: { ...update.staff, school: schoolName },
          updateMask: update.updateMask,
          product,
        }),
      ).response;
    }
    return await staffClient.createSchoolStaffMember(
      CreateSchoolStaffMemberRequest.create({
        staffMember: { ...update.staff, school: schoolName },
        product,
      }),
    ).response;
  };
};

/**
 * Returns a function that calls the `delete` server RPC method.
 */
export const useDeleteMutationFn = () => {
  const staffClient = useSchoolStaffClient();
  const { schoolName } = useContext(CommonParamsContext);

  return async (req: { name: string }) =>
    staffClient.deleteSchoolStaffMember(
      DeleteSchoolStaffMemberRequest.create({ ...req, school: schoolName }),
    ).response;
};
