import { Product } from '@sparx/api/apis/sparx/types/product';

export const colourProp = (product: Product) => {
  switch (product) {
    // TP2, TP3 and Science all customise their buttons directly with CSS variables
    case Product.SPARX_MATHS:
    case Product.SPARX_SCIENCE:
      return 'custom' as const;
    // Reader I guess... doesn't?
    default:
      return undefined;
  }
};
