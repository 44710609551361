import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const flashcard = defineStyle({
  color: 'white',
  backgroundImage: 'linear-gradient(90deg, #E97B2A 0%, #D34A59 50%, #E97B2A 100%)',
  backgroundSize: '200% auto',
  transition: 'background-position 0.2s',
  _hover: {
    backgroundPosition: '98% 0',
  },
  _active: {
    backgroundImage: 'linear-gradient(90deg, #E97B2A 0%, #D34A59 50%, #E97B2A 100%)',
  },
});

export const buttonTheme = defineStyleConfig({
  variants: { flashcard },
});
