import {
  Box,
  Button,
  chakra,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { faCircleXmark, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { KatexMath } from '@sparx/text-with-maths';
import { useBreakpointValue } from 'components/chakraExports';
import { useClientEvent } from 'components/ClientEventProvider';
import { HelpMaterialOption } from 'components/helpmaterials';
import {
  aqaEquations,
  edexcelJointEquations,
  edexcelPhysEquations,
  EquationRow,
} from 'components/helpmaterials/equationsheet/equations';
import MiniSearch from 'minisearch';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';

import styles from './EquationSheet.module.css';
import fx from './fx.svg';

interface EquationsSheetModalProps {
  type: HelpMaterialOption.AQA_EQUATION_SHEET | HelpMaterialOption.EDEXCEL_EQUATION_SHEET;
  eventContext: { activity: string; equationSheetType: string };
}

export const EquationsSheetModal = ({ type, eventContext }: EquationsSheetModalProps) => {
  const { sendEvent } = useClientEvent();
  const { isOpen, onOpen, onClose } = useDisclosure({
    onOpen: () => sendEvent({ category: 'equation_sheet', action: 'open' }, eventContext),
    onClose: () => sendEvent({ category: 'equation_sheet', action: 'close' }, eventContext),
  });

  const [searchText, setSearchText] = useState('');
  const searching = searchText.length > 0;
  const miniSearch = useMemo(() => {
    const s = new MiniSearch({
      fields: ['searchString', 'symbols'],
      idField: 'words',
      storeFields: ['words', 'symbols', 'higherTier'],
      searchOptions: {
        prefix: true,
        fuzzy: 0.2,
        combineWith: 'AND',
        boost: { symbols: 2 },
      },
      processTerm: term =>
        new Set(['the', 'of', 'in', 'for', 'frac', 'text']).has(term) ? null : term.toLowerCase(),
    });
    s.addAll(
      type === HelpMaterialOption.AQA_EQUATION_SHEET
        ? aqaEquations
        : [...edexcelJointEquations, ...edexcelPhysEquations],
    );
    return s;
  }, [type]);

  const rows = useMemo(() => {
    let rows: (EquationRow | InfoRow)[] = [];
    if (searching) {
      const results = miniSearch.search(searchText);
      rows = results.map(({ words, symbols, higherTier }) => ({
        words,
        symbols,
        higherTier,
      }));
    } else {
      rows = makeDefaultRows(type);
    }

    return rows;
  }, [miniSearch, searchText, searching, type]);

  const buttonIcon = useBreakpointValue({
    base: <Image src={fx} width={'20px'} minWidth={'20px'} />,
    sm: undefined,
    md: <Image src={fx} width={'20px'} minW={'20px'} mr={2} />,
  });
  const buttonText = useBreakpointValue({ base: '', sm: <Text>Equations sheet</Text> });

  const searchRef = useRef<HTMLInputElement>(null);

  const [scrollContainer, setScrollContainer] = useState<HTMLElement | null>(null);
  const scrollDistanceRef = useRef(0);
  useEffect(() => {
    if (scrollContainer) {
      const aborter = new AbortController();
      scrollContainer.addEventListener(
        'scroll',
        () => {
          if (scrollContainer) {
            scrollDistanceRef.current = scrollContainer?.scrollTop;
          }
        },
        { signal: aborter.signal },
      );
      return () => aborter.abort();
    }
  }, [scrollContainer]);

  return (
    <>
      <Button
        onClick={onOpen}
        variant="outline"
        colorScheme="buttonTeal"
        display={'flex'}
        padding={[0, 2]}
        height={[8, 10]}
      >
        {buttonIcon}
        {buttonText}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} allowPinchZoom={true} isCentered={true}>
        <ModalOverlay />
        <ModalContent
          padding={[1, 1, 2]}
          height={[
            '100%',
            'calc(100% - var(--chakra-space-5))',
            'calc(100% - var(--chakra-space-10))',
          ]}
          width={[
            '100%',
            'calc(100% - var(--chakra-space-5))',
            'calc(100% - var(--chakra-space-10))',
          ]}
          maxHeight={'1750px'}
          maxWidth={'2000px'}
          overflow={'hidden'}
          borderRadius={[0, 6]}
        >
          <ModalHeader pb={0} pt={2} px={[1, 2, 3]}>
            Equations sheet
          </ModalHeader>
          <ModalCloseButton />
          <Stack px={[1, 2, 3]} py={[1, 2, 3]} gap={[1, 2, 3]} className={styles.ModalBody}>
            <InputGroup onClick={() => searchRef.current?.focus()}>
              <InputLeftElement
                onClick={() => {
                  searchRef.current?.focus();
                  if (!searching) {
                    return;
                  }
                  setSearchText('');
                }}
                cursor={'pointer'}
                _hover={{ opacity: 0.75 }}
              >
                <FontAwesomeIcon icon={searching ? faCircleXmark : faSearch} />
              </InputLeftElement>
              <Input
                ref={searchRef}
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
                autoFocus
                placeholder="Search for an equation..."
              />
            </InputGroup>
          </Stack>
          <ModalBody
            className={styles.ModalBody}
            px={[1, 2, 3]}
            pb={3}
            pt={0}
            width="100%"
            overflow="scroll"
            as={ScrollContainer}
            hideScrollbars={true}
            innerRef={(e: HTMLElement | null) => {
              setScrollContainer(e);
              e?.scrollTo({ top: scrollDistanceRef.current, behavior: 'instant' });
            }}
          >
            <Table className={styles.EquationsTable}>
              <Tbody>
                {rows.map(row => {
                  if (isInfoRow(row)) {
                    return <InfoDisplay info={row.info} key={row.key} />;
                  }
                  return <EquationDisplay key={row.words} {...row} />;
                })}
              </Tbody>
            </Table>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

type InfoRow = { info: ReactNode; key: string };

const isInfoRow = (row: EquationRow | InfoRow): row is InfoRow => {
  return (row as InfoRow).info !== undefined;
};

const InfoDisplay = (row: InfoRow) => (
  <Tr>
    <Td borderWidth={0} p={0} colSpan={2}>
      {row.info}
    </Td>
  </Tr>
);

const EquationDisplay = (row: EquationRow) => {
  const numColumns = useBreakpointValue({ base: 1, md: 2 });

  let rowContent = null;
  switch (numColumns) {
    case 1:
      rowContent = (
        <Td border={'1px solid black'} p={0}>
          <Box display={'flex'} flexDir={'column'}>
            <EquationContainer gap={0}>
              {row.higherTier && <HigherTierText>Higher Tier</HigherTierText>}
              <HTMLKatex text={row.words} />
            </EquationContainer>
            {row.symbols && (
              <EquationContainer borderTop={'1px solid'} borderTopColor={'gray.300'}>
                <KatexMath text={row.symbols} />
              </EquationContainer>
            )}
          </Box>
        </Td>
      );
      break;
    case 2:
      rowContent = (
        <>
          <Td borderWidth={0} p={0} border={'1px solid black'} borderRightColor={'gray.300'}>
            <EquationContainer flexDirection="row">
              {row.higherTier && <HigherTierText alignSelf={'center'}>Higher Tier</HigherTierText>}
              <HTMLKatex text={row.words} />
            </EquationContainer>
          </Td>
          <Td borderWidth={0} p={0} border={'1px solid black'}>
            {row.symbols && (
              <EquationContainer width={'max-content'}>
                <KatexMath text={row.symbols} />
              </EquationContainer>
            )}
          </Td>
        </>
      );
      break;
  }

  return (
    <Tr
      _hover={{
        bg: 'gray.100',
      }}
      position={'relative'}
    >
      {rowContent}
    </Tr>
  );
};

const EquationContainer = chakra(Stack, {
  baseStyle: {
    p: [1, 2, 2],
  },
});

const HigherTierText = chakra(Text, {
  baseStyle: {
    background: 'cyan.100',
    width: 'max-content',
    px: 2,
    borderRadius: 4,
    whiteSpace: 'nowrap',
    height: '100%',
    lineHeight: 1.5,
  },
});

const HTMLKatex = ({ text }: { text: string }) => {
  return (
    <KatexMath
      text={text}
      options={{ strict: false, trust: ({ command }) => command === '\\htmlClass' }}
    />
  );
};

const makeDefaultRows = (
  type: HelpMaterialOption.AQA_EQUATION_SHEET | HelpMaterialOption.EDEXCEL_EQUATION_SHEET,
) => {
  return type === HelpMaterialOption.AQA_EQUATION_SHEET
    ? aqaEquations
    : [
        {
          info: (
            <Text mb={4}>
              If you&apos;re taking{' '}
              <Text as="span" whiteSpace={'nowrap'} fontWeight={'bold'}>
                GCSE (9-1) Combined Science
              </Text>{' '}
              or{' '}
              <Text as="span" whiteSpace={'nowrap'} fontWeight={'bold'}>
                GCSE (9-1) Physics
              </Text>
              , you will need these equations:
            </Text>
          ),
          key: 'combinedScience',
        },
        ...edexcelJointEquations,
        {
          info: (
            <Text my={4}>
              If you&apos;re taking{' '}
              <Text as="span" whiteSpace={'nowrap'} fontWeight={'bold'}>
                GCSE (9-1) Physics
              </Text>
              , you also need these extra equations:
            </Text>
          ),
          key: 'physics',
        },
        ...edexcelPhysEquations,
      ];
};
