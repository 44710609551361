import katex, { KatexOptions } from 'katex';

// Import katex CSS
import 'katex/dist/katex.min.css';
import 'katex/dist/contrib/mhchem.js';

/**
 * This file is imported from CloudExperiments and converted to typescript.
 */

const rAmp = /&/g;
const rLt = /</g;
const rApos = /'/g;
const rQuot = /"/g;
const hChars = /[&<>"']/;

const htmlEscape = (str: any) => {
  if (str === null) {
    return str;
  }

  if (typeof str !== 'string') {
    str = String(str);
  }

  if (hChars.test(String(str))) {
    return str
      .replace(rAmp, '&amp;')
      .replace(rLt, '&lt;')
      .replace(rApos, '&apos;')
      .replace(rQuot, '&quot;');
  } else {
    return str;
  }
};

const backslashNCommands = /\\n(?![a-zA-Z])/g;
const nonBreakingSpacePattern = /~/g;

// Mapping of Latex tags with HTML tags to replace them with. (Closing brace
// is also handled as a special case)
const LATEX_TAGS: Record<string, string> = {
  '\\textbf{': 'b',
  '\\textit{': 'i',
  '}': '',
};

// Maths specific patterns - macros
const vectorPattern =
  /\\vector{((?:[^}$]|\\$[^$]*\\$)*)}{((?:[^}$]|\\$[^$]*\\$)*)}/g;
const degreesPattern = /\\degrees/g;
const numberCommaPattern = /(\d,)(?=\d\d\d)/g;
const unescapedPercentPattern = /([^\\]|^)%/g;
const ungroupedQuestionMarkPattern = /([^{?]|^)([?]+)([^}?]|$)/g;
const uscorePattern = /\\uscore{(\d+)}/g;

const preprocessText = (text: string, ignoreNewLines?: boolean) => {
  text = text.replace(nonBreakingSpacePattern, '\xa0');
  if (!ignoreNewLines) {
    text = text.replace(backslashNCommands, '<br/>');
  }
  return text;
};

const preprocessMath = (math: string) => {
  if (math === '') {
    return '';
  }
  // We wrap all Maths in {} to prevent new Katex wrapping
  return (
    '{' +
    math
      .replace(vectorPattern, '{$1 \\choose $2}')
      .replace(degreesPattern, '^\\circ')
      .replace(numberCommaPattern, '$1\\!')
      .replace(unescapedPercentPattern, '$1\\%')
      .replace(ungroupedQuestionMarkPattern, '$1{$2}$3')
      .replace(uscorePattern, '\\rule{$1em}{0.03em}')
      .replace(/\\pound/g, '\\pounds')
      .replace(/\\euro/g, '€')
      .replace(/\\gap/g, '\\text{\\textunderscore}') +
    '}'
  );
};

const renderMathToString = (math: string, options?: KatexOptions) => {
  return katex.renderToString(preprocessMath(math), options);
};

export const renderMixedTextToString = (
  text: string,
  suppressWarnings?: boolean,
) => {
  // A stack to contain html tags in the text elements that we have opened,
  // but not closed yet
  const tagStack: string[] = [];

  const bits = text.match(/\$|(?:\\.|[^$])+/g);
  if (bits === null) {
    return '';
  }
  let isMath = false;
  for (let i = 0; i < bits.length; i++) {
    let bit = bits[i];
    if (bit === '$') {
      isMath = !isMath;
      bits[i] = '';
    } else if (isMath) {
      try {
        bits[i] = renderMathToString(bit);
      } catch (exc) {
        bits[i] = '<code class="invalid-math">' + htmlEscape(bit) + '</code>';
        if (!suppressWarnings) {
          console.log(exc);
          console.warn('Invalid Math', bit);
        }
      }
    } else {
      bit = htmlEscape(bit.replace('\\$', '$'));

      // Replace Latex tags such as \textbf{...} and \textit{...} with
      // HTML tags such as <b>...</b> and <i>...</i>
      bit = replaceLatexTagsWithHtmlTags(bit, tagStack);
      bits[i] = bit;
    }
  }
  return preprocessText(bits.join(''));
};

/**
 * Replaces Latex sections of a bit of a string with equivalent HTML elements.
 * At present \textbf{ ... } is replaced with <b> ... </b> and |textit{ ... }
 * is replaced with <i> ... </i>
 * @param bit The bit of the string to be modified
 * @param tagStack A stack of the open tags in the string (to allow a tag
 * opened in one bit to be closed in a following bit, preserving the
 * opening/closing order)
 * @returns {*} The modified bit of a string
 */
const replaceLatexTagsWithHtmlTags = (bit: string, tagStack: string[]) => {
  let ret = '';
  let input = bit;
  let tag;
  let firstTag;
  let firstTagIdx;
  let index;
  let lastOpenedTag;

  while (input) {
    firstTagIdx = input.length;
    firstTag = null;
    index = -1;

    for (tag in LATEX_TAGS) {
      //eslint-disable-line guard-for-in
      index = input.indexOf(tag);
      if (index !== -1 && index < firstTagIdx) {
        firstTagIdx = index;
        firstTag = tag;
      }
    }
    if (firstTag) {
      // If we have a tag it must be length 1, therefore input must shrink
      ret += input.substring(0, firstTagIdx);
      input = input.substring(firstTagIdx + firstTag.length, input.length);
      if (firstTag === '}') {
        lastOpenedTag = tagStack.pop();
        if (lastOpenedTag) {
          ret += '</' + lastOpenedTag + '>';
        } else {
          ret += '}';
        }
      } else {
        ret += '<' + LATEX_TAGS[firstTag] + '>';
        tagStack.push(LATEX_TAGS[firstTag]);
      }
    } else {
      // Otherwise we set input to empty, allowing the loop to break
      ret += input;
      input = '';
    }
  }

  return ret;
};

export const renderMathInElement = (
  elem: Node,
  ignoreNewLines?: boolean,
  suppressWarnings?: boolean,
) => {
  const ignoredTags = [
    'script',
    'noscript',
    'style',
    'textarea',
    'pre',
    'code',
  ];
  for (let i = 0; i < elem.childNodes.length; i++) {
    const childNode = elem.childNodes[i];
    if (childNode.nodeType === 3) {
      // Text node
      const text = childNode.textContent;
      console.log('rendering text:', text);
      const math = renderMixedTextToString(text || '', suppressWarnings);
      // Make a temporary span to render the content
      const s = document.createElement('span');
      s.innerHTML = math;
      // Copy the spans children to make a document fragment
      const frag = document.createDocumentFragment();
      while (s.childNodes.length) {
        frag.appendChild(s.childNodes[0]);
      }
      // replace the text node with the document fragment
      i += frag.childNodes.length - 1;
      elem.replaceChild(frag, childNode);
    } else if (childNode.nodeType === 1) {
      // Element node
      const shouldRender =
        ignoredTags.indexOf(childNode.nodeName.toLowerCase()) === -1;

      if (shouldRender) {
        renderMathInElement(childNode, ignoreNewLines, suppressWarnings);
      }
    }
    // Otherwise, it's something else, and ignore it.
  }
};

const mathsPattern = /\$[^$]+\$/g;

/**
 * Removes any katex blocks from the text and returns the result along with
 * a boolean indicating if any maths was removed.
 */
export const removeMathsFromText = (text: string) => {
  if (text.search(mathsPattern) >= 0) {
    return {
      result: text.replace(mathsPattern, ' '),
      found: true,
    };
  }
  return { result: text, found: false };
};

export const TextWithMaths = ({
  text,
  className,
}: {
  text: string;
  className?: string;
}) => (
  <span
    className={className}
    dangerouslySetInnerHTML={{ __html: renderMixedTextToString(text, false) }}
  />
);

export const KatexMath = ({
  text,
  options,
}: {
  text: string;
  options?: KatexOptions;
}) => {
  const html = katex.renderToString(text, { throwOnError: false, ...options });
  return <span dangerouslySetInnerHTML={{ __html: html }} />;
};
