import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';

import styles from './ExternalLink.module.css';

export interface ExternalLinkProps {
  children: ReactNode;
  href: string;
}

export const ExternalLink = ({ children, href }: ExternalLinkProps) => (
  <a
    className={styles.Link}
    href={href}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
    <span className={styles.LinkIcon}>
      <FontAwesomeIcon icon={faExternalLink} size="sm" />
    </span>
  </a>
);
