import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { getSchool } from '@sparx/query/schools-service';
import { StaffContextProvider } from '@sparx/staff-manager';
import { useSession } from 'api/sessions';
import { InfoTooltip } from 'components/tooltip/InfoTooltip';
import { PropsWithChildren } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { articleKeyContacts } from 'utils/knowledgeBaseArticles';

export const SuspenseStaffContextProvider = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const { data: user } = useSession({ suspense: true });
  const { data: school } = getSchool.useSuspenseQuery();

  return (
    <StaffContextProvider
      value={{
        school,
        currentUserID: user?.sparxUserId || user?.userId,
        showTrainingProgress: true,
        knowledgeBaseArticleURL: articleKeyContacts,

        onSelectStaff: (name: string) => navigate(`/teacher/${name}/details`),
        onStaffDeleted: toastMessage => navigate(`/teacher/staff`, { state: { toastMessage } }),
        onStaffCreated: toastMessage => navigate(`/teacher/staff`, { state: { toastMessage } }),
        getInfoTooltip: text => <InfoTooltip text={text} />,
        onSwitchTab: idx => navigate(`/teacher/staff${idx === 1 ? '/keycontacts' : ''}`),
        useNavigationMessage: () => {
          const location = useLocation();
          return location.state?.toastMessage;
        },

        styles: {
          linkColor: 'buttonTeal.600',
          editIconColor: 'teal.600',
          headerBackgroundColor: 'blue.800',
          tableSortOptions: {
            upIcon: faCaretUp,
            downIcon: faCaretDown,
            hoverStyle: { background: 'blue.900', cursor: 'pointer' },
          },
        },
      }}
    >
      {children}
    </StaffContextProvider>
  );
};
