import { SubscriptionContextProvider } from '@sparx/subscription-manager';
import { useClientEvent } from 'components/ClientEventProvider';
import { InfoTooltip } from 'components/tooltip/InfoTooltip';
import { PropsWithChildren } from 'react';

export const SubscriptionContext = ({ children }: PropsWithChildren) => {
  const { sendEvent } = useClientEvent();

  return (
    <SubscriptionContextProvider
      value={{
        sendClientEvent: sendEvent,
        getInfoTooltip: text => <InfoTooltip ml={2} text={text} />,
      }}
    >
      {children}
    </SubscriptionContextProvider>
  );
};
