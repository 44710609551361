import { Task } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { usePackage } from 'api/packages';
import { useMemo } from 'react';
import { isTaskFlashcards } from 'utils/flashcards';

export interface FlashCardCounts {
  total: number;
  newCards: number;
  reviewCards: number;
  confident: number;
  learning: number;
}

export const useFlashCardCounts = (task?: Task) => {
  const { data: pkg } = usePackage(task?.name.split('/')[1] || '');
  return useMemo(() => {
    let packageCounts: FlashCardCounts | null = null;
    let taskCounts: FlashCardCounts | null = null;

    const flashcardTasks = pkg?.package?.contents?.tasks?.filter(t => isTaskFlashcards(t));

    for (const t of flashcardTasks || []) {
      const counts = getTaskCounts(t);
      if (counts) {
        if (t.name === task?.name) {
          taskCounts = counts;
        }
        if (packageCounts === null) {
          packageCounts = { ...counts };
        } else {
          packageCounts.total += counts.total;
          packageCounts.newCards += counts.newCards;
          packageCounts.reviewCards += counts.reviewCards;
          packageCounts.confident += counts.confident;
          packageCounts.learning += counts.learning;
        }
      }
    }

    // TODO: do final counts differently

    return {
      packageCounts,
      taskCounts,
    };
  }, [pkg?.package?.contents?.tasks, task?.name]);
};

const getTaskCounts = (task: Task) => {
  const contents = task?.contents?.taskItems?.[0].contents?.contents;
  if (!contents || contents.oneofKind !== 'flashcards') {
    return null;
  }
  const learning = contents.flashcards.initialStates?.learning || 0;
  const review = contents.flashcards.initialStates?.review || 0;
  const total = learning + review;

  const correct = task.state?.completion?.progress?.['C'] || 0;
  const cft = task.state?.completion?.progress?.['CFT'] || 0;
  return {
    total,
    newCards: learning,
    reviewCards: review,
    confident: cft,
    learning: correct - cft,
  };
};
