import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faBookOpen,
  faCamera,
  faChartLine,
  faCircleNotch,
  faListSquares,
  faWandMagic,
} from '@fortawesome/free-solid-svg-icons';
import {
  CreateLessonActivityResponse,
  LessonActivityBlueprint,
} from '@sparx/api/apis/sparx/science/lessons/v1/lessons';
import {
  TaskItem_Contents_Skill,
  TaskItem_Contents_Skill_Section,
} from '@sparx/api/apis/sparx/science/packages/v1/package';
import { StaticAssignment_Task } from '@sparx/api/apis/sparx/science/packages/v1/planner';
import { Subject } from '@sparx/api/apis/sparx/science/v1/subject';
import React from 'react';
import { CreateCustomSkillsActivity } from 'views/lessons/createactivity/CreateCustomSkillsActivity';
import { CreateFollowUpFiveActivity } from 'views/lessons/createactivity/CreateFollowUpFiveActivity';
import { CreateMathsContentActivity } from 'views/lessons/createactivity/CreateMathsContentActivity';
import { CreatePhotoActivity } from 'views/lessons/createactivity/CreatePhotoActivity';
import { CreateScienceContentActivity } from 'views/lessons/createactivity/CreateScienceContentActivity';
import { CreateSurveyActivity } from 'views/lessons/createactivity/CreateSurveyActivity';
import { CreateTeamTeachingActivities } from 'views/lessons/createactivity/CreateTeamTeachingActivities';

export interface ActivityPreparationProps {
  lessonID: string;
  doSubmit: (
    bp: LessonActivityBlueprint,
    keepAdding?: boolean,
  ) => Promise<CreateLessonActivityResponse | undefined>;
  doSubmitMulti: (
    activities: ({ title: string } & LessonActivityBlueprint)[],
  ) => Promise<CreateLessonActivityResponse[] | undefined>;
  getFooter: (
    onSubmit?: (keepAdding?: boolean) => void,
    isLoading?: boolean,
    allowKeepAdding?: boolean,
  ) => React.ReactNode;
  setTaskName: (name: string, force?: boolean) => void;
}

interface ActivityTemplate {
  id: string;
  name: string;
  icon?: IconDefinition;
  descr?: string;
  additionalStep: React.FunctionComponent<ActivityPreparationProps>;
  isEphemeral?: boolean;
  sparxStaffOnly?: boolean;
  teamTeachingOnly?: boolean;
}

export interface TaskBlueprint {
  name: string;
  flashcards?: boolean;
  skills: SkillBlueprint[];
}

export interface SkillBlueprint {
  skillId: string;
  questionId: string;
  annotations?: Record<string, string>;
}

export const createBasicLessonActivityBlueprint = (
  ...tasks: TaskBlueprint[]
): LessonActivityBlueprint => ({
  content: {
    oneofKind: 'assignment',
    assignment: {
      contents: {
        oneofKind: 'staticAssignment',
        staticAssignment: {
          annotations: {},
          tasks: tasks.map(taskToStaticTask),
        },
      },
    },
  },
});

const taskToStaticTask = (task: TaskBlueprint): StaticAssignment_Task => {
  if (task.flashcards) {
    return {
      title: '',
      items: [
        {
          title: '',
          annotations: { flashcards: 'is' },
          contents: {
            subject: Subject.SUBJECT_UNDEFINED,
            contents: {
              oneofKind: 'flashcards',
              flashcards: {
                skills: task.skills.map(({ skillId }) =>
                  TaskItem_Contents_Skill.create({
                    skillId,
                  }),
                ),

                // TODO: initial states?
              },
            },
          },
        },
      ],
      annotations: { flashcards: 'is' },
    };
  } else {
    return {
      title: task.name,
      annotations: {},
      items: task.skills.map(({ skillId, questionId, annotations }) => ({
        title: '',
        annotations: annotations || {},
        contents: {
          subject: Subject.SUBJECT_UNDEFINED,
          contents: {
            oneofKind: 'skill',
            skill: {
              skillId,
              questionId,
              section: TaskItem_Contents_Skill_Section.INFOCUS,
              topicName: '',
              subject: Subject.SUBJECT_UNDEFINED,
            },
          },
        },
      })),
    };
  }
};

export const activityTemplateCreators: ActivityTemplate[] = [
  {
    id: 'content',
    name: 'Sparx Content',
    icon: faBookOpen,
    descr: 'Create a Sparx content activity.',
    additionalStep: CreateScienceContentActivity,
  },
  {
    id: 'followupfive',
    name: 'Follow Up 5',
    icon: faChartLine,
    descr: 'Create a Follow Up 5 activity.',
    additionalStep: CreateFollowUpFiveActivity,
  },
  {
    id: 'photo',
    name: 'Take a photo',
    icon: faCamera,
    descr: 'Set a custom question and prompt photo.',
    additionalStep: CreatePhotoActivity,
    teamTeachingOnly: true,
  },
  {
    id: 'survey',
    name: 'Survey question',
    icon: faListSquares,
    descr: 'Survey the class for an answer.',
    additionalStep: CreateSurveyActivity,
    teamTeachingOnly: true,
    isEphemeral: true,
  },
  {
    id: 'custom',
    name: 'Custom',
    icon: faWandMagic,
    descr: 'Manually set content (advanced).',
    additionalStep: CreateCustomSkillsActivity,
    sparxStaffOnly: import.meta.env.VITE_SPARX_PRODUCT !== 'SPARX_TEACHING',
  },
  {
    id: 'content-maths',
    name: 'Maths Content',
    icon: faBookOpen,
    descr: 'Create a Sparx Maths content activity.',
    additionalStep: CreateMathsContentActivity,
    sparxStaffOnly: import.meta.env.VITE_SPARX_PRODUCT !== 'SPARX_TEACHING',
  },
  {
    id: 'teamteaching',
    name: 'Team Teaching',
    icon: faCircleNotch,
    descr: 'Manual Team Teaching content (advanced).',
    additionalStep: CreateTeamTeachingActivities,
    sparxStaffOnly: import.meta.env.VITE_SPARX_PRODUCT !== 'SPARX_TEACHING',
    teamTeachingOnly: true,
  },
];
